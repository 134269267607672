import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';

const ModalManajemenSurveyor = ({ onClose, editingData }) => {


  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);




  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
  });

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [show, setShow] = useState(false);


  // Form state
  const [id, setId] = useState('');
  const [nik, setNik] = useState('');
  const [namaLengkap, setNamaLengkap] = useState('');
  const [email, setEmail] = useState('');
  const [nomorHandphone, setNomorHandphone] = useState('');
  const [npwp, setNpwp] = useState('');
  const [domisiliSaatIni, setDomisiliSaatIni] = useState('');
  const [namaAkunBank, setNamaAkunBank] = useState('');
  const [nomorRekening, setNomorRekening] = useState('');
  const [kodeWilayah, setKodeWilayah] = useState('');
  const [status, setStatus] = useState('');
  const [tahunLulus, setTahunLulus] = useState('');
  const [tingkatPendidikan, setTingkatPendidikan] = useState('');
  const [photo, setPhoto] = useState('');

  useEffect(() => {
    if (editingData) {
      setId(editingData.id || '');
      setNik(editingData.nik || '');
      setNamaLengkap(editingData.namaLengkap || '');
      setEmail(editingData.email || '');
      setNomorHandphone(editingData.nomorHandphone || '');
      setNpwp(editingData.npwp || '');
      setDomisiliSaatIni(editingData.domisiliSaatIni || '');
      setNamaAkunBank(editingData.namaAkunBank || '');
      setNomorRekening(editingData.nomorRekening || '');
      setKodeWilayah(editingData.kodeWilayah || '');
      setStatus(editingData.status || '');
      setTahunLulus(editingData.tahunLulus || '');
      setTingkatPendidikan(editingData.tingkatPendidikan || '');
      setPhoto(editingData.photo || '');
    }
  }, [editingData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!nik) {
      alert('Nik harus diisi');
      return
    }

    if (!email) {
      alert('Email harus diisi');
      return
    }

    //----------------------------- api -------------------------------
    const requestBody = {

      username: email,
      namaLengkap: namaLengkap,
      nik: nik,
      nohp: nomorHandphone,
      password: 'igahpsurveyor',
      email: email,
      role: ['IGAHP_SURVEYOR']
    };

    console.log(requestBody);


    const url = apiUrl + '/api/auth/signup'; // Replace with your API endpoint
    try {
      await fetch(url, {
        // credentials: 'include', //getcookies
        method: 'POST',
        // body: JSON.stringify(formData),
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          // 'X-XSRF-TOKEN': user.csrfToken
        },
      })
        .then(response => response.json())
        .then(data => {

          console.log(data);
          const resp = JSON.parse(JSON.stringify(data));

          console.log(resp);

          //  alert(resp.userId);
          // navigate('/pengembang/PengembangLokasiBlok');
          if (!resp.message.includes('Error')) {
            // simpanUserDetail(resp.userId, email);
          } else {
            // alert('Gagal');
          }

        })
        .catch(error => {
          console.error(error);
          // alert(error);
        });

    } catch (error) {
      console.error('An error occurred:', error);
    }


    //------------------------------------ tabel ---------------------------
    const formData = new FormData();
    formData.append('id', id);
    formData.append('userId', user.id);
    formData.append('nik', nik);
    formData.append('namaLengkap', namaLengkap);
    formData.append('email', email);
    formData.append('nomorHandphone', nomorHandphone);
    formData.append('npwp', npwp);
    formData.append('domisiliSaatIni', domisiliSaatIni);
    formData.append('namaAkunBank', namaAkunBank);
    formData.append('nomorRekening', nomorRekening);
    formData.append('kodeWilayah', kodeWilayah);
    formData.append('status', status);
    formData.append('tahunLulus', tahunLulus);
    formData.append('tingkatPendidikan', tingkatPendidikan);
    formData.append('photo', photo);




    try {
      const response = await fetch(`${apiUrl}/api/igahp/simpanSurveyor`, {
        credentials: 'include',
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
          'X-XSRF-TOKEN': user.csrfToken,
        },
      });

      const result = await response.json();

      if (response.ok) {
        Toast.fire({
          icon: 'success',
          title: result.status || 'Berhasil menyimpan data!',
        });

        // alert("Sukses");
        // Menutup modal jika berhasil
        // onClose();
        navigate('/depan/pemda/ManajemenSurveyor');
      } else {
        throw new Error(result.message || 'Terjadi kesalahan');
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: error.message,
      });
    }
  };


  const handleClose = () => {
    onClose();
  };


  return (
    <>

      <div className="modal fade" id="ModalManajemenSurveyor" tabIndex={-1} role="dialog"
        aria-labelledby="ModalManajemenSurveyorLabel" aria-hidden="true" data-backdrop="static">


        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <h4 className="modal-title mb-3">Form Enumerator</h4>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      {/* Left Column */}
                      <InputField label="NIK" value={nik} setValue={setNik} required />
                      <InputField
                        label="Nama Lengkap"
                        value={namaLengkap}
                        setValue={setNamaLengkap}
                        required
                      />
                      <InputField
                        label="Email / Username"
                        type="email"
                        value={email}
                        setValue={setEmail}
                      />
                      <InputField
                        label="Nomor Handphone"
                        value={nomorHandphone}
                        setValue={setNomorHandphone}
                      />
                      <InputField label="NPWP" value={npwp} setValue={setNpwp} />
                      
                    </div>
                    <div className="col-md-6">
                      {/* Right Column */}
                      <InputField
                        label="Nama Akun Bank"
                        value={namaAkunBank}
                        setValue={setNamaAkunBank}
                      />
                      <InputField
                        label="Nomor Rekening"
                        value={nomorRekening}
                        setValue={setNomorRekening}
                      />
                      {/* <InputField
                      label="Kode Wilayah"
                      value={kodeWilayah}
                      setValue={setKodeWilayah}
                    /> */}
                      {/* <InputField
                        label="Status"
                        value={status}
                        setValue={setStatus}
                      /> */}
                      {/* <InputField
                        label="Tahun Lulus"
                        value={tahunLulus}
                        setValue={setTahunLulus}
                      /> */}
                      <InputField
                        label="Tingkat Pendidikan"
                        value={tingkatPendidikan}
                        setValue={setTingkatPendidikan}
                      />
                      
                      <InputField
                        label="Domisili Saat Ini"
                        value={domisiliSaatIni}
                        setValue={setDomisiliSaatIni}
                      />
                      {/* <InputField
                        label="Photo"
                        value={photo}
                        setValue={setPhoto}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  Tutup
                </button>
                <button type="submit" className="btn btn-primary">
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

// Reusable Input Field Component
const InputField = ({ label, value, setValue, type = 'text', required }) => (
  <div className="form-group">
    <label>{label}</label>
    <input
      type={type}
      className="form-control"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={`Masukkan ${label.toLowerCase()}`}
      required={required}
    />
  </div>
);

export default ModalManajemenSurveyor;
