import React from 'react'

import { AuthContext } from '../../../AuthContext';
import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../../refreshTokenRequest';
import axios from 'axios';
import Footer from '../Footer';
// import ModalAsosiasi from './childs/ModalAsosiasi';
import Swal from 'sweetalert2';


const RptPerformance1 = () => {


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });


  const { user, setUser } = useContext(AuthContext);
  let refreshToken = null;
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const navigate = useNavigate()
  const { loginContext } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedRoleName, setSelectedRoleName] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [editingData, setEditingData] = useState(null);

  // useEffect(() => {
  //   fetchData();  
  // }, []);



  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/js/content.js";
    script.async = true;
    document.body.appendChild(script);

    if (isLoading || isTokenExpired) {
      console.log('Use effetc load fetchdata calling.....');
      // fetchDataRoles();
      fetchData();
    }
    return () => { script.remove(); }

  }, [isLoading, isTokenExpired]);



  const fetchData = async () => {

    let decodedToken = jwt_decode(user.accessToken);
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }


    try {
      const response = await axios.get(apiUrl + '/api/igahp/dashboard/rptPerformancePerSurveyor', {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });
      // await Delay(5000);
      console.log(response.data);
      setData(response.data);
      setIsLoading(false);
      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      navigate('/login');
    }
  };



  const handleSelectRoleChange = event => {
    setSelectedRole(event.target.value);
    setSelectedRoleName(event.target.options[event.target.selectedIndex].text);
    // console.log('Selected role:', selectedRole);
    // console.log('Selected role name:', event.target.options[event.target.selectedIndex].text);
  };

  // Function to close the modal
  const handleModalClose = () => {
    console.log('Modal Close')
    setModalOpen(false);
    fetchData();

  };





  let sequenceNumber = 1;

  let totals = {
    pemilikan: 0,
    pembangunan: 0,
    perbaikan: 0,
    sewa: 0,
    minatRusun: 0,
    approval: 0,
    revisi: 0,
    approved: 0,
    total: 0,
  }

  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Monitoring Enumerator</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Monitoring Enumerator</li>
                </ol>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* Main content */}

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {isLoading ? (
                <p>Loading Data...</p>
              ) : (
                <>
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>NO</th>
                              <th>NAMA</th>
                              <th className="text-end">KEPEMILIKAN</th>
                              <th className="text-end">PEMBANGUNAN</th>
                              <th className="text-end">PERBAIKAN</th>
                              <th className="text-end">SEWA</th>
                              <th className="text-end">MINAT RUSUN</th>
                              <th className="text-end">MENUNGGU APPROVAL</th>
                              <th className="text-end">REVISI</th>
                              <th className="text-end">APPROVED</th>
                              <th className="text-end">TOTAL</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              // Accumulate totals for each column
                              totals.pemilikan += item.pemilikanRumah || 0;
                              totals.pembangunan += item.pembangunanRumah || 0;
                              totals.perbaikan += item.perbaikan || 0;
                              totals.sewa += item.sewa || 0;
                              totals.minatRusun += item.minatRusun || 0;
                              totals.approval += item.approval || 0;
                              totals.revisi += item.revisi || 0;
                              totals.approved += item.approved || 0;
                              totals.total += item.total || 0;

                              return (
                                <tr key={item.id}>
                                  <td>{index + 1}</td>
                                  <td>{item.namaLengkap}</td>
                                  <td className="text-end">{item.pemilikanRumah ? item.pemilikanRumah.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.pembangunanRumah ? item.pembangunanRumah.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.perbaikan ? item.perbaikan.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.sewa ? item.sewa.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.minatRusun ? item.minatRusun.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.approval ? item.approval.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.revisi ? item.revisi.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.approved ? item.approved.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.total ? item.total.toLocaleString() : '0'}</td>
                                </tr>
                              );
                            })}
                          </tbody>

                          {/* Add totals row */}
                          <tfoot>
                            <tr>
                              <td colSpan="2">Total</td>
                              <td className="text-end">{totals.pemilikan.toLocaleString()}</td>
                              <td className="text-end">{totals.pembangunan.toLocaleString()}</td>
                              <td className="text-end">{totals.perbaikan.toLocaleString()}</td>
                              <td className="text-end">{totals.sewa.toLocaleString()}</td>
                              <td className="text-end">{totals.minatRusun.toLocaleString()}</td>
                              <td className="text-end">{totals.approval.toLocaleString()}</td>
                              <td className="text-end">{totals.revisi.toLocaleString()}</td>
                              <td className="text-end">{totals.approved.toLocaleString()}</td>
                              <td className="text-end">{totals.total.toLocaleString()}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* /.row */}
          </div>{/* /.container-fluid */}
        </section>


        {/* /.content */}


      </div>
      {/* /.content-wrapper */}

      {/* <Footer /> */}
    </>
  )
}

export default RptPerformance1
