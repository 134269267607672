import React from 'react'
import { useEffect } from 'react';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import { Link, useNavigate } from 'react-router-dom';
import LocationSelect from '../shared/LocationSelect';
import axios from 'axios';
import MenuPerumahanDetail from './MenuPerumahanDetail';
import CustomIframe from '../CustomIFrame';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';
import MyTimeline from '../shared/MyTimeline';
import ModalPengajuan from './childs/ModalPengajuan';
import LokasiPerumahan from './infoPerumahan/LokasiPerumahan';
import KantorPemasaran from './infoPerumahan/KantorPemasaran';
import BlokPerumahan from './infoPerumahan/BlokPerumahan';
import TipeUnit from './infoPerumahan/TipeUnit';
import SitePlanDigital from './infoPerumahan/SitePlanDigital';
import FasilitasPerumahan from './infoPerumahan/FasilitasPerumahan';

// import Timeline from 'react-timeline-horizontal';
// import 'react-timeline-horizontal/dist/style.css'; // Import the timeline styles


const PengembangLokasiProfile2 = () => {

    // const { id } = useParams();

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const { user, setUser } = useContext(AuthContext);
    const { loginContext } = useContext(AuthContext);
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate()
    const [dataProfile, setDataProfile] = useState({});
    const [dataFile, setDataFile] = useState({});
    const [lokasiGps, setLokasiGps] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isModal, setIsModal] = useState(false);

    const [dataStatus, setDataStatus] = useState();


    useEffect(() => {
        // console.log('masuk ke pengembang lokasi profile...')
        if (isLoading || isTokenExpired) {
            // console.log('Use effetc load fetchdata calling.....');
            (async () => {
                await fetchData();
                await fetchDataStatusPengajuan();

                setIsLoading(false);
                setIsTokenExpired(false);
            })();
        }

    }, [isLoading, isTokenExpired]);


    const handleMapContainerReady = () => {
        // setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            loginContext(user);
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        // console.log(user);

        const url = apiUrl + "/api/igahp/lokasi/detail/" + user.perumahan_id;

        let token = user.accessToken;

        // console.log('token...');
        // console.log(user);



        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // setIsLoading(false);
            // setIsTokenExpired(false);

            console.log('respon api profile peruahan ....');
            console.log(data);
            setDataProfile(data);
            setLokasiGps({ lat: data.latitude, lng: data.longitude });

            // return data;
        } catch (error) {
            // Handle errors here
            console.error('Error fetching data:', error);
        }

    };


    const fetchDataStatusPengajuan = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            loginContext(user);
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }



        try {
            await fetch(apiUrl + '/api/igahp/getStatusPengajuan/' + user.perumahan_id, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    console.log('data status prfile 2')
                    console.log(data)
                    setDataStatus(data)
                    if (dataStatus.kantorPemasaran == false) {
                        alert("Isikan terlabih dahulu kantor pemasaran, agar lokasi perumahan ini bisa diajukan ke admin");

                    }
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    // alert(data.status);
                });

        } catch (error) {
            console.error('An error occurred:', error);
        }


    };



    const handleNilaiBalikan = async (nilaiBalikan) => {
        // setIsLoading(nilaiBalikan);
        // console.log('Modal Close ' + nilaiBalikan)

        (async () => {
            await fetchData();
            await fetchDataStatusPengajuan();
        })();
    };

    const [itemData, setItemData] = useState(null);


    const handleIsModal = (item, event) => {

        isModal ? setIsModal(false) : setIsModal(true);
        setItemData(dataProfile);

        console.log('Modal Open')
        // setModalOpen(true);

    }

    const [isActiveLokasi, setIsActiveLokasi] = useState(true);
    const handleLokasiClick = () => {
        setIsActiveLokasi(!isActiveLokasi);
        setIsActiveKantor(false)
        setIsActiveBlok(false)
        setIsActiveTipe(false);
        setIsActiveSiteplan(false);
        setIsActiveFasilitas(false);
    };

    const [isActiveKantor, setIsActiveKantor] = useState(false);
    const handleKantorClick = () => {

        setIsActiveLokasi(false)
        setIsActiveKantor(!isActiveKantor);
        setIsActiveBlok(false)
        setIsActiveTipe(false);
        setIsActiveSiteplan(false);
        setIsActiveFasilitas(false);

    };

    const [isActiveBlok, setIsActiveBlok] = useState(false);
    const handleBlokClick = () => {
        setIsActiveLokasi(false)
        setIsActiveKantor(false)
        setIsActiveBlok(!isActiveBlok);
        setIsActiveTipe(false);
        setIsActiveSiteplan(false);
        setIsActiveFasilitas(false);

    };

    const [isActiveTipe, setIsActiveTipe] = useState(false);
    const handleTipeClick = () => {
        setIsActiveLokasi(false)
        setIsActiveKantor(false)
        setIsActiveBlok(false);
        setIsActiveTipe(!isActiveTipe);
        setIsActiveSiteplan(false);
        setIsActiveFasilitas(false);

    };

    const [isActiveSiteplan, setIsActiveSiteplan] = useState(false);
    const handleSiteplanClick = () => {
        setIsActiveLokasi(false)
        setIsActiveKantor(false)
        setIsActiveBlok(false);
        setIsActiveTipe(false);
        setIsActiveSiteplan(!isActiveSiteplan);
        setIsActiveFasilitas(false);

    };

    const [isActiveFasilitas, setIsActiveFasilitas] = useState(false);
    const handleFasilitasClick = () => {
        setIsActiveLokasi(false)
        setIsActiveKantor(false)
        setIsActiveBlok(false);
        setIsActiveTipe(false);
        setIsActiveSiteplan(false);
        setIsActiveFasilitas(!isActiveFasilitas);

    };


    return (
        <>



            {/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}
            {/* <aside className="main-sidebar sidebar-light-success elevation-4"> */}

            {/* <MenuPerumahanDetail /> */}
            {/* </aside> */}

            <>
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header header-expand">
                        <div className="container-fluid">
                            <div className="row mb-4 mt-2">
                                <div className="col-sm-6">
                                    <h1 className="text-muted fw-light mb-2"><b>{dataProfile.nama_perumahan}</b></h1>
                                    <h6 className="text-muted fw-light mb-0"><i className="fas fa-map-marker-alt mr-2" />
                                        {dataProfile.info_kelurahan}
                                    </h6>
                                </div>{/* /.col */}
                                <div className="col-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Kembali</a></li>
                                        <li className="breadcrumb-item active">Detail Perumahan</li>
                                    </ol>
                                </div>
                                {/* /.content-card-body */}
                            </div>{/* /.row */}
                        </div>{/* /.container-fluid */}
                    </div>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 col-lg-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <h6 className="text-muted accent-gray-dark">Menu</h6>
                                                {/* <p class="text-muted mb-0"><small>Isi form untuk daftar perumahan. </small></p> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="d-flex justify-content-between flex-column mb-3 mb-md-0">
                                                        <ul className="nav nav-align-left nav-pills flex-column">

                                                            <li className="nav-item mb-2 border-0">
                                                                <a className={`nav-link ${isActiveLokasi ? 'bg-success' : 'bg-light'}`} href="#" onClick={handleLokasiClick}>
                                                                    <i className="fas fa-map-pin me-2 mr-2" />
                                                                    <span className="align-middle"> Lokasi Perumahan</span>
                                                                </a>
                                                            </li>

                                                            <li className="nav-item mb-2 border-0">
                                                                <a className={`nav-link ${isActiveKantor ? 'bg-success' : 'bg-light'}`} href="#" onClick={handleKantorClick}>
                                                                    <i className="fas fa-building me-2 mr-2" />
                                                                    <span className="align-middle"> Kantor Pemasaran</span>
                                                                </a>
                                                            </li>

                                                            <li className="nav-item mb-2 border-0">
                                                                <a className={`nav-link ${isActiveBlok ? 'bg-success' : 'bg-light'}`} href="#" onClick={handleBlokClick}>
                                                                    <i className="fas fa-map-signs me-2 mr-2" />
                                                                    <span className="align-middle"> Blok Perumahan</span>
                                                                </a>
                                                            </li>

                                                            <li className="nav-item mb-2 border-0">
                                                                <a className={`nav-link ${isActiveTipe ? 'bg-success' : 'bg-light'}`} href="#" onClick={handleTipeClick}>
                                                                    <i className="fab fa-houzz me-2 mr-2" />
                                                                    <span className="align-middle"> Tipe Unit Rumah</span>
                                                                </a>
                                                            </li>

                                                            <li className="nav-item mb-2 border-0">
                                                                <a className={`nav-link ${isActiveSiteplan ? 'bg-success' : 'bg-light'}`} href="#" onClick={handleSiteplanClick}>
                                                                    <i className="fas fa-object-group me-2 mr-2" />
                                                                    <span className="align-middle"> Siteplan Digital</span>
                                                                </a>
                                                            </li>

                                                            <li className="nav-item mb-2 border-0">
                                                                <a className={`nav-link ${isActiveFasilitas ? 'bg-success' : 'bg-light'}`} href="#"
                                                                    onClick={handleFasilitasClick}>
                                                                    <i className="fas fa-bookmark me-2 mr-2" />
                                                                    <span className="align-middle"> Pilih Fasilitas</span>
                                                                </a>
                                                            </li>




                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {dataStatus ? (
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <h6 className="text-muted accent-gray-dark">Status lokasi diajukan</h6>
                                                    {/* <p class="text-muted mb-0"><small>Isi form untuk daftar perumahan. </small></p> */}
                                                </div>
                                                <div className="timeline timeline-inverse">
                                                    <div className="time-label">
                                                        <a className="btn btn-default btn-sm rounded-pill">10 Feb, 2024, Sedang Proses Pengecekan</a>
                                                    </div>

                                                    <div>
                                                        <i
                                                            className="fas fa-circle"
                                                            style={{
                                                                color: dataStatus.perizinan ? 'green' : 'lightgrey',
                                                                background: 'transparent'
                                                            }}
                                                        />
                                                        <div className="timeline-item rounded-pill border-success">
                                                            <h3 className="timeline-header text-muted border-0">
                                                                Data Lokasi dan Perizinan
                                                            </h3>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <i
                                                            className="fas fa-circle"
                                                            style={{
                                                                color: dataStatus.fotoLokasi ? 'green' : 'lightgrey',
                                                                background: 'transparent'
                                                            }}
                                                        />
                                                        <div className="timeline-item rounded-pill border-success">
                                                            <h3 className="timeline-header text-muted border-0">
                                                                Upload Foto Lokasi
                                                            </h3>
                                                        </div>
                                                    </div>


                                                    <div>
                                                        <i
                                                            className="fas fa-circle"
                                                            style={{
                                                                color: dataStatus.siteplan ? 'green' : 'lightgrey',
                                                                background: 'transparent'
                                                            }}
                                                        />
                                                        <div className="timeline-item rounded-pill border-success">
                                                            <h3 className="timeline-header text-muted border-0">
                                                                Upload Siteplan Latar
                                                            </h3>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <i
                                                            className="fas fa-circle"
                                                            style={{
                                                                color: dataStatus.kantorPemasaran ? 'green' : 'lightgrey',
                                                                background: 'transparent'
                                                            }}
                                                        />
                                                        <div className="timeline-item rounded-pill border-success">
                                                            <h3 className="timeline-header text-muted border-0">
                                                                Isi Data Kantor Pemasaran
                                                            </h3>
                                                        </div>
                                                    </div>


                                                    <div>
                                                        <i className="fas fa-circle" style={{ color: 'lightgrey', background: 'transparent' }} />
                                                        <div className="timeline-item rounded-pill">
                                                            <h3 className="timeline-header text-muted border-0">Pengecekan Lokasi
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <i className="fa fa-check bg-black" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ) : (

                                        <>
                                            <p>Loading ...</p>
                                        </>
                                    )}


                                </div>


                                {isActiveLokasi && (
                                    <>
                                        <LokasiPerumahan />
                                    </>
                                )}

                                {isActiveKantor && (
                                    <>
                                        <KantorPemasaran />
                                    </>
                                )}
                                {isActiveBlok && (
                                    <>
                                        <BlokPerumahan />
                                    </>
                                )}
                                {isActiveTipe && (
                                    <>
                                        <TipeUnit />
                                    </>
                                )}
                                {isActiveSiteplan && (
                                    <>
                                        <SitePlanDigital />
                                    </>
                                )}
                                {isActiveFasilitas && (
                                    <>
                                        <FasilitasPerumahan />
                                    </>
                                )}



                            </div>
                        </div>
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
            </>



        </>
    )
}

export default PengembangLokasiProfile2
