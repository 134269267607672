import React, { useState } from "react";
import {
    BrowserRouter,
    Router,
    Routes,
    Route,
    Link,
    Outlet,
} from "react-router-dom";

// import Badge from "../additional/Bagde";
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";

import { useEffect } from "react";
import Footer from "../Footer";

import NavbarPenerimaManfaat from "./NavbarPenerimaManfaat";
import SidebarPenerimaManfaat from "./SidebarPenerimaManfaat";

const MenuPenerimaManfaat = (props) => {
    const { user, setUser } = useContext(AuthContext);

    useEffect(() => {
        // const script = document.createElement("script");
        // script.src = "/js/highlight.js";
        // script.async = true;
        // document.body.appendChild(script);

        // console.log('masuk ke menu pengembang admin');
        return () => {
            // script.remove();
        };
    }, []);

    return (
        <>


            {/* <div className="hold-transition sidebar-mini layout-fixed"> */}
            <div className="hold-transition sidebar-mini sidebar-collapse layout-fixed layout-navbar-fixed layout-footer-fixed">

                <div className="wrapper">

                    <NavbarPenerimaManfaat />

                    <SidebarPenerimaManfaat />

                    <Outlet />

                    <Footer />

                   

                </div>



            </div>

        </>
    );
};

export default MenuPenerimaManfaat;
