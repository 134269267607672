import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';

import ChangePasswordModal from "../shared/ChangePasswordModal"

const Sidebar = () => {
    const { user } = useContext(AuthContext);

    const [showModalPassword, setshowModalPassword] = useState(false);

    // Fungsi untuk menampilkan modal
    const handleshowModalPassword = () => setshowModalPassword(true);

    // Fungsi untuk menutup modal
    const handleCloseModalPassword = () => setshowModalPassword(false);


    // State to handle the collapse of each section
    const [isCollapsed, setIsCollapsed] = useState({
        dataReferensi: false,
        calonPenerima: false,
        pendataan: false,
        bgh: false,
        surveiLapangan: false,
        minatProgram: false,
        slikBank: false,
        penerimaManfaat: false,
        hasilSubsidiChecking: false,
        pelaporan: false,
        utility: false,
    });

    // Function to toggle collapse of a specific menu
    const toggleCollapse = (section) => {
        setIsCollapsed((prev) => ({ ...prev, [section]: !prev[section] }));
    };

    useEffect(() => {
        // Any additional JavaScript can be loaded here
    }, []);

    return (
        <aside id="control_sidebar" className="main-sidebar sidebar-light-success elevation-4">
            {/* Brand Logo */}
            <a href="/" className="brand-link">
                <img src="/dist/img/lentera_logo.svg" alt="Logo" className="brand-image" />
                <h4>
                    <span className="brand-text text-muted ml-2"></span>
                    <b>LENTERA HIJAU</b>
                </h4>
            </a>

            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar user panel */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex flex-wrap">
                    {/* <div className="image">
                        <img src="/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User" />
                    </div> */}
                    <div className="info p-0 ml-4">
                        <div>
                            <info className="text-muted">
                                USER PEMDA
                                <a href="#" className="d-block">{user?.username}</a>
                            </info>
                        </div>
                    </div>
                </div>

                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">


                        {/* Dashboard */}
                        <li className="nav-item">
                            <Link to="pemda/dashboard" className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>Dashboard</p>
                            </Link>
                        </li>

                        {/* Data Referensi */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('dataReferensi')}>
                                <i className="nav-icon fas fa-folder-open"></i>
                                <p>
                                    Data Referensi
                                    <i className={`fas fa-angle-left right ${isCollapsed.dataReferensi ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.dataReferensi && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/QuesionerDataAwal" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Data Hasil Sinkronisasi</p>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>

                        {/* Status Subsidi Checking */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('calonPenerima')}>
                                <i className="nav-icon fas fa-user-check"></i>
                                <p>
                                    Status Subsidi Checking
                                    <i className={`fas fa-angle-left right ${isCollapsed.calonPenerima ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.calonPenerima && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/LolosSubsidiChecking" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Lolos Checking</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pemda/TidakLolosSubsidiChecking" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Tidak Lolos Checking</p>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>

                        {/* Data Survei Lapangan */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('surveiLapangan')}>
                                <i className="nav-icon fas fa-map-marker-alt"></i>
                                <p>
                                    Data Survei Lapangan
                                    <i className={`fas fa-angle-left right ${isCollapsed.surveiLapangan ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.surveiLapangan && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/IgahpDataSiapSurvei" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Data Siap Survei</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pemda/IgahpDataHasilSurvei" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Data Hasil Survei</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pemda/IgahpDataAnalisaHasilSurvei" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Data Hasil Analisa Survei</p>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>

                        {/* Admin Pendataan */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('pendataan')}>
                                <i className="nav-icon fas fa-user-cog"></i>
                                <p>
                                    Admin Pendataan
                                    <i className={`fas fa-angle-left right ${isCollapsed.pendataan ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.pendataan && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/PemdaPenugasanPendataan" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Penugasan</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pemda/PemdaApprovalPendataan" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Approval</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pemda/ManajemenSurveyor" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Data Enumerator</p>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>

                        {/* Pelaporan */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('pelaporan')}>
                                <i className="nav-icon fas fa-chart-bar"></i>
                                <p>
                                    Pelaporan
                                    <i className={`fas fa-angle-left right ${isCollapsed.pelaporan ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.pelaporan && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/RptPerformanceSurveyor1" className="nav-link">
                                            <i className="far fa-circle nav-icon text-danger"></i>
                                            <p>Monitoring Enumerator</p>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>

                        {/* Konfigurasi */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('utility')}>
                                <i className="nav-icon fas fa-cogs"></i>
                                <p>
                                    Konfigurasi
                                    <i className={`fas fa-angle-left right ${isCollapsed.utility ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.utility && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="#" className="nav-link" onClick={handleshowModalPassword}>
                                            <i className="far fa-circle nav-icon text-blue"></i>
                                            <p>Rubah Password</p>
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </li>


                        <ChangePasswordModal
                            show={showModalPassword}
                            handleClose={handleCloseModalPassword}
                        // onChangePassword={handleChangePassword}
                        />

                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default Sidebar;
