import React from 'react'
import NavBarAtas from './NavBarAtas'
import FooterFront from './FooterFront';
import FooterQuestion from './FooterQuestion';
import { useLanguage } from '../../context/LanguageContext';
// import { useState } from 'react';


const IgahpDetail = () => {
  const { translations } = useLanguage(); // Mengambil terjemahan dari konteks bahasa
  const myIgahpTranslations = translations?.myigahp || {};
  const myLandingTranslations = translations?.mylanding || {};


  require("./../../css/common.css");

  return (

    <>

      <div>

        <NavBarAtas showButton={true} />

        <section className="body-home-content-igahpdetail">


          <div className="section-headlines-header-text">
            <div className="wrapper-label-headlines">
              <div className="igahp-highlight-semi-bold">
                {myIgahpTranslations.igahpTitle ||
                  "Indonesia Green Affordable Housing Program"}
              </div>
              <div className="igahp-l-regular black-text">
                {myIgahpTranslations.igahpDescription ||
                  "IGAHP adalah singkatan dari Indonesia Green Affordable Housing Program, yaitu program penyediaan rumah terjangkau yang berwawasan lingkungan. Program ini dikembangkan oleh Kementerian Pekerjaan Umum dan Perumahan Rakyat (PUPR) bersama dengan para pemangku kepentingan di bidang perumahan."}
              </div>
            </div>
            <div className="wrapper-house-information">
              <div className="igahp-l-regular black-text">
                {myIgahpTranslations.igahpImpact ||
                  "Program IGAHP diharapkan dapat menghemat air minimal 20 persen, hemat energi minimal 20 persen, dan dapat mengurangi efek rumah kaca sebesar 29 persen."}
              </div>
            </div>
          </div>

          {/* <section className="section-body-maxpage">
            <div className="body-card-panduan">

              <div className="group-card-panduan">
                <div className="card-panduan">
                <div className="label-panduan">
                    <div className="igahp-subheading-semi-bold">
                    Guidelines
                    </div>
                    <button className="btn-icon-only">
                      <img src="/ic_number1.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                <div className="card-panduan">
                <div className="label-panduan">
                    <div className="igahp-subheading-semi-bold">
                    Basic Design
                    </div>
                    <button className="btn-icon-only">
                      <img src="/ic_number2.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                <div className="card-panduan">
                <div className="label-panduan">
                    <div className="igahp-subheading-semi-bold">
                    Toolkit
                    </div>
                    <button className="btn-icon-only">
                      <img src="/ic_number3.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                <div className="card-panduan">
                <div className="label-panduan">
                    <div className="igahp-subheading-semi-bold">
                    Administrasi PGB
                    </div>
                    <button className="btn-icon-only">
                      <img src="/ic_number4.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

              </div>

            



            </div>


          </section> */}



          <section className="section-body-maxpage">
          <div className="body-card">
              <div className="container-attention">
                <div className="content-attention">
                  <div className="container-text-attention">
                    <div className="label-text-attention">
                      {myLandingTranslations.reportLabel || "LAPORKAN!!"}
                    </div>
                    <div className="support-text-attention">
                      <p>
                        {myLandingTranslations.reportDescription1 ||
                          "Jika anda melihat dan mendengar Pelanggaran/Kecurangan yang dilakukan oleh Pejabat/Pegawai di lingkungan"}
                      </p>
                      <p>
                        {myLandingTranslations.reportDescription2 ||
                          "Kementerian Perumahan dan Kawasan Permukiman"}
                      </p>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </section>

        </section>


        <FooterQuestion />
        <FooterFront />
      </div>

    </>



  )
};

export default IgahpDetail
