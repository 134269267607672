import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import SidebarPengembang from './SidebarPengembang';
import jwt_decode from 'jwt-decode'

import axios from 'axios';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { AuthContext } from '../../../AuthContext';
import { useContext } from 'react';
import ModalDataRumah from './ModalDataRumah';
// import ModalPeminatBanks from '../childs/ModalPeminatBanks';
import ModalDataPribadi from './ModalDataPribadi';
import PictureProfile from '../../PictureProfile';
import ModalPeminatBanks from '../../igahp_pengembang_admin/childs/ModalPeminatBanks';

const ProfileDokumen = () => {

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const navigate = useNavigate();
  let refreshToken = null;
  const { user, setUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [dataLokasi, setDataLokasi] = useState([]);
  const [itemData, setItemData] = useState(null);
  const [dataBank, setDataBank] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [wilayah, setWilayah] = useState({
    namaProp: ''
  });
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const { loginContext } = useContext(AuthContext);

  const [email, setEmail] = useState('');


  const [selectedPekerjaanOption, setSelectedPekerjaanOption] = useState('');


  const handleChangeOptionPekerjaan = event => {
    setSelectedPekerjaanOption(event.target.value);
  };

  const [kategoriRumah, setKategoriRumah] = useState('');

  const [jenisPembangunan, setjJenisPembangunan] = useState(null);

  const [options, setOptions] = useState([]);
  const [selectedUserOption, setSelectedUserOption] = useState('');

  const handleOptionUserChange = event => {
    setSelectedUserOption(event.target.value);
  };

  const [selectedOptionPerumahan, setSelectedOptionPerumahan] = useState('');

  const handleOptionChangePerumahan = event => {
    setSelectedOptionPerumahan(event.target.value);
    // alert(event.target.value)
  };


  const handleKategoriRumah = (event) => {
    setKategoriRumah(event.target.value);
  };


  const handleJenisPembangunan = (event) => {
    setjJenisPembangunan(event.target.value);
  };



  const handleOpenBankClick = () => {
    setModalBankOpen(true);
  }

  const handleModalBankClose = () => {
    setModalBankOpen(false);
    fetchData();
  };

  const [isModalBankOpen, setModalBankOpen] = useState(false);


  const [optionsPerumahan, setOptionsPerumahan] = useState([]);


  const [nama, setNama] = useState('');

  const handleSubmit = async () => {
    // Perform form submission logic here
    // console.log('Form submitted:', { fullName, username, phoneNumber });
    // You can send the form data to an API endpoint or do any other action here

    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      // console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      // console.log("Valid token");
      // result = true;
    }

  };


  useEffect(() => {

    (async () => {

      await fetchOptionsFromApiPerumahan()
        .then(data => setOptionsPerumahan(data))
        .catch(error => console.error('Error fetching options:', error));

      await fetchData();


      await fetchOptionsPekerjaanFromApi()
        .then(data => setOptions(data))
        .catch(error => console.error('Error fetching options:', error));


      setIsLoading(false);
      setIsTokenExpired(false);
    })();

  }, []);


  const fetchOptionsPekerjaanFromApi = async () => {
    try {
      const response = await fetch(apiUrl + '/api/public/getAllPekerjaan');
      const data = await response.json();
      // console.log('data pekerjaan ....');
      // console.log(data);
      return data;
    } catch (error) {
      throw new Error('Failed to fetch options from API');
    }
  };


  useEffect(() => {

    if (selectedOptionPerumahan) {

      const fetchDataLokasi = async () => {


        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
          console.log("Token expired.");
          try {
            const paramRefreshToken = {
              refreshToken: user.refreshToken,
              csrfToken: user.csrfToken,
            };
            refreshToken = await refreshTokenRequest(paramRefreshToken);
            // refreshToken = await refreshTokenRequest();
          } catch (error) {
            console.error('Expired. Please sign in.');
            navigate('/login');
          }
          if (refreshToken.statusCode === 403) {
            console.error('Expired. Please sign in.');
            navigate('/login');
          }
          console.log(refreshToken);
          user['accessToken'] = refreshToken.accessToken;
          setIsTokenExpired(true);
        } else {
          console.log("Valid token");
          // result = true;
        }


        try {
          const response = await axios.get(apiUrl + '/api/igahp/lokasi/detail/' + selectedOptionPerumahan, {
            headers: {
              Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
            }
          });
          if (response && response.data) {
            setDataLokasi(response.data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }

        try {
          const response2 = await axios.get(apiUrl + '/api/igahp/profileperorangan/' + selectedOptionPerumahan, {
            headers: {
              Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
            }
          });
          if (response2 && response2.data) {
            // setDataLokasi(response.data);
            console.log('.......profileperorangan............');
            console.log(response2.data)


            setDataStatus(prevState => ({
              ...prevState,
              sudahPelaksanaan: response2.data.statusPelaksanaan,
              sudahPemanfaatan: response2.data.statusPemanfaatan,
              sudahPerencanaan: response2.data.statusPerencanaan,
              sudahPembongkaran: response2.data.statusPembongkaran
            }));

            setjJenisPembangunan(response2.data.jenisPembangunan);
            setKategoriRumah(response2.data.katagoriRumah)

            fetchData()


          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }



      };

      fetchDataLokasi();
    }
  }, [selectedOptionPerumahan]);

  const fetchData = async () => {

    let decodedToken = jwt_decode(user.accessToken);
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }



    try {
      const response = await axios.get(apiUrl + '/api/igahp/getPengembangPekerjaanById/' + user.pengembang_id, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });

      if (response && response.data) {

        console.log('data api from ------------------')
        console.log(response.data);

        setData(response.data);

        const tampung = response.data;

        setFullName(tampung.nama)
        setEmail(tampung.email);
        setPhoneNumber(tampung.telp)

        setIsLoading(false);
        setIsTokenExpired(false);
      } else {
        console.error('No data returned from API.');

      }
    } catch (error) {
      console.error('Error getting API data...', error);
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, possibly refresh token or prompt login
      } else {
        // Handle other errors
      }
      navigate('/login');
    }

  };


  const [dataStatus, setDataStatus] = useState({
    sudahPerencanaan: false,
    sudahPelaksanaan: false,
    sudahPemanfaatan: false,
    sudahPembongkaran: false
  });

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setDataStatus(prevData => ({
      ...prevData,
      [id]: checked
    }));
  };

  const handleSubmitCategoryDanJenis = async () => {

    if (selectedOptionPerumahan === '') {
      alert('Rumah belum di pilih')
      return

    }


    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      // console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      // console.log("Valid token");
      // result = true;
    }



    const data22 = {
      id: selectedOptionPerumahan,
      kategRumah: kategoriRumah,
      jnsPembangunan: jenisPembangunan

    };


    axios.post(apiUrl + '/api/igahp/perorangan/updateKategJenisPembangunan', data22,
      {

        headers: {

          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'Content-Type': 'application/json' // You can adjust this based on your API requirements
        }
      })
      .then(response => {
        console.log('API Response:', response.data);
        // Handle success response here

        alert('Update berhasil..');
        fetchData();
      })
      .catch(error => {
        console.error('API Error:', error);
        alert('Update Gagal..');
        // Handle error here
      });



    // alert("helllo");
  }

  const handleSubmitStatus = async () => {

    if (selectedOptionPerumahan === '') {
      alert('Rumah belum di pilih')
      return

    }



    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      // console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      // console.log("Valid token");
      // result = true;
    }



    const data = {
      id: selectedOptionPerumahan,
      sudahPerencanaan: dataStatus.sudahPerencanaan,
      sudahPelaksanaan: dataStatus.sudahPelaksanaan,
      sudahPemanfaatan: dataStatus.sudahPemanfaatan,
      sudahPembongkaran: dataStatus.sudahPembongkaran
    };


    axios.post(apiUrl + '/api/igahp/perorangan/updateStatusSertifikasi', data,
      {

        headers: {

          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'Content-Type': 'application/json' // You can adjust this based on your API requirements
        }
      })
      .then(response => {
        console.log('API Response:', response.data);
        // Handle success response here

        alert('Update berhasil..');
        fetchData();
      })
      .catch(error => {
        console.error('API Error:', error);
        alert('Update Gagal..');
        // Handle error here
      });



  };

  const fetchOptionsFromApiPerumahan = async () => {
    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();'
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }

    // var idPengembang = (selectedOptionPengembang === '') ? 1 : selectedOptionPengembang;

    var idPengembang = user.pengembang_id;

    try {
      const response = await axios.get(apiUrl + '/api/igahp/lokasibypengembangid/' + idPengembang, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });

      return response.data;
    } catch (error) {
      // console.error('Error get api data...');
      // navigate('/login');
    }
  };

  const [nik, setNik] = useState(null);
  const handleNikChange = (event) => {
    setNik(event.target.value);
  };

  const [npwp, setNpwp] = useState(null);
  const handleNpwpChange = (event) => {
    setNpwp(event.target.value);
  };

  const [kk, setKk] = useState(null);
  const handleKkChange = (event) => {
    setKk(event.target.value);
  };




  const [dokPhoto3x4, setDokPhoto3x4] = useState(null);
  const [dokKtpSuamiIstri, setDokKtpSuamiIstri] = useState(null);
  const [dokNpwp, setDokNpwp] = useState(null);
  const [dokKk, setDokKk] = useState(null);
  const [dokKetKerja, setDokKetKerja] = useState(null);
  const [dokSlipGaji, setDokSlipGaji] = useState(null);
  const [dokBukuNikah, setDokBukuNikah] = useState(null);
  const [dokRekKoran, setDokRekKoran] = useState(null);
  const [dokGaji3Bulan, setDokGaji3Bulan] = useState(null);
  const [dokBelumMilikiRumah, setDokBelumMilikiRumah] = useState(null);
  const [dokBelumTerimaSubsidi, setDokBelumTerimaSubsidi] = useState(null);


  const handleSubmitDokumen = async (e) => {


    e.preventDefault();

    //----------------- mulai handel form ---------------------------------------------------------------------------------------
    const formData = new FormData();

    formData.append('id', user.minat_id);
    formData.append('dokPhoto3x4', dokPhoto3x4);
    formData.append('dokKtpSuamiIstri', dokKtpSuamiIstri);
    formData.append('dokNpwp', dokNpwp);
    formData.append('dokKk', dokKk);
    formData.append('dokKetKerja', dokKetKerja);
    formData.append('dokSlipGaji', dokSlipGaji);
    formData.append('dokBukuNikah', dokBukuNikah);
    formData.append('dokRekKoran', dokRekKoran);
    formData.append('dokGaji3Bulan', dokGaji3Bulan);
    formData.append('dokBelumMilikiRumah', dokBelumMilikiRumah);
    formData.append('dokBelumTerimaSubsidi', dokBelumTerimaSubsidi);


    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;

      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }


    try {
      await fetch(apiUrl + '/api/igahp/simpanPeminatDokumenPerorangan', {
        credentials: 'include', //getcookies
        method: 'POST',
        // body: JSON.stringify(formData),
        body: formData,
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'X-XSRF-TOKEN': user.csrfToken
        },
      })
        .then(response => response.json())
        .then(data => {
          // Handle API response
          console.log(data);

          alert(data.status)

          fetchData();
          //  alert(data.status);

          // Toast.fire({
          //   icon: 'success',
          //   title: data.status
          // });

          // setIsButtonProcess(false);

          // navigate('/igahp/pengembang/PengembangLokasiTipeRumah');
          // handleNilaiBalikan();
        })
        .catch(error => {
          // Handle error
          // setIsButtonProcess(false);
          console.error(error);
          alert(error);

          // Toast.fire({
          //   icon: 'error',
          //   title: error
          // });

        });

    } catch (error) {
      // setIsButtonProcess(false);
      console.error('An error occurred:', error);
      alert(error)
    }

  };



  const handleSubmitDataPribadi = async (e) => {
    e.preventDefault();

    if (selectedPekerjaanOption === '') {
      alert('Pekerjaan harus di piih')
      return
    }

    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      // console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      // console.log("Valid token");
      // result = true;
    }

    var formData = new FormData();
    formData.append("id", user.pengembang_id);
    formData.append("nik", nik);
    formData.append("npwp", npwp);
    formData.append("kk", kk);
    formData.append("pekerjaanId", selectedPekerjaanOption);
    // formData.append("email", email);

    try {
      await fetch(apiUrl + '/api/igahp/updatePengembangPekerjaan', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        },
        body: formData  // Set formData directly as the body
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Parse JSON response
        })
        .then(data => {
          // Handle API response
          // setIsLoading(true);
          fetchData()
          alert(data.status);
          // handleNilaiBalikan();
        })
        .catch(error => {
          // Handle fetch errors
          console.error('Fetch error:', error);
          alert('An error occurred while fetching data');
        });

    } catch (error) {
      // Handle other errors
      console.error('An error occurred:', error);
    }


  }

  let sequenceNumber = 1;



  return (
    <>

      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-4 mt-2">
              <div className="col-sm-6">
                <h1 className="text-muted fw-light">PROFIL <b>AKUN</b></h1>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Pengembang</a></li>
                  <li className="breadcrumb-item active">Peminatan</li>
                </ol>
              </div>
              {/* /.content-card-body */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        <section className="content">
          <div className="container-fluid">

            {isLoading ?
              <p>Loading Data...</p> :

              <div className="row">
                <div className="col-12 col-lg-4 order-1 order-md-0">
                  {/* User Card */}
                  <div className="card mb-4">
                    <div className="card-body mb-4">
                      <div className="user-avatar-section">
                        <div className=" d-flex align-items-center flex-column">
                          <img className="img-fluid rounded my-4" src="/dist/img/user8-128x128.jpg" height={110} width={110} alt="User avatar" />
                          {/* <PictureProfile/> */}
                          {/* <div className="img-fluid rounded my-4"  style={{ height: '50px', width: '50px' }}> */}
                          {/* <PictureProfile /> */}
                          {/* </div> */}

                          <div class="user-info text-center">
                            <h4 class="mb-2">{data.nama}</h4>
                            <h6 class="text-muted">ID USER #12345</h6>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-around flex-wrap my-4 py-3">

                      </div>


                      <div>
                        <h6 className="pb-2 border-bottom text-muted mb-4">DETAIL AKUN</h6>
                        <div className="info-container">
                          <div className="table-responsive">
                            <table className="table no-border">
                              <tbody>
                                <tr>
                                  <td>Status</td>
                                  <td>:</td>
                                  <td><span className="badge bg-gradient-red">belum menyetujui slik</span></td>
                                </tr>
                                <tr>
                                  <td>No. Handphone</td>
                                  <td>:</td>
                                  <td>{data.telp}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="pt-3">
                            <div className="d-flex justify-content-center">
                              <button type="button" id="#" data-toggle="modal" data-target="#modal-l" className="btn btn-success"><i className="fas fa-edit mr-2" />Update Akun </button>

                            </div>
                            <div className="modal fade" id="modal-l">
                              <div className="modal-dialog modal-l">
                                <div className="modal-content ">
                                  <div className="modal-header">
                                    <h6 className="text-muted m-0">Update Akun</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="d-flex align-items-start align-items-sm-center">
                                      <img src="dist/img/user1-128x128.jpg" alt="user-avatar" className="d-block rounded" height={100} width={100} id="uploadedAvatar" />
                                      <div className="button-wrapper ml-4">
                                        <label htmlFor="upload" className="btn btn-success me-2 mb-4" tabIndex={0}>
                                          <span className="d-none d-sm-block">Upload new photo</span>
                                          <i className="fas fa-upload d-block d-sm-none" />
                                          <input type="file" id="upload" className="account-file-input" hidden accept="image/png, image/jpeg" />
                                        </label>
                                        <button type="button" className="btn btn-outline-secondary account-image-reset mb-4">
                                          <i className="fas fa-redo-alt d-block d-sm-none" />
                                          <span className="d-none d-sm-block">Reset</span>
                                        </button>
                                        <p className="text-muted mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p>
                                      </div>
                                    </div>
                                    <br />
                                    <div className="form-group">
                                      <label htmlFor="dafaultLabel" className="text-gray-dark">Nama Lengkap</label>
                                      {/* <input type="text" defaultValue="Junet Aldio" className="form-control" id="settings" placeholder="Masukan nama lengkap" /> */}
                                      <input
                                        type="text"
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                        className="form-control"
                                        id="fullName"
                                        placeholder="Masukan nama lengkap"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="dafaultLabel" className="text-gray-dark">Username</label>
                                      {/* <input type="text" defaultValue="-" className="form-control" id="settings" placeholder="Masukan username" /> */}
                                      <input
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="form-control"
                                        id="fullName"
                                        placeholder="Masukan nama lengkap"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="dafaultLabel" className="text-gray-dark">Email</label>
                                      <input type="email" defaultValue="junetaldio@gmail.com" className="form-control" id="settings" placeholder="Masukan nama perumahan" />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="dafaultLabel" className="text-gray-dark">No. Handphone</label>
                                      {/* <input type="number" defaultValue={"081212812812"} className="form-control" id="settings" placeholder="081xxxxxxx" /> */}
                                      <input
                                        type="text"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        className="form-control"
                                        id="phoneNumber"
                                        placeholder="081xxxxxxx"
                                      />
                                    </div>
                                  </div>
                                  <div className="modal-footer justify-content-end align-content-between">
                                    <button type="button" className="btn btn-success">Simpan</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                  {/* /User Card */}
                  <div className="card mb-4">
                    <div className="card-body mb-4">
                      <div className="form-group">
                        <h6 className="text-muted accent-gray-dark">PHOTO PENDATAAN</h6>
                        {/* <p class="text-muted mb-0"><small>Isi form untuk daftar perumahan. </small></p> */}
                      </div>

                      <div className="row mb-0">
                        <div className="col-md-6 col-lg-6">
                          <div className="card h-60">
                            <img className="card-img-top" src="/dist/img/contoh-photo-diri.jpg" alt="Foto Diri" />
                            <div className="card-body">
                              <p className="text-muted h6">PHOTO DIRI</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div className="card h-60">
                            <img className="card-img-top" src="/dist/img/contoh-photo-rumah-peminat.jpg" alt="Foto Rumah" />
                            <div className="card-body">
                              <p className="text-muted h6">PHOTO RUMAH</p>
                            </div>
                          </div>
                        </div>


                      </div>





                    </div>
                  </div>
                </div>


                {/* /.col */}
                <div className="col-sm-12 col-lg-8 order-0 order-md-1">


                  <ul className="nav nav-pills flex-column flex-md-row mb-3">
                    <li className="nav-item"><a className="nav-link active" href="#datapribadi" data-toggle="tab"> <i className="far fa-user mr-2" /> Data Pribadi</a></li>
                    <li className="nav-item"><a className="nav-link" href="#statussertifikasi" data-toggle="tab"><i className="	far fa-file-alt mr-2" />Status Verifikasi</a></li>
                    <li className="nav-item"><a className="nav-link" href="#dokumen" data-toggle="tab"><i className="far fa-folder-open mr-2" />Dokumen</a></li>
                  </ul>


                  <div className="tab-content">

                    {/* /.tab-pane */}
                    <div className="tab-pane active" id="datapribadi">

                      <div className="card mb-4">
                        <h5 className="card-header">Permohonan SLIK</h5>
                        <div className="card-body">
                          <form id="formChangePassword" method="GET" onsubmit="return false" className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                            <div className="alert alert-warning alert-dismissible" role="alert">
                              <h5 className="alert-heading mb-2"><i class="icon fas fa-exclamation-triangle"></i>Anda belum melakukan persetujuan SLIK</h5>
                              <span>Untuk pengajuan permohonan bantuan lakukan persetujuan SLIK agar data Anda dapat diproses</span>
                              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
                            </div>

                            <button type="button" id="#" data-toggle="modal" data-target="#modal-lg" className="btn btn-success">
                              Persetujuan SLIK
                            </button>
                            <input type="hidden" /></form>
                        </div>
                        <div className="modal fade" id="modal-lg">
                          <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h6 className="text-muted m-0">Persetujuan SLIK</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <form>
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="row mb-4">
                                        <div className="col-12 text-center">
                                          <h5>PERNYATAAN </h5>
                                          <h5>KESEDIAAN UNTUK DILAKUKAN PENCARIAN
                                            <p>SISTEM LAYANAN INFORMASI KEUANGAN (SLIK) / INFOMASI LAINNYA</p>
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="row mb-2">
                                        <div className="col-12"><label>Dengan ini saya :</label></div>

                                      </div>
                                      <div className="row mb-2">
                                        <h6 class="col-sm-3 col-form-label">Nama</h6>
                                        <div className="col-sm-9"><input type="text" className="form-control" id="nama" placeholder required defaultValue /></div>
                                      </div>
                                      <div className="row mb-2">
                                        <h6 class="col-sm-3 col-form-label">Alamat</h6>
                                        <div className="col-sm-9"><input type="text" className="form-control" id="" placeholder required defaultValue /></div>
                                      </div>
                                      <div className="row mb-2">
                                        <h6 class="col-sm-3 col-form-label">Pekerjaan</h6>
                                        <div className="col-sm-9"><input type="text" className="form-control" id="" placeholder required defaultValue /></div>
                                      </div>
                                      <div className="row mb-2">
                                        <h6 class="col-sm-3 col-form-label">Nomor KTP</h6>
                                        <div className="col-sm-9"><input type="text" className="form-control" id="" placeholder required defaultValue /></div>
                                      </div>
                                      <div className="row mb-2">
                                        <h6 class="col-sm-3 col-form-label">Nama Ibu Kandung</h6>
                                        <div className="col-sm-9"><input type="text" className="form-control" id="" placeholder required defaultValue /></div>
                                      </div>

                                      <br />
                                      <div className="row">
                                        <div className="col-12">
                                          <p>Dengan ini menyatakan dengan sebenar-benarnya, bahwa saya bersedia/sangup/setuju untuk dilakukan pencarian informasi mengenai reputasi keuangan saya melalui Sistem Layanan Informasi Keuangan (SLIK) atau informasi lainnya
                                            dalam rangka proses analisa pembiayaan yang saya ajukan. Saya tidak akan menuntut secara secara Hukum dan apapun atas pencarian informasi tersebut.
                                          </p>
                                          <p>Dengan penyataan ini dibuat untuk dipergunakan sebagaimana mestinya.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-6" />
                                        <div className="col-6">
                                          <div className="icheck-primary d-inline"><input type="checkbox" id="checkboxPrimary3" /><label htmlFor="checkboxPrimary3">Saya setuju dengan
                                            pernyataan di atas</label></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>

                              </div>

                              <div className="modal-footer justify-content-between">
                                {/* Close modal button */}
                                <button type="button" className="btn btn-default" data-dismiss="modal">Kembali</button>
                                {/* Submit button */}
                                <button type="button" className="btn btn-success">Simpan</button>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="card mb-4">
                        <h5 className="card-header">
                          Status Permohonan</h5>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-4 col-12">
                            <div className="btn btn-lightgrey">
                                <span className="bs-stepper-circle" style={{ color: 'white', backgroundColor: 'dodgerblue' }}>1 </span>
                                <span className="bs-stepper-label">Permohonan Verifikasi Bank
                                </span>
                              </div>
                              {/* /.info-box */}
                            </div>
                            {/* /.col */}
                            <div className="col-sm-4 col-12">
                            <div className="btn btn-lightgrey">
                                <span className="bs-stepper-circle" style={{ color: 'white', backgroundColor: 'lightgrey' }}>2 </span>
                                <span className="bs-stepper-label">Dalam Proses Verifikasi Bank
                                </span>
                              </div>
                              {/* /.info-box */}
                            </div>
                            {/* /.col */}
                            <div className="col-sm-4 col-12">
                            <div className="btn btn-lightgrey">
                                <span className="bs-stepper-circle" style={{ color: 'white', backgroundColor: 'lightgrey' }}>3 </span>
                                <span className="bs-stepper-label">Tidak Lolos Verifikasi Bank
                                </span>
                              </div>
                              {/* /.info-box */}
                            </div>
                            {/* /.col */}
                          </div>
                          <div className="row">
                          <div className="col-sm-4 col-12">
                            <div className="btn btn-lightgrey">
                                <span className="bs-stepper-circle" style={{ color: 'white', backgroundColor: 'lightgrey' }}>4 </span>
                                <span className="bs-stepper-label">Lolos Verifikasi Bank
                                </span>
                              </div>
                              {/* /.info-box */}
                            </div>
                            <div className="col-sm-4 col-12">
                              <div className="btn btn-lightgrey">
                                <span className="bs-stepper-circle" style={{ color: 'white', backgroundColor: 'lightgrey' }}>5 </span>
                                <span className="bs-stepper-label">Melengkapi Persyaratan Bank
                                </span>
                              </div>
                              {/* /.info-box */}
                            </div>
                            {/* /.col */}
                            <div className="col-sm-4 col-12">
                            <div className="btn btn-lightgrey">
                                <span className="bs-stepper-circle" style={{ color: 'white', backgroundColor: 'lightgrey' }}>6 </span>
                                <span className="bs-stepper-label">Proses Verifikasi II Bank
                                </span>
                              </div>
                              {/* /.info-box */}
                            </div>
                            {/* /.col */}
                          </div>
                          <div className="row">
                            <div className="col-sm-4 col-12">
                            <div className="btn btn-lightgrey">
                                <span className="bs-stepper-circle" style={{ color: 'white', backgroundColor: 'lightgrey' }}>7 </span>
                                <span className="bs-stepper-label">Proses Persetujuan Bank
                                </span>
                              </div>
                              {/* /.info-box */}
                            </div>
                            {/* /.col */}
                            <div className="col-sm-4 col-12">
                            <div className="btn btn-lightgrey">
                                <span className="bs-stepper-circle" style={{ color: 'white', backgroundColor: 'lightgrey' }}>8 </span>
                                <span className="bs-stepper-label">Pencairan Dana Bank
                                </span>
                              </div>
                              {/* /.info-box */}
                            </div>
                            {/* /.col */}
                          </div>

                        </div>
                      </div>

                      <div className="card mb-4">
                        <div className="card-header align-items-center border-0 mt-1 mr-2">
                          <div className="card-title">
                            <h6 className="m-0">Data Pribadi </h6>
                            <p className="text-muted mb-0"><small>Rekam data pribadi</small></p>
                          </div>
                          <div className="card-tools">
                            <button type="button" id="#" data-toggle="modal" data-target="#modal-pribadi" className="btn btn-success">
                              <i className="fas fa-edit mr-2" />Update Data</button>
                          </div>
                          <div className="modal fade" id="modal-pribadi">
                            <div className="modal-dialog modal-pribadi">
                              <div className="modal-content ">
                                <div className="modal-header">
                                  <h5 className="modal-title">Update Data</h5>
                                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>

                                <form onSubmit={handleSubmitDataPribadi}>
                                  <div className="modal-body">
                                    <div className="form-group">
                                      <h6 className="m-0">Data Pribadi </h6>
                                      <p className="text-muted mb-0"><small>Rekam data pribadi</small></p>
                                    </div>

                                    <div className="row">
                                      <div className="col-sm-6">
                                        <div className="form-group">
                                          <label htmlFor="dafaultLabel" className="text-gray-dark">NIK</label>
                                          {/* <input type="number"
                                                  defaultValue={3172311249200012}
                                                  className="form-control" id="settings"
                                                  placeholder="Masukan NIK KTP anda"
                                                /> */}
                                          <input
                                            id="namaLengkap"
                                            type="text"
                                            placeholder=""
                                            className="form-control"
                                            value={nik}
                                            onChange={handleNikChange}
                                            maxLength={16}
                                            required
                                          />

                                        </div>
                                      </div>
                                      <div className="col-sm-6">
                                        <div className="form-group">
                                          <label>Pekerjaan</label>

                                          <select value={selectedPekerjaanOption} className="form-control select2"
                                            onChange={handleChangeOptionPekerjaan}
                                          >
                                            <option value="">Pilih Pekerjaan</option>
                                            {options.map(option => (
                                              <option key={option.id} value={option.id}>
                                                {option.namaPekerjaan}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <div className="form-group">
                                          <label htmlFor="dafaultLabel" className="text-gray-dark">NPWP</label>
                                          {/* <input type="text" defaultValue="48.903.330.3-823.000" className="form-control" id="settings" placeholder={"00"} /> */}

                                          <input
                                            id="npwp"
                                            type="text"
                                            placeholder=""
                                            className="form-control"
                                            value={npwp}
                                            onChange={handleNpwpChange}
                                            maxLength={15}
                                            required
                                          />


                                        </div>
                                      </div>
                                      <div className="col-sm-6">
                                        <div className="form-group">
                                          <label htmlFor="dafaultLabel" className="text-gray-dark">Penghasilan Perbulan</label>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">Rp.</span>
                                            </div>
                                            <input type="text" defaultValue="5.000.000,00-" className="form-control" id="settings" placeholder="x.xxx.xxx,-" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <div className="form-group">
                                          <label htmlFor="dafaultLabel" className="text-gray-dark">NO. KK</label>
                                          {/* <input type="text" defaultValue={3172311249200012} className="form-control" id="settings" placeholder="Masukan lokasi domisili saat ini" /> */}

                                          <input
                                            id="kk"
                                            type="text"
                                            placeholder=""
                                            className="form-control"
                                            value={kk}
                                            onChange={handleKkChange}
                                            maxLength={16}
                                            required
                                          />

                                        </div>
                                      </div>
                                      <div className="col-sm-6">
                                        <div className="form-group">
                                          <label htmlFor="dafaultLabel" className="text-gray-dark">Domisili Saat Ini</label>
                                          <input type="text" defaultValue="Jakarta" className="form-control" id="settings" placeholder="Masukan lokasi domisili saat ini" />
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="modal-footer justify-content-end align-content-between">
                                    <button type="submit" className="btn btn-success">Simpan</button>
                                  </div>
                                </form>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body mb-4">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="table-responsive">
                                <table className="table border-bottom border-right border-left">
                                  <tbody>
                                    <tr>
                                      <td>NIK</td>
                                      <td>:</td>
                                      <td>{data.nikSwadaya}</td>
                                    </tr>
                                    <tr>
                                      <td>NPWP</td>
                                      <td>:</td>
                                      <td>{data.npwp}</td>
                                    </tr>
                                    <tr>
                                      <td>Nomor KK</td>
                                      <td>:</td>
                                      <td>{data.nomorKk}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="table-responsive">
                                <table className="table border-bottom border-right border-left">
                                  <tbody>
                                    <tr>
                                      <td>Pekerjaan</td>
                                      <td>:</td>
                                      <td>{data.namaPekerjaan}</td>
                                    </tr>
                                    <tr>
                                      <td>Domisili Saat Ini</td>
                                      <td>:</td>
                                      <td>Jakarta</td>
                                    </tr>
                                    <tr>
                                      <td>Penghasilan Perbulan</td>
                                      <td>:</td>
                                      <td>Rp. 5.000.000,00-</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    {/* /.tab-pane */}
                    <div className="tab-pane" id="statussertifikasi">

                      <div className="card mb-4">
                        <h5 className="card-header">Pilih Rumah</h5>
                        <div className="card-body">
                          <div className="form-group">
                            <div className="mb-3">
                              <label>Pilih Rumah</label>
                              <select className="form-control" value={selectedOptionPerumahan} onChange={handleOptionChangePerumahan}>
                                <option value="">Pilih</option>
                                {optionsPerumahan.map(option2 => (
                                  <option key={option2.id} value={option2.id}>
                                    {option2.nama_perumahan}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mb-4">
                        <div className="card-header align-items-center border-0 mt-1 mr-2">

                          <div className="card-title">
                            <h6 className="m-0">Info Status</h6>
                            <p className="text-muted mb-4"><small>Tahapan status sertifikasi rumah</small></p>
                          </div>

                          <div className="card-tools">
                            <button type="button" id="tambahModal" data-toggle="modal" data-target="#KonfirmasiStatus" className="btn btn-success">
                              <i className="fas fa-save mr-2" />Simpan
                            </button>
                          </div>

                          <div className="modal fade" id="KonfirmasiStatus">
                            <div className="modal-dialog modal-sm">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h6 className="text-muted m-0">Update Status Sertifikasi Rumah</h6>
                                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <p>Apakah anda yakin ingin simpan?</p>
                                </div>
                                <div className="modal-footer justify-content-between">
                                  {/* Close modal button */}
                                  <button type="button" className="btn btn-default" data-dismiss="modal">Kembali</button>
                                  {/* Submit button */}
                                  <button type="button" className="btn btn-success" onClick={handleSubmitStatus}>Yakin</button>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="table-responsive mb-4">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th className="text-nowrap text-center text-gray-dark">📝 Perencanaan</th>
                                  <th className="text-nowrap text-center text-gray-dark">🏗️ Pelaksanaan</th>
                                  <th className="text-nowrap text-center text-gray-dark">🏡 Pemanfaatan</th>
                                  <th className="text-nowrap text-center text-gray-dark">🚜 Pembongkaran</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-control custom-checkbox d-flex justify-content-center">
                                      <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="sudahPerencanaan"
                                        checked={dataStatus.sudahPerencanaan}
                                        onChange={handleCheckboxChange}
                                      />
                                      <label htmlFor="sudahPerencanaan" className="custom-control-label" />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="custom-control custom-checkbox d-flex justify-content-center">
                                      <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="sudahPelaksanaan"
                                        checked={dataStatus.sudahPelaksanaan}
                                        onChange={handleCheckboxChange}
                                      />
                                      <label htmlFor="sudahPelaksanaan" className="custom-control-label" />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="custom-control custom-checkbox d-flex justify-content-center">
                                      <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="sudahPemanfaatan"
                                        checked={dataStatus.sudahPemanfaatan}
                                        onChange={handleCheckboxChange}
                                      />
                                      <label htmlFor="sudahPemanfaatan" className="custom-control-label" />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="custom-control custom-checkbox d-flex justify-content-center">
                                      <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="sudahPembongkaran"
                                        checked={dataStatus.sudahPembongkaran}
                                        onChange={handleCheckboxChange}
                                      />
                                      <label htmlFor="sudahPembongkaran" className="custom-control-label" />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>

                      <div>
                        <div className="card mb-4">
                          <div className="card-header align-items-center border-0 mt-1 mr-2">
                            <div className="card-title">
                              <h6 className="m-0">Jenis Pembangunan </h6>
                              <p className="text-muted mb-0"><small>Data Jenis Pembangunan</small></p>
                            </div>
                            <div className="card-tools">
                              <button type="button" id="#" data-toggle="modal" data-target="#update-lg" className="btn btn-success"><i className="fas fa-edit mr-1" /> Update Data</button>
                            </div>
                            <div className="modal fade" id="update-lg">
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content ">
                                  <div className="modal-header">
                                    <h5 className="modal-title">Update Data kategori dan Jenis pembangunan</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="form-group">
                                      <h6 className="m-0">Data Rumah </h6>
                                      <p className="text-muted mb-0"><small>Kategori pembangunan</small></p>
                                    </div>
                                    <form>
                                      <div className="row">
                                        <div className="col-sm-6">
                                          <div className="form-group">
                                            <label htmlFor="dafaultLabel" className="text-gray-dark">Kategori Rumah</label>
                                            <select value={kategoriRumah} onChange={handleKategoriRumah} className="form-control" required>
                                              <option value="">Pilih</option>
                                              <option value="0">BSPS</option>
                                              <option value="1">KPR</option>
                                            </select>

                                          </div>
                                        </div>
                                        <div className="col-sm-6">
                                          <div className="form-group">
                                            <label htmlFor="dafaultLabel" className="text-gray-dark">Jenis Pembangunan</label>
                                            <select value={jenisPembangunan} onChange={handleJenisPembangunan} className="form-control" required>
                                              <option value="">Pilih</option>
                                              <option value="0">Peningkatan Kualitas</option>
                                              <option value="1">Pembangunan Baru</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                    </form>
                                  </div>
                                  <div className="modal-footer justify-content-end align-content-between">
                                    <button type="button" onClick={handleSubmitCategoryDanJenis} className="btn btn-success">Simpan</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="table-responsive">
                                  <table className="table border-bottom border-right border-left">
                                    <tbody>
                                      <tr>
                                        <td>Jenis Rumah</td>
                                        <td>:</td>
                                        <td>{dataLokasi.jenis_perumahan === 0 ? 'Rumah Tapak' : 'Rumah Susun'}</td>
                                      </tr>
                                      <tr>
                                        <td>Kategori Rumah</td>
                                        <td>:</td>
                                        <td>{kategoriRumah == '0' ? 'BSPS' : 'KPR'}</td>
                                      </tr>
                                      <tr>
                                        <td>Jenis Pembangunan</td>
                                        <td>:</td>
                                        <td>{jenisPembangunan == '0' ? 'Peningkatan kualitas' : 'Pembangunan Baru'}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="table-responsive">
                                  <table className="table border-bottom border-right border-left">
                                    <tbody>
                                      <tr>
                                        <td>Tipe Unit</td>
                                        <td>:</td>
                                        <td>{dataLokasi.namaTipe}</td>
                                      </tr>
                                      <tr>
                                        <td>Luas Tanah</td>
                                        <td>:</td>
                                        <td>{dataLokasi.luasTanah} m2</td>
                                      </tr>
                                      <tr>
                                        <td>Luas Bangunan</td>
                                        <td>:</td>
                                        <td>{dataLokasi.luasBangunan} m2</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <br />
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <div className="d-flex align-items-center">
                                <div className="d-flex flex-column">
                                  <h6 className="m-0">Lokasi Perumahan</h6>
                                  <p className="text-muted mb-0"><small>Lokasi Perumahan yang diminati</small></p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="table-responsive">
                                  <table className="table border-bottom border-right border-left">
                                    <tbody>
                                      <tr>
                                        <td>Provinsi</td>
                                        <td>:</td>
                                        <td>{dataLokasi.prov}</td>
                                      </tr>
                                      <tr>
                                        <td>Kab/Kota</td>
                                        <td>:</td>
                                        <td>{dataLokasi.kab}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="table-responsive">
                                  <table className="table border-bottom border-right border-left">
                                    <tbody>
                                      <tr>
                                        <td>Kecamatan</td>
                                        <td>:</td>
                                        <td>{dataLokasi.kec}</td>
                                      </tr>
                                      <tr>
                                        <td>Kelurahan</td>
                                        <td>:</td>
                                        <td>{dataLokasi.kel}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card mb-4">
                          <div className="card-header align-items-center border-0 mt-1 mr-2">
                            <div className="card-title">
                              <h6 className="m-0">Pilih Bank Penyalur</h6>
                              <p className="text-muted mb-0"><small>Bank penyalur </small></p>
                            </div>
                            <div className="card-tools">
                              {/* <button type="button" id="#" data-toggle="modal" data-target="#pilihBank" className="btn btn-success btn-sm">Pilih Bank </button> */}

                              <button
                                type="button"
                                id="peminatBankModal" data-toggle="modal"
                                data-target="#ModalPeminatBank"
                                className="btn btn-success float-left"
                                onClick={handleOpenBankClick}
                              >Tambah Bank</button>


                            </div>
                          </div>
                          <div className="card-body mb-4">
                            <div className="added-cards">
                              <div className="cardMaster border p-3 rounded mb-3">
                                <div className="d-flex justify-content-between flex-sm-row flex-column">
                                  <div className="card-information">
                                    <img className="mb-3 img-fluid" src="logo/logo_bank/200.png" style={{ height: 50 }} alt="Logo Bank" />
                                    <h6 className="mb-1">Bank BTN </h6>
                                    <span className="card-number text-muted">PT. Bank Tabungan Negara</span>
                                  </div>
                                  <div className="d-flex flex-column text-start text-lg-end">
                                    <div className="d-flex order-sm-0 order-1">
                                      <button className="btn btn-outline-info mr-3" data-bs-toggle="modal" data-bs-target="#editCCModal">Edit </button>
                                      <button className="btn btn-outline-danger">Delete</button>
                                    </div>
                                    {/* <small class="mt-sm-auto mt-2 order-sm-1 order-0">tanggal at 12/26</small> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>

                    {/* /.tab-pane */}
                    <div className="tab-pane" id="dokumen">

                      <form onSubmit={handleSubmitDokumen}>
                        <div className="card mb-4">
                          {/* Notifications */}
                          <div className="card-header align-items-center border-0 mt-1 mr-2">
                            <div className="card-title">
                              <h6 className="m-0">File Dokumen KPR </h6>
                              <p className="text-muted mb-4"><small>Unggah file dokumen
                                Penyalur</small></p>
                            </div>
                            <div className="table-responsive mb-4">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th className="text-nowrap text-center text-gray-dark"> Nama Dokumen</th>
                                    <th className="text-nowrap text-center text-gray-dark">📄 File</th>
                                    <th style={{ width: '20%' }}>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>


                                  <tr>
                                    <td>Foto 3x4</td>
                                    <td>
                                      <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokPhoto3x4}`}
                                        target="blank" >Lihat File</a>
                                    </td>
                                    <td className="project-actions text-right">
                                      <input
                                        className="btn btn-info btn-sm"
                                        requireds
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setDokPhoto3x4(e.target.files[0])}
                                      />
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      KTP Suami/ Istri
                                    </td>
                                    <td>
                                      <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokKtpSuamiIstri}`}
                                        target="blank" >Lihat File</a>
                                    </td>
                                    {/* <td className="project-actions text-right">
                                              <a className="btn btn-info btn-sm" href="#">
                                                <i className="fas fa-upload">
                                                </i>
                                                Unggah
                                              </a>
                                            </td> */}

                                    <td className="project-actions text-right">
                                      <input
                                        className="btn btn-info btn-sm"
                                        requireds
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setDokKtpSuamiIstri(e.target.files[0])}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      NPWP
                                    </td>
                                    <td>

                                      <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokNpwp}`}
                                        target="blank" >Lihat File</a>
                                    </td>
                                    <td className="project-actions text-right">
                                      <input
                                        className="btn btn-info btn-sm"
                                        requireds
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setDokNpwp(e.target.files[0])}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Kartu Keluarga
                                    </td>
                                    <td>
                                      <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokKk}`}
                                        target="blank" >Lihat File</a>
                                    </td>
                                    <td className="project-actions text-right">
                                      <input
                                        className="btn btn-info btn-sm"
                                        requireds
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setDokKk(e.target.files[0])}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Surat Keterangan Kerja
                                    </td>
                                    <td>
                                      <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokKetKerja}`}
                                        target="blank" >Lihat File</a>
                                    </td>
                                    <td className="project-actions text-right">
                                      <input
                                        className="btn btn-info btn-sm"
                                        requireds
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setDokKetKerja(e.target.files[0])}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Slip Gaji
                                    </td>
                                    <td>
                                      {/* <a href="#" className="d-flex justify-content-center">Lihat File</a> */}
                                      <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokSlipGaji}`}
                                        target="blank" >Lihat File</a>
                                    </td>
                                    <td className="project-actions text-right">
                                      <input
                                        className="btn btn-info btn-sm"
                                        requireds
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setDokSlipGaji(e.target.files[0])}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Buku Nikah
                                    </td>
                                    <td>
                                      <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokBukuNikah}`}
                                        target="blank" >Lihat File</a>
                                    </td>

                                    <td className="project-actions text-right">
                                      <input
                                        className="btn btn-info btn-sm"
                                        requireds
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setDokBukuNikah(e.target.files[0])}
                                      />
                                    </td>

                                  </tr>
                                  <tr>
                                    <td>
                                      Rekening Koran
                                    </td>
                                    <td>
                                      <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokRekKoran}`}
                                        target="blank" >Lihat File</a>
                                    </td>

                                    {/* <td className="project-actions text-right">
                                              <a className="btn btn-info btn-sm" href="#">
                                                <i className="fas fa-upload">
                                                </i>
                                                Unggah
                                              </a>
                                            </td> */}

                                    <td className="project-actions text-right">
                                      <input
                                        className="btn btn-info btn-sm"
                                        requireds
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setDokRekKoran(e.target.files[0])}
                                      />
                                    </td>


                                  </tr>
                                  <tr>
                                    <td>
                                      Surat Keterangan Gaji 3 bulan
                                    </td>
                                    <td>
                                      <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokGaji3Bulan}`}
                                        target="blank" >Lihat File</a>
                                    </td>
                                    <td className="project-actions text-right">
                                      <input
                                        className="btn btn-info btn-sm"
                                        requireds
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setDokGaji3Bulan(e.target.files[0])}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Surat Pernyataan Belum Memiliki Rumah
                                    </td>
                                    <td>
                                      <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokBelumMilikiRumah}`}
                                        target="blank" >Lihat File</a>
                                    </td>
                                    <td className="project-actions text-right">
                                      <input
                                        className="btn btn-info btn-sm"
                                        requireds
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setDokBelumMilikiRumah(e.target.files[0])}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Surat Keterangan Belum Pernah Menerima Subsidi
                                    </td>
                                    <td>
                                      <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokBelumTerimaSubsidi}`}
                                        target="blank" >Lihat File</a>
                                    </td>
                                    <td className="project-actions text-right">
                                      <input
                                        className="btn btn-info btn-sm"
                                        requireds
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setDokBelumTerimaSubsidi(e.target.files[0])}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {/* /Notifications */}
                            <div class="mb-4">
                              <button className="btn btn-success" >Simpan Dokumen</button>
                            </div>
                          </div>
                        </div>



                      </form>


                    </div>
                    {/* /.tab-pane */}


                  </div>
                  {/* /.tab-content */}


                  {/* /.card */}
                </div>
                {/* /.col */}





              </div>
            }
          </div>
        </section >


        {isModalBankOpen && <ModalPeminatBanks onClose={handleModalBankClose}
          peminatId={data.id}
        />
        }

      </div >



    </>
  )
}

export default ProfileDokumen
