
import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavBarAtas from './NavBarAtas';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const RegisterNew = () => {

  require("./../../css/common.css");


  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [passwordMatchErrorInstansi, setPasswordMatchErrorInstansi] = useState(false);
  const [passwordMatchErrorBank, setPasswordMatchErrorBank] = useState(false);
  const [passwordMatchErrorPerorangan, setPasswordMatchErrorPerorangan] = useState(false);

  const [currentTab, setCurrentTab] = useState(1);
  const [isActivePengembang, setIsActivePengembang] = useState(true);
  const [isActivePemerintah, setIsActivePemerintah] = useState(false);
  const [isActivePerbankan, setIsActivePerbankan] = useState(false);
  const [isActivePerorangan, setIsActivePerorangan] = useState(false);
  const [dataDetail, setDataDetail] = useState({});

  const [options, setOptions] = useState([]);
  const [optionsInstansi, setOptionsInstansi] = useState([]);
  const [optionsBank, setOptionsBank] = useState([]);
  // const [optionsProvinsi, setOptionsProvinsi] = useState([]);


  const [selectedPekerjaanOption, setSelectedPekerjaanOption] = useState('');
  const [selectedInstansiOption, setSelectedInstansiOption] = useState('');
  const [selectedBankOption, setSelectedBankOption] = useState('');


  // const [selectedProvinsiOption, setSelectedProvinsiOption] = useState('');


  const navigate = useNavigate()

  const handleOptionPekerjaanChange = event => {
    setSelectedPekerjaanOption(event.target.value);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });


  const apiUrl = process.env.REACT_APP_API_ENDPOINT;


  useEffect(() => {

    (async () => {
      await fetchOptionsPekerjaanFromApi()
        .then(data => setOptions(data))
        .catch(error => console.error('Error fetching options:', error));

      await fetchOptionsInstansiFromApi()
        .then(data => setOptionsInstansi(data))
        .catch(error => console.error('Error fetching options:', error));

      await fetchOptionsBankFromApi()
        .then(data => setOptionsBank(data))
        .catch(error => console.error('Error fetching options:', error));

      // await fetchOptionProvinsiFromApi()
      //   .then(data => setOptionsProvinsi(data))
      //   .catch(error => console.error('Error fetching options:', error));

    })();


  }, []);

  const fetchOptionsPekerjaanFromApi = async () => {
    try {
      const response = await fetch(apiUrl + '/api/public/getAllPekerjaan');
      const data = await response.json();
      // console.log('data pekerjaan ....');
      // console.log(data);
      return data;
    } catch (error) {
      throw new Error('Failed to fetch options from API');
    }
  };

  const fetchOptionsInstansiFromApi = async () => {
    try {
      const response = await fetch(apiUrl + '/api/public/getAllInstansi');
      const data = await response.json();
      // console.log('data instansi ....');
      // console.log(data);
      return data;
    } catch (error) {
      throw new Error('Failed to fetch options from API');
    }
  };

  const fetchOptionsBankFromApi = async () => {
    try {
      const response = await fetch(apiUrl + '/api/public/getAllBanks');
      const data = await response.json();
      // console.log('data instansi ....');
      // console.log(data);
      return data;
    } catch (error) {
      throw new Error('Failed to fetch options from API');
    }
  };

  // const fetchOptionProvinsiFromApi = async () => {
  //   try {
  //     const response = await fetch(apiUrl + '/api/public/getProvinsi');
  //     const data = await response.json();
  //     console.log('data provinsi ....');
  //     console.log(data);
  //     return data;
  //   } catch (error) {
  //     throw new Error('Failed to fetch options from API');
  //   }
  // };

  const [formData, setFormData] = useState({
    namaLengkap: '',
    handphone: '',
    namaBadan: '',
    email: '',
    password: '',
    password2: ''
  });

  const [formDataInstansi, setFormDataInstansi] = useState({
    namaLengkap: '',
    handphone: '',
    namaBadan: '',
    email: '',
    password: '',
    password2: ''
  });

  const [formDataPerorangan, setFormDataPerorangan] = useState({
    namaLengkap: '',
    handphone: '',
    pekerjaan: '',
    email: '',
    password: '',
    password2: ''
  });

  const [formDataBank, setFormDataBank] = useState({
    namaLengkap: '',
    handphone: '',
    namaBadan: '',
    email: '',
    password: '',
    password2: ''
  });

  const [errors, setErrors] = useState({
    namaLengkap: '',
    handphone: '',
    namaBadan: '',
    email: '',
    password: '',
    password2: ''
  });


  const [errorsInstansi, setErrorsInstansi] = useState({
    namaLengkap: '',
    handphone: '',
    selectedInstansiOption: '',
    selectedProvinsiOption: '',
    email: '',
    password: '',
    password2: ''
  });


  const [errorsBank, setErrorsBank] = useState({
    namaLengkap: '',
    handphone: '',
    namaBadan: '',
    email: '',
    password: '',
    password2: ''
  });

  const [errorsPerorangan, setErrorsPerorangan] = useState({
    namaLengkap: '',
    handphone: '',
    pekerjaan: '',
    email: '',
    password: '',
    password2: ''
  });

  const handleChange = (e) => {

    // console.log('handle change on tab position ' + currentTab);

    if (currentTab === 1) {
      setFormData({
        ...formData,
        [e.target.id]: e.target.value,
      });

      const { id, value } = e.target;
      // Reset error message when user starts typing
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: '',
      }));

      // Validasi email
      if (id === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          setErrors({ ...errorsInstansi, email: 'Email tidak valid' });
        } else {
          setErrors({ ...errorsInstansi, email: '' });
        }
      }

    } else if (currentTab === 2) {

      setFormDataInstansi({
        ...formDataInstansi,
        [e.target.id]: e.target.value,
      });

      const { id, value } = e.target;
      // Reset error message when user starts typing
      setErrorsInstansi((prevErrors) => ({
        ...prevErrors,
        [id]: '',
      }));

      // Validasi email
      if (id === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          setErrorsInstansi({ ...errorsInstansi, email: 'Email tidak valid' });
        } else {
          setErrorsInstansi({ ...errorsInstansi, email: '' });
        }
      }

      if (id === 'optionselectinstansi') {
        setSelectedInstansiOption(e.target.value);
      }

      // if (id === 'optionselectprovinsi') {
      //   setSelectedProvinsiOption(e.target.value);
      // }

    } else if (currentTab === 3) {
      setFormDataBank({
        ...formDataBank,
        [e.target.id]: e.target.value,
      });

      const { id, value } = e.target;
      // Reset error message when user starts typing
      setErrorsBank((prevErrors) => ({
        ...prevErrors,
        [id]: '',
      }));

      // Validasi email
      if (id === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          setErrorsBank({ ...errorsInstansi, email: 'Email tidak valid' });
        } else {
          setErrorsBank({ ...errorsInstansi, email: '' });
        }
      }

      if (id === 'optionselectbank') {
        setSelectedBankOption(e.target.value);
      }


    } else if (currentTab === 4) {

      setFormDataPerorangan({
        ...formDataPerorangan,
        [e.target.id]: e.target.value,
      });
      // console.log('on change tab 4 = ' + e.target.value);


      const { id, value } = e.target;
      // Reset error message when user starts typing
      setErrorsPerorangan((prevErrors) => ({
        ...prevErrors,
        [id]: '',
      }));

      // Validasi email
      if (id === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          setErrorsPerorangan({ ...errorsPerorangan, email: 'Email tidak valid' });
        } else {
          setErrorsPerorangan({ ...errorsPerorangan, email: '' });
        }
      }

      // console.group('handel change option select...')

      if (id === 'optionselect') {
        setSelectedPekerjaanOption(e.target.value);
      }


    }

  };


  const handleBlur = () => {

    if (currentTab === 1) {
      if (formData.password !== formData.password2) {
        setPasswordMatchError(true);
        // alert('Isian password Pengembang tidak sama..')
        return;
      } else {
        console.log('password sama...')
        setPasswordMatchError(false);
      }
    } else if (currentTab === 2) {
      if (formDataInstansi.password !== formDataInstansi.password2) {
        setPasswordMatchError(true);
        // alert('Isian password Instansi tidak sama..')
        return;
      } else {
        console.log('password sama...')
        setPasswordMatchError(false);
      }
    } else if (currentTab === 3) {
      if (formDataBank.password !== formDataBank.password2) {
        setPasswordMatchError(true);
        // alert('Isian password Badan Usaha tidak sama..')
        return;
      } else {
        console.log('password sama...')
        setPasswordMatchError(false);
      }
    } else if (currentTab === 4) {
      if (formDataPerorangan.password !== formDataPerorangan.password2) {
        setPasswordMatchError(true);
        // alert('Isian password Badan Usaha tidak sama..')
        return;
      } else {
        console.log('password sama...')
        setPasswordMatchError(false);
      }
    }

  };

  const handleFocus = () => {

    if (currentTab === 1) {
      setPasswordMatchError(false);
    } else if (currentTab === 2) {
      setPasswordMatchErrorInstansi(false);
    } else if (currentTab === 3) {
      setPasswordMatchErrorBank(false);
    } else if (currentTab === 4) {
      setPasswordMatchErrorPerorangan(false);
    }

  };

  const handleClick = (paramValue) => {
    setCurrentTab(paramValue)
    if (paramValue == 1) {
      setIsActivePengembang(true)
      setIsActivePemerintah(false)
      setIsActivePerbankan(false)
      setIsActivePerorangan(false)
    } else if (paramValue == 2) {
      setIsActivePengembang(false)
      setIsActivePemerintah(true)
      setIsActivePerbankan(false)
      setIsActivePerorangan(false)
    } else if (paramValue == 3) {
      setIsActivePengembang(false)
      setIsActivePemerintah(false)
      setIsActivePerbankan(true)
      setIsActivePerorangan(false)
    } else if (paramValue == 4) {
      setIsActivePengembang(false)
      setIsActivePemerintah(false)
      setIsActivePerbankan(false)
      setIsActivePerorangan(true)
    }
  };


  // const renderForm = () => {
  //   switch (currentTab) {
  //     case 1:
  //       return <Form1 />;
  //     case 2:
  //       return <Form2 />;
  //     case 3:
  //       return <Form3 />;
  //     default:
  //       return null;
  //   }

  // };


  const handleLogin = async (e) => {
    navigate('/login');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();


    const newErrors = {};
    const newErrorsInstansi = {};
    const newErrorsBank = {};
    const newErrorsPerorangan = {};

    if (currentTab === 1) {
      if (!formData.namaLengkap.trim()) {
        newErrors.namaLengkap = 'Nama Lengkap harus diisi';
      }
      if (!formData.handphone.trim()) {
        newErrors.handphone = 'No. Handphone harus diisi';
      }
      if (!formData.namaBadan.trim()) {
        newErrors.namaBadan = 'Nama Perusahaan harus diisi';
      }
      if (!formData.email.trim()) {
        newErrors.email = 'Email harus diisi';
      }
      if (!formData.password.trim()) {
        newErrors.password = 'Password pertama harus di isi';
      }
      if (!formData.password2.trim()) {
        newErrors.password2 = 'Password kedua harus di isi';
      }
      if (formData.password !== formData.password2) {
        setPasswordMatchError(true);
        // alert('Isian password pengembang tidak sama..')

        //icon : success, info, error, warning,question
        // Toast.fire({
        //   icon: 'error',
        //   title: 'Isian password pengembang tidak sama..'
        // });

        toast.error('Isian password pengembang tidak sama..')


        return;
      } else {
        setPasswordMatchError(false);
      }
    } else if (currentTab === 2) {
      if (!formDataInstansi.namaLengkap.trim()) {
        newErrorsInstansi.namaLengkap = 'Nama Lengkap harus diisi';
      }

      if (!formDataInstansi.handphone.trim()) {
        newErrorsInstansi.handphone = 'No. Handphone harus diisi';
      }

      if (!selectedInstansiOption) {
        newErrorsInstansi.selectedInstansiOption = 'Nama Instansi harus diisi';
      }

      // if (!selectedProvinsiOption) {
      //   newErrorsInstansi.selectedProvinsiOption = 'Provinsi harus diisi';
      // }

      if (!formDataInstansi.email.trim()) {
        newErrorsInstansi.email = 'Email harus diisi';
      }
      if (!formDataInstansi.password.trim()) {
        newErrorsInstansi.password = 'Password pertama harus di isi';
      }
      if (!formDataInstansi.password2.trim()) {
        newErrorsInstansi.password2 = 'Password kedua harus di isi';
      }
      if (formDataInstansi.password !== formDataInstansi.password2) {
        setPasswordMatchErrorInstansi(true);
        // alert('Isian password instansi tidak sama..')
        //icon : success, info, error, warning,question
        // Toast.fire({
        //   icon: 'error',
        //   title: 'Isian password instansi tidak sama..'
        // });

        toast.error('Isian password instansi tidak sama..')

        return;
      } else {
        setPasswordMatchErrorInstansi(false);
      }
    } else if (currentTab === 3) {
      if (!formDataBank.namaLengkap.trim()) {
        newErrorsBank.namaLengkap = 'Nama Lengkap harus diisi';
      }
      if (!formDataBank.handphone.trim()) {
        newErrorsBank.handphone = 'No. Handphone harus diisi';
      }
      // if (!formDataBank.namaBadan.trim()) {
      //   newErrorsBank.namaBadan = 'Nama Badan Usaha harus diisi';
      // }

      if (!selectedBankOption) {
        newErrorsBank.selectedBankOption = 'Nama Bank harus diisi';
      }



      if (!formDataBank.email.trim()) {
        newErrorsBank.email = 'Email harus diisi';
      }
      if (!formDataBank.password.trim()) {
        newErrorsBank.password = 'Password pertama harus di isi';
      }
      if (!formDataBank.password2.trim()) {
        newErrorsBank.password2 = 'Password kedua harus di isi';
      }
      if (formDataBank.password !== formDataBank.password2) {
        setPasswordMatchErrorBank(true);
        // alert('Isian password badan usaha tidak sama..')
        //icon : success, info, error, warning,question
        // Toast.fire({
        //   icon: 'error',
        //   title: 'Isian password badan usaha tidak sama..'
        // });

        toast.error('Isian password badan usaha tidak sama..')

        return;
      } else {
        setPasswordMatchErrorBank(false);
      }
    } else if (currentTab === 4) {
      if (!formDataPerorangan.namaLengkap.trim()) {
        newErrorsPerorangan.namaLengkap = 'Nama Lengkap harus diisi';
      }
      if (!formDataPerorangan.handphone.trim()) {
        newErrorsPerorangan.handphone = 'No. Handphone harus diisi';
      }
      if (!selectedPekerjaanOption.trim()) {
        newErrorsPerorangan.pekerjaan = 'Pekerjaan harus diisi';
      }
      if (!formDataPerorangan.email.trim()) {
        newErrorsPerorangan.email = 'Email harus diisi';
      }
      if (!formDataPerorangan.password.trim()) {
        newErrorsPerorangan.password = 'Password pertama harus di isi';
      }
      if (!formDataPerorangan.password2.trim()) {
        newErrorsPerorangan.password2 = 'Password kedua harus di isi';
      }
      if (formDataPerorangan.password !== formDataPerorangan.password2) {
        setPasswordMatchErrorPerorangan(true);
        // alert('Isian password badan usaha tidak sama..')
        //icon : success, info, error, warning,question
        // Toast.fire({
        //   icon: 'error',
        //   title: 'Isian password badan usaha tidak sama..'
        // });

        toast.error('Isian password perorangan tidak sama..')

        return;
      } else {
        setPasswordMatchErrorPerorangan(false);
      }
    }


    // console.log('MASUKKKKKKKKKKKKKKKKKKKK SINI.............');





    if (currentTab === 1) {
      if (Object.keys(newErrors).length > 0) {
        // Set errors and prevent form submission
        setErrors(newErrors);
        return;
      }
    } else if (currentTab === 2) {

      if (Object.keys(newErrorsInstansi).length > 0) {

        setErrorsInstansi(newErrorsInstansi);
        return;
      }
    } else if (currentTab === 3) {
      if (Object.keys(newErrorsBank).length > 0) {
        // Set errors and prevent form submission
        setErrorsBank(newErrorsBank);
        return;
      }
    } else if (currentTab === 4) {
      if (Object.keys(newErrorsPerorangan).length > 0) {
        // Set errors and prevent form submission
        setErrorsPerorangan(newErrorsPerorangan);
        return;
      }
    }



    var rolee;

    if (currentTab === 1) {
      rolee = ['pengembang']
    } else if (currentTab === 2) {
      rolee = ['pemda']
    } else if (currentTab === 3) {
      rolee = ['igahp_bulain']
    } else if (currentTab === 4) {
      rolee = ['igahp_perorangan']
    }

    var requestBody = '';

    if (currentTab === 1) {

      requestBody = {
        // username: (formData.username === '' ? formData.email.split('@')[0] : formData.username),
        username: formData.email,
        password: formData.password,
        email: formData.email,
        // role: [selectedRoleName.toLowerCase().replace(/^role_/, "")]
        role: rolee
      };

    } else if (currentTab === 2) {

      requestBody = {
        // username: (formData.username === '' ? formData.email.split('@')[0] : formData.username),
        username: formDataInstansi.email,
        password: formDataInstansi.password,
        email: formDataInstansi.email,
        // role: [selectedRoleName.toLowerCase().replace(/^role_/, "")]
        role: rolee,
        nohp: formDataInstansi.handphone,
        instansiId: selectedInstansiOption,
        // provinsiId: selectedProvinsiOption
      };

    } else if (currentTab === 3) {

      requestBody = {
        // username: (formData.username === '' ? formData.email.split('@')[0] : formData.username),
        username: formDataBank.email,
        password: formDataBank.password,
        email: formDataBank.email,
        // role: [selectedRoleName.toLowerCase().replace(/^role_/, "")]
        bankId: selectedBankOption,
        role: rolee
      };

    } else if (currentTab === 4) {

      requestBody = {
        // username: (formData.username === '' ? formData.email.split('@')[0] : formData.username),
        username: formDataPerorangan.email,
        password: formDataPerorangan.password,
        email: formDataPerorangan.email,
        pekerjaan: selectedPekerjaanOption,
        nohp: formDataPerorangan.handphone,
        namaLengkap: formDataPerorangan.namaLengkap,
        // role: [selectedRoleName.toLowerCase().replace(/^role_/, "")]
        role: rolee
      };

    }


    const url = apiUrl + '/api/auth/signup'; // Replace with your API endpoint

    const postConfig = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    axios.post(url, requestBody, postConfig)
      .then(response => {
        // Handle successful response
        console.log('Response:', response);
        // alert(response)
        Toast.fire({
          icon: 'success',
          title: response.data.message
        });
        // simpanUserDetail(response.userId, formData.email);
      })
      .catch(error => {
        // Handle error
        if (error.response) {
          // console.error('Server responded with non-2xx status:', error.response.status);
          // console.error('Response data:', error.response.data);


          // Toast.fire({
          //   icon: 'error',
          //   title: error.response.data.message
          // });

          toast.error(error.response.data.message)
        } else if (error.request) {
          // console.error('No response received from server:', error.request);
          // Toast.fire({
          //   icon: 'error',
          //   title: 'No response received from server' 
          // });
          toast.error('No response received from server')
        } else {
          // console.error('Error setting up the request:', error.message);
          // Toast.fire({
          //   icon: 'error',
          //   title: 'Error setting up the request:' + error.meessage
          // });
          toast.error('Error setting up the request')
        }
      });

    // const url = apiUrl + '/api/auth/signup'; // Replace with your API endpoint
    // try {
    //   await fetch(url, {
    //     method: 'POST',
    //     body: JSON.stringify(requestBody),
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   })
    //     .then(response => response.json())
    //     .then(data => {

    //       const resp = JSON.parse(JSON.stringify(data));
    //       if (!resp.message.includes('Error')) {
    //         simpanUserDetail(resp.userId, formData.email);
    //       } else {
    //           Toast.fire({
    //           icon: 'error',
    //           title: resp.message
    //         });
    //       }

    //     })
    //     .catch(error => {

    //       console.error(error);
    //       alert(error);
    //     });

    // } catch (error) {
    //   console.error('An error occurred:', error);
    // }


  };


  const simpanUserDetail = async (userId, Email) => {

    dataDetail['user_id'] = userId;
    dataDetail['email'] = Email;

    // console.log('data detail sebelum dikirim...')
    // console.log(dataDetail);
    try {
      const response = await fetch(apiUrl + '/api/public/signupDetail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataDetail),
      })

        .then(response => response.json())
        .then(data => {

          console.log(data);
          const resp = JSON.parse(JSON.stringify(data));
          alert(resp.message);
          // Toast.fire({
          //   icon: 'success',
          //   title: resp.message
          // });
          navigate('/login');

        })
        .catch(error => {

          console.error(error);
          alert(error);

          navigate('/login');

        });


    } catch (error) {
      console.error('An error occurred:', error);
    }

  }



  const disableScroll = (e) => {
    e.preventDefault()
  }

  const ignoreScroll = useRef(null)

  useEffect(() => {
    ignoreScroll.current && ignoreScroll.current.addEventListener("wheel", disableScroll);
  }, [ignoreScroll]);

  const handleEmailBlur = (emailnih) => {
    // Check if the email contains '@' before making the API call
    if (emailnih.includes('@')) {
      axios.get(apiUrl + `/api/public/checkEmail/${emailnih}`)
        .then(response => {
          // Handle the response from the API
          console.log(response);
          if (response.data) {
            alert('Email ini sudah terdaftar');
          } else {
            // Additional logic if email is not registered
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else {
      // Handle invalid email format
      // alert('Email tidak valid');
    }
  };

  const handleHandphoneBlur = (nohp) => {
    // Check if the email contains '@' before making the API call

    axios.get(apiUrl + `/api/public/checkEmail/${nohp}`)
      .then(response => {
        // Handle the response from the API
        console.log(response);
        if (response.data) {
          alert('Nomor hp ini sudah terdaftar');
        } else {
          // Additional logic if email is not registered
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });

  };


  return (
    <>
      <NavBarAtas showButton={false} />


      <section className="wrapper-igahp-register">

      <div className="background-left-register">
        <div className="login-logo-content">
          <img src="/dist/img/lentera_logo.svg" width={60} height={60} alt="logo lentera hijau" />
          <div className="logo-title-brand">
            <div className="igahp-l-semi-bold neutral-90-text">LENTERA HIJAU</div>
            {/* <div className="igahp-m-regular">Layanan Terpadu Data Hunian Terjangkau</div> */}
          </div>
        </div>
        </div>

        <div className="igahp-register">
          <div className="igahp-h2-semi-bold neutral-90-text">PENDAFTARAN AKUN</div>

          <div className="igahp-list-role-register">


            <div onClick={() => { handleClick(1) }}>
              <div className={`igahp-list-role-item ${isActivePengembang ? 'active' : ''}`} >
                <svg width={41} height={40} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M37.8335 32.8125H35.021V18.0547C35.0206 17.7516 34.9575 17.4519 34.8357 17.1744C34.7139 16.8969 34.536 16.6476 34.3132 16.4422L21.8132 4.64685L21.7991 4.63435C21.3975 4.26652 20.8726 4.0625 20.328 4.0625C19.7834 4.0625 19.2586 4.26652 18.8569 4.63435L18.8429 4.64685L6.34287 16.4437C6.12178 16.6496 5.94571 16.899 5.82578 17.1762C5.70584 17.4535 5.64463 17.7526 5.646 18.0547V32.8125H2.8335C2.58486 32.8125 2.3464 32.9112 2.17058 33.0871C1.99477 33.2629 1.896 33.5013 1.896 33.75C1.896 33.9986 1.99477 34.2371 2.17058 34.4129C2.3464 34.5887 2.58486 34.6875 2.8335 34.6875H37.8335C38.0821 34.6875 38.3206 34.5887 38.4964 34.4129C38.6722 34.2371 38.771 33.9986 38.771 33.75C38.771 33.5013 38.6722 33.2629 38.4964 33.0871C38.3206 32.9112 38.0821 32.8125 37.8335 32.8125ZM7.521 18.0547C7.5209 18.0112 7.52989 17.9681 7.54737 17.9283C7.56486 17.8885 7.59047 17.8528 7.62256 17.8234L7.63662 17.8109L20.1272 6.01716C20.1842 5.96749 20.2572 5.94012 20.3327 5.94012C20.4083 5.94012 20.4813 5.96749 20.5382 6.01716L33.0304 17.8125L33.0429 17.825C33.0752 17.8542 33.1011 17.8899 33.1189 17.9297C33.1367 17.9695 33.1459 18.0126 33.146 18.0562V32.8125H25.021V25C25.021 24.4198 24.7905 23.8634 24.3803 23.4532C23.9701 23.0429 23.4137 22.8125 22.8335 22.8125H17.8335C17.2533 22.8125 16.6969 23.0429 16.2867 23.4532C15.8765 23.8634 15.646 24.4198 15.646 25V32.8125H7.521V18.0547ZM23.146 32.8125H17.521V25C17.521 24.9171 17.5539 24.8376 17.6125 24.779C17.6711 24.7204 17.7506 24.6875 17.8335 24.6875H22.8335C22.9164 24.6875 22.9959 24.7204 23.0545 24.779C23.1131 24.8376 23.146 24.9171 23.146 25V32.8125Z" fill="#404040" />
                </svg>
                <div className="text-layout-column">
                <div className="igahp-m-medium neutral-90-text">Pengembang</div>
                <div className="igahp-xs-regular neutral-60-text">Daftar Pengembang</div>
                </div>
              </div>
            </div>

            <div onClick={() => { handleClick(2) }}>
              <div className={`igahp-list-role-item ${isActivePemerintah ? 'active' : ''}`} >
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35 2.5H20C19.3372 2.50066 18.7017 2.76427 18.233 3.23296C17.7643 3.70166 17.5007 4.33716 17.5 5V17.5H5C4.33716 17.5007 3.70166 17.7643 3.23296 18.233C2.76427 18.7017 2.50066 19.3372 2.5 20V37.5H37.5V5C37.4993 4.33716 37.2357 3.70166 36.767 3.23296C36.2983 2.76427 35.6628 2.50066 35 2.5ZM11.25 35V26.25H16.25V35H11.25ZM35 35H18.75V25C18.75 24.6685 18.6183 24.3505 18.3839 24.1161C18.1495 23.8817 17.8315 23.75 17.5 23.75H10C9.66848 23.75 9.35054 23.8817 9.11612 24.1161C8.8817 24.3505 8.75 24.6685 8.75 25V35H5V20H20V5H35V35Z" fill="#404040" />
                  <path d="M22.5 10H25V12.5H22.5V10ZM30 10H32.5V12.5H30V10ZM22.5 17.5H25V20H22.5V17.5ZM30 17.5H32.5V20H30V17.5ZM22.5 25H25V27.5H22.5V25ZM30 25H32.5V27.5H30V25Z" fill="#404040" />
                </svg>
                <div className="text-layout-column">
                <div className="igahp-m-medium neutral-90-text">Instansi Pemerintah</div>
                <div className="igahp-xs-regular neutral-60-text">Daftar Instansi Pemerintah
                </div>
                </div>
              </div>
            </div>

            <div onClick={() => { handleClick(3) }}>
              <div className={`igahp-list-role-item ${isActivePerbankan ? 'active' : ''}`} >
                <svg width={41} height={40} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.6672 11.7477C21.1093 11.7477 21.5333 11.572 21.8459 11.2594C22.1586 10.9468 22.3342 10.5228 22.3342 10.0807C22.3342 9.63854 22.1586 9.21453 21.8459 8.90191C21.5333 8.58929 21.1093 8.41366 20.6672 8.41366C20.2251 8.41366 19.8011 8.58929 19.4884 8.90191C19.1758 9.21453 19.0002 9.63854 19.0002 10.0807C19.0002 10.5228 19.1758 10.9468 19.4884 11.2594C19.8011 11.572 20.2251 11.7477 20.6672 11.7477ZM32.6672 26.6797V15.9997H32.8512C34.6372 15.9997 35.3712 13.7037 33.9112 12.6697L22.2112 4.36566C21.7601 4.04521 21.2205 3.87305 20.6672 3.87305C20.1139 3.87305 19.5743 4.04521 19.1232 4.36566L7.41918 12.6697C5.96318 13.7037 6.69318 15.9977 8.47918 15.9977H8.66718V26.6777C7.46518 27.4497 6.66718 28.7977 6.66718 30.3317V32.9977C6.66718 33.2629 6.77254 33.5172 6.96008 33.7048C7.14761 33.8923 7.40197 33.9977 7.66718 33.9977H33.6672C33.9324 33.9977 34.1868 33.8923 34.3743 33.7048C34.5618 33.5172 34.6672 33.2629 34.6672 32.9977V30.3317C34.6672 28.7977 33.8712 27.4497 32.6672 26.6797ZM20.2792 5.99766C20.3917 5.91792 20.5263 5.8751 20.6642 5.8751C20.8021 5.8751 20.9366 5.91792 21.0492 5.99766L32.3272 13.9977H9.00318L20.2792 5.99766ZM30.6632 15.9977V26.0097C30.5527 26.0015 30.442 25.9975 30.3312 25.9977H26.6632V15.9997L30.6632 15.9977ZM10.6672 26.0097V15.9997H14.6672V25.9997H10.9992C10.8872 25.9997 10.7752 26.0037 10.6652 26.0117M10.9992 27.9997H30.3332C31.6212 27.9997 32.6652 29.0437 32.6652 30.3337V31.9997H8.66718V30.3337C8.66718 29.0437 9.71118 27.9997 10.9992 27.9997ZM19.6672 25.9997H16.6672V15.9997H19.6672V25.9997ZM21.6672 25.9997V15.9997H24.6672V25.9997H21.6672Z" fill="#404040" />
                </svg>
                <div className="text-layout-column">
                <div className="igahp-m-medium neutral-90-text">Perbankan/ Badan Usaha</div>
                <div className="igahp-xs-regular neutral-60-text">Daftar Perbankan/ Badan Usaha
                  lain</div>
                  </div>
              </div>
            </div>

            <div onClick={() => { handleClick(4) }}>
              <div className={`igahp-list-role-item ${isActivePerorangan ? 'active' : ''}`} >
                <svg width={41} height={40} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" fill="#404040" />
                </svg>
                <div className="text-layout-column">
                <div className="igahp-m-medium neutral-90-text">Perorangan</div>
                <div className="igahp-xs-regular neutral-60-text">Daftar Perorangan lain</div>
              </div>
              </div>
            </div>


          </div>

          {/* {renderForm()} */}


          <>
            {currentTab === 1 &&

              <>


                <div className="igahp-form-register">


                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Nama Lengkap</div>
                    <input
                      id="namaLengkap"
                      type="text"
                      placeholder="John Doe"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errors.namaLengkap && 'input-error'
                        }`}
                      value={formData.namaLengkap}
                      onChange={handleChange}
                    />
                    {errors.namaLengkap && <div className="error-message text-danger">{errors.namaLengkap}</div>}
                  </div>

                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">No. Handphone</div>
                    <input
                      id="handphone"
                      ref={ignoreScroll}
                      type="text"
                      placeholder="081100010002"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errors.handphone && 'input-error'
                        }`}
                      value={formData.handphone}
                      onChange={handleChange}
                      onBlur={handleHandphoneBlur(formData.handphone)}
                    />
                    {errors.handphone && <div className="error-message text-danger">{errors.handphone}</div>}
                  </div>



                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Nama Perusahaan</div>
                    <input
                      id="namaBadan"
                      type="text"
                      placeholder="PT. NAMA PENGEMBANG"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errors.namaBadan && 'input-error'
                        }`}

                      value={formData.namaBadan}
                      onChange={handleChange}
                    />
                    {errors.namaBadan && <div className="error-message text-danger">{errors.namaBadan}</div>}
                  </div>

                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Email</div>
                    <input
                      id="email"
                      type="text"
                      placeholder="emailjohn@mail.com"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errors.email && 'input-error'
                        }`}

                      value={formData.email}
                      onChange={handleChange}
                      onBlur={handleEmailBlur(formData.email)}
                    />
                    {errors.email && <div className="error-message text-danger">{errors.email}</div>}
                  </div>
                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Kata Sandi</div>
                    <input
                      id="password"
                      type="password"
                      placeholder="******"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errors.password && 'input-error'
                        }`}
                      value={formData.password}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                    {errors.password && <div className="error-message text-danger">{errors.password}</div>}
                  </div>
                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Konfirmasi Kata Sandi</div>
                    <input
                      id="password2"
                      type="password"
                      placeholder="******"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errors.password2 && 'input-error'
                        }`}
                      value={formData.password2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.password2 && <div className="error-message text-danger">{errors.password2}</div>}
                  </div>
                </div>

                <div className="igahp-form-btn-register">
                  <div className="btn-login" onClick={handleSubmit}>
                    <div className="igahp-m-semi-bold white-text">Daftar</div>
                  </div>
                  <div className="divider-register">
                    <div className="divider-regis" />
                    <div className="already-registered-text igahp-xs-regular neutral-60-text">Atau sudah punya akun?</div>
                    <div className="divider-regis" />
                  </div>
                  <div className="btn-registration">
                    <div className="igahp-m-semi-bold green-main-text" onClick={handleLogin}>Masuk Akun</div>
                  </div>
                </div>

              </>

            }

            {currentTab === 2 &&

              <>


                <div className="igahp-form-register">


                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Nama Lengkap</div>
                    <input
                      id="namaLengkap"
                      type="text"
                      placeholder="John Doe"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsInstansi.namaLengkap && 'input-error'
                        }`}
                      value={formDataInstansi.namaLengkap}
                      onChange={handleChange}
                    />
                    {errorsInstansi.namaLengkap && <div className="error-message text-danger">{errorsInstansi.namaLengkap}</div>}
                  </div>

                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">No. Handphone</div>
                    <input
                      id="handphone"
                      type="text"
                      placeholder="081100010002"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsInstansi.handphone && 'input-error'
                        }`}
                      value={formDataInstansi.handphone}
                      onChange={handleChange}
                      onBlur={handleHandphoneBlur(formDataInstansi.handphone)}
                      maxLength={15} // Batasi maksimum karakter input menjadi 15 
                    />
                    {errorsInstansi.handphone && <div className="error-message text-danger">{errorsInstansi.handphone}</div>}
                  </div>


                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Nama Instansi</div>
                    <select id="optionselectinstansi" className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsPerorangan.pekerjaan && 'input-error'
                      }`} value={selectedInstansiOption} onChange={handleChange}>
                      <option value="">Pilih instansi</option>
                      {optionsInstansi.map(option2 => (
                        <option key={option2.id} value={option2.id}>
                          {option2.namaInstansi}
                        </option>
                      ))}
                    </select>
                    {errorsInstansi.selectedInstansiOption && <div className="error-message text-danger">
                      {errorsInstansi.selectedInstansiOption}</div>}
                  </div>


                  {/* 
                  <div className="igahp-form-item">
                    <div className="igahp-m-regular neutral-90-text">Nama Instansi</div>
                    <input
                      id="namaBadan"
                      type="text"
                      placeholder="Kementerian/Lembaga"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsInstansi.namaBadan && 'input-error'
                        }`}
                      value={formDataInstansi.namaBadan}
                      onChange={handleChange}
                    />
                    {errorsInstansi.namaBadan && <div className="error-message text-danger">{errorsInstansi.namaBadan}</div>}
                  </div> */}


                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Email</div>
                    <input
                      id="email"
                      type="text"
                      placeholder="emailjohn@mail.com"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsInstansi.email && 'input-error'
                        }`}

                      value={formDataInstansi.email}
                      onChange={handleChange}
                      onBlur={handleEmailBlur(formDataInstansi.email)}

                    />
                    {errorsInstansi.email && <div className="error-message text-danger">{errorsInstansi.email}</div>}
                  </div>


                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Kata Sandi</div>
                    <input
                      id="password"
                      type="password"
                      placeholder="******"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsInstansi.password && 'input-error'
                        }`}
                      value={formDataInstansi.password}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                    {errorsInstansi.password && <div className="error-message text-danger">{errorsInstansi.password}</div>}
                  </div>
                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Konfirmasi Kata Sandi</div>
                    <input
                      id="password2"
                      type="password"
                      placeholder="******"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsInstansi.password2 && 'input-error'
                        }`}
                      value={formDataInstansi.password2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errorsInstansi.password2 && <div className="error-message text-danger">{errorsInstansi.password2}</div>}
                  </div>
                </div>

                {/* <div className="igahp-form-item">
                  <div className="igahp-m-regular neutral-90-text">Pilih Provinsi</div>
                  <select id="optionselectprovinsi" className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsPerorangan.pekerjaan && 'input-error'
                    }`} value={selectedProvinsiOption} onChange={handleChange}>
                    <option value="">Pilih</option>
                    {optionsProvinsi.map(option3 => (
                      <option key={option3.id} value={option3.kode}>
                        {option3.nama_wilayah}
                      </option>
                    ))}
                  </select>
                  {errorsInstansi.selectedProvinsiOption && <div className="error-message text-danger">
                    {errorsInstansi.selectedProvinsiOption}
                  </div>
                  }
                </div> */}


                <div className="igahp-form-btn-register">
                  <div className="btn-login" onClick={handleSubmit}>
                    <div className="igahp-m-semi-bold white-text">Daftar</div>
                  </div>
                  <div className="divider-register">
                    <div className="divider" />
                    <div className="already-registered-text igahp-xs-regular neutral-60-text">Atau sudah punya akun?</div>
                    <div className="divider" />
                  </div>
                  <div className="btn-registration">
                    <div className="igahp-m-semi-bold green-main-text" onClick={handleLogin}>Masuk Akun</div>
                  </div>
                </div>



              </>

            }

            {currentTab === 3 &&

              <>


                <div className="igahp-form-register">


                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Nama Lengkap</div>
                    <input
                      id="namaLengkap"
                      type="text"
                      placeholder="John Doe"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsBank.namaLengkap && 'input-error'
                        }`}
                      value={formDataBank.namaLengkap}
                      onChange={handleChange}
                    />
                    {errorsBank.namaLengkap && <div className="error-message text-danger">{errorsBank.namaLengkap}</div>}
                  </div>

                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">No. Handphone</div>
                    <input
                      id="handphone"
                      type="text"
                      placeholder="081100010002"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsBank.handphone && 'input-error'
                        }`}
                      value={formDataBank.handphone}
                      onChange={handleChange}
                      onBlur={handleHandphoneBlur(formDataBank.handphone)}
                    />
                    {errorsBank.handphone && <div className="error-message text-danger">{errorsBank.handphone}</div>}
                  </div>



                  {/* <div className="igahp-form-item">
                    <div className="igahp-m-regular neutral-90-text">Nama Badan Usaha</div>
                    <input
                      id="namaBadan"
                      type="text"
                      placeholder="Badan Usaha/Bank"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsBank.namaBadan && 'input-error'
                        }`}

                      value={formDataBank.namaBadan}
                      onChange={handleChange}
                    />
                    {errorsBank.namaBadan && <div className="error-message text-danger">{errorsBank.namaBadan}</div>}
                  </div> */}


                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Nama Bank/Badan Usaha</div>
                    <select id="optionselectbank" className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsPerorangan.pekerjaan && 'input-error'
                      }`} value={selectedBankOption} onChange={handleChange}>
                      <option value="">Pilih Bank/Badan Usaha</option>
                      {optionsBank.map(option4 => (
                        <option key={option4.id} value={option4.id}>
                          {option4.namaBank}
                        </option>
                      ))}
                    </select>
                    {errorsBank.selectedBankOption && <div className="error-message text-danger">
                      {errorsBank.selectedBankOption}</div>}
                  </div>




                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Email</div>
                    <input
                      id="email"
                      type="text"
                      placeholder="emailjohn@mail.com"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsBank.email && 'input-error'
                        }`}

                      value={formDataBank.email}
                      onChange={handleChange}
                      onBlur={handleEmailBlur(formDataBank.email)}
                    />
                    {errorsBank.email && <div className="error-message text-danger">{errorsBank.email}</div>}
                  </div>
                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Kata Sandi</div>
                    <input
                      id="password"
                      type="password"
                      placeholder="******"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsBank.password && 'input-error'
                        }`}
                      value={formDataBank.password}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                    {errorsBank.password && <div className="error-message text-danger">{errorsBank.password}</div>}
                  </div>
                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Konfirmasi Kata Sandi</div>
                    <input
                      id="password2"
                      type="password"
                      placeholder="******"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsBank.password2 && 'input-error'
                        }`}
                      value={formDataBank.password2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errorsBank.password2 && <div className="error-message text-danger">{errorsBank.password2}</div>}
                  </div>
                </div>
                <div className="igahp-form-btn-register">
                  <div className="btn-login" onClick={handleSubmit}>
                    <div className="igahp-m-semi-bold white-text">Daftar</div>
                  </div>
                  <div className="divider-register">
                    <div className="divider" />
                    <div className="already-registered-text igahp-xs-regular neutral-60-text">Atau sudah punya akun?</div>
                    <div className="divider" />
                  </div>
                  <div className="btn-registration">
                    <div className="igahp-m-semi-bold green-main-text" onClick={handleLogin}>Masuk Akun</div>
                  </div>
                </div>

              </>

            }

            {currentTab === 4 &&
              <>

                <div className="igahp-form-register">


                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Nama Lengkap</div>
                    <input
                      id="namaLengkap"
                      type="text"
                      placeholder="John Doe"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsPerorangan.namaLengkap && 'input-error'
                        }`}
                      value={formDataPerorangan.namaLengkap}
                      onChange={handleChange}
                    />
                    {errorsPerorangan.namaLengkap && <div className="error-message text-danger">{errorsPerorangan.namaLengkap}</div>}
                  </div>

                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">No. Handphone</div>
                    <input
                      id="handphone"
                      ref={ignoreScroll}
                      type="text"
                      placeholder="081100010002"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsPerorangan.handphone && 'input-error'
                        }`}
                      value={formDataPerorangan.handphone}
                      onChange={handleChange}
                      onBlur={handleHandphoneBlur(formDataPerorangan.handphone)}
                    />
                    {errorsPerorangan.handphone && <div className="error-message text-danger">{errorsPerorangan.handphone}</div>}
                  </div>



                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Pekerjaan</div>
                    <select id="optionselect" className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsPerorangan.pekerjaan && 'input-error'
                      }`} value={selectedPekerjaanOption} onChange={handleChange}>
                      <option value="">Pilih Pekerjaan</option>
                      {options.map(option => (
                        <option key={option.id} value={option.id}>
                          {option.namaPekerjaan}
                        </option>
                      ))}
                    </select>
                    {errorsPerorangan.pekerjaan && <div className="error-message text-danger">{errorsPerorangan.pekerjaan}</div>}
                  </div>



                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Email</div>
                    <input
                      id="email"
                      type="text"
                      placeholder="emailjohn@mail.com"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsPerorangan.email && 'input-error'
                        }`}

                      value={formDataPerorangan.email}
                      onChange={handleChange}
                      onBlur={handleEmailBlur(formDataPerorangan.email)}
                    />
                    {errorsPerorangan.email && <div className="error-message text-danger">{errorsPerorangan.email}</div>}
                  </div>
                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Kata Sandi</div>
                    <input
                      id="password"
                      type="password"
                      placeholder="******"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsPerorangan.password && 'input-error'
                        }`}
                      value={formDataPerorangan.password}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                    {errorsPerorangan.password && <div className="error-message text-danger">{errorsPerorangan.password}</div>}
                  </div>
                  <div className="igahp-form-item">
                    <div className="igahp-s-medium neutral-90-text">Konfirmasi Kata Sandi</div>
                    <input
                      id="password2"
                      type="password"
                      placeholder="******"
                      className={`form-border-rad-12 igahp-m-regular neutral-90-text ${errorsPerorangan.password2 && 'input-error'
                        }`}
                      value={formDataPerorangan.password2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errorsPerorangan.password2 && <div className="error-message text-danger">{errorsPerorangan.password2}</div>}
                  </div>
                </div>
                <div className="igahp-form-btn-register">
                  <div className="btn-login" onClick={handleSubmit}>
                    <div className="igahp-m-semi-bold white-text">Daftar</div>
                  </div>
                  <div className="divider-register">
                    <div className="divider" />
                    <div className="already-registered-text igahp-xs-regular neutral-60-text">Atau sudah punya akun?</div>
                    <div className="divider" />
                  </div>
                  <div className="btn-registration">
                    <div className="igahp-m-semi-bold green-main-text" onClick={handleLogin}>Masuk Akun</div>
                  </div>
                </div>


              </>
            }

            {currentTab !== 1 && currentTab !== 2 && currentTab !== 3 && currentTab !== 4 && null}
          </>



        </div>
      </section>




      <ToastContainer />





    </>
  )
}

export default RegisterNew
