import React, { useState } from "react";
import {
  BrowserRouter,
  Router,
  Routes,
  Route,
  Link,
  Outlet,
} from "react-router-dom";

// import Badge from "../additional/Bagde";
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";

import Navbar from "./Navbar";
// import $ from "jquery";
import { useEffect } from "react";
// import ContentAsosiasi from "../master/ContentAsosiasi";
import ChangePasswordModal from "../shared/ChangePasswordModal"

const SideBarAdmin = (props) => {
  const { user, setUser } = useContext(AuthContext);


  const [showModalPassword, setshowModalPassword] = useState(false);

  // Fungsi untuk menampilkan modal
  const handleshowModalPassword = () => setshowModalPassword(true);

  // Fungsi untuk menutup modal
  const handleCloseModalPassword = () => setshowModalPassword(false);



  // useEffect(() => {
  //   console.log('sidebar admin component loaded...'); 
  // } );


  useEffect(() => {
    // const script = document.createElement("script");
    // script.src = "/js/highlight.js";
    // script.async = true;
    // document.body.appendChild(script);

    return () => {
      // script.remove();
    };
  }, []);

  return (
    <>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-light-success elevation-4">
        <Link to="/PengembangAdminDashboard" className="brand-link">
          <img src="/dist/img/lentera_logo.svg" alt="Logo" className="brand-image" />
          <h4><span className="brand-text text-muted ml-2"></span><b>LENTERA</b></h4>
        </Link>
  
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img src="/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
            </div>
            <div className="info">
              ADMIN Enumerator
              <a href="#" className="d-block">{user.username}</a>
            </div>
          </div>
  
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
  
              <li className="nav-item">
                <Link to="adminsurveyor/adminSurveyorDashboard" className="nav-link">
                  <i className="bi bi-speedometer2 nav-icon"></i>
                  <p>Dashboard</p>
                </Link>
              </li>
              
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="bi bi-check-square nav-icon"></i>
                  <p>
                    Approval <i className="bi bi-chevron-down right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="adminsurveyor/approvalpendataan" className="nav-link">
                      <i className="bi bi-box-arrow-in-down nav-icon text-info"></i>
                      <p>Pendataan</p>
                    </Link>
                  </li>
                </ul>
              </li>
  
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="bi bi-clipboard-check nav-icon"></i>
                  <p>
                    Penugasan <i className="bi bi-chevron-down right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="adminsurveyor/penugasanpendataan" className="nav-link">
                      <i className="bi bi-folder-plus nav-icon text-blue"></i>
                      <p>Pendataan</p>
                    </Link>
                  </li>
                </ul>
              </li>
  
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="bi bi-gear nav-icon"></i>
                  <p>
                    Utility <i className="bi bi-chevron-down right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="#" className="nav-link" onClick={handleshowModalPassword}>
                      <i className="bi bi-key nav-icon text-blue"></i>
                      <p>Rubah Password</p>
                    </a>
                  </li>
                </ul>
              </li>
  
              <ChangePasswordModal
                show={showModalPassword}
                handleClose={handleCloseModalPassword}
              />
              
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
  
};

export default SideBarAdmin;
