import React, { createContext, useContext, useState, useEffect } from 'react';
import en from '../locales/en.json';
import id from '../locales/id.json';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('id'); // Default bahasa
    const [translations, setTranslations] = useState(id); // Default terjemahan

    // Fungsi untuk mengganti bahasa
    const changeLanguage = (lang) => {
        if (lang !== 'en' && lang !== 'id') return; // Validasi bahasa
        setLanguage(lang); // Ubah bahasa
        setTranslations(lang === 'en' ? en : id); // Ubah terjemahan
        localStorage.setItem('selectedLanguage', lang); // Simpan bahasa ke localStorage
    };

    // Load bahasa dari localStorage saat aplikasi dimulai
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage') || 'id'; // Default ke 'id'
        setLanguage(savedLanguage);
        setTranslations(savedLanguage === 'en' ? en : id);
    }, []);

    return (
        <LanguageContext.Provider value={{ language, translations, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);
