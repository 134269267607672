import React from 'react'
import { useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';



const FooterQuestion = () => {
  const { translations } = useLanguage(); // Mengambil terjemahan dari konteks bahasa
  const myLandingTranslations = translations?.footerquestion || {};

  // const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');



  // const [index, setIndex] = useState(0);
  // const handleSelect = (selectedIndex, e) => {
  //   setIndex(selectedIndex);
  // };


  const handleButtonClick = async () => {
    try {
      // Assuming you have an API endpoint and the appropriate method (POST, PUT, etc.)
      // const response = await fetch('http://localhost:8080/api/public/kirimEmailPertanyaan', {
      const response = await fetch('https://onegreen-be-cms.indonesiahijau.id/api/public/kirimEmailPertanyaan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from: email,
          isi: question,
          subject: 'contact us'
        }),
      });

      // Handle the response accordingly (e.g., show success message, error handling)
      if (response.ok) {
        console.log('Request successful');
        alert('Pesan telah terkirim, harap tunggu balasan dari admin');
        // You can add further actions here upon successful API request
      } else {
        console.error('Error in API request');
        alert('Error in API request');
        // Handle error cases here
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error');
      // Handle other errors such as network issues
    } finally {

    }
  };
  return (
    <>

      <section className="wrapper-contact-us">
        <div className="wrapper-contact-us-content">
          <div className="wrapper-contact-us-left">
            <div className="igahp-contribute">
              <img src="/logo_igahp_lama_white.png" alt="logo igahp" />
            </div>
          </div>
          <div className="wrapper-contact-us-right">

            <p className="igahp-h3-medium white-text" id="contact-us-title">
              {myLandingTranslations.contactUsTitle || "Hubungi kami untuk mengetahui IGAHP"}
            </p>
            <div className="wrapper-contact-us-form-email">
              <img src="/ic_message_contact_us.svg" alt="" id="ic-message-contact-us" />
              <input
                type="text"
                placeholder={myLandingTranslations.emailPlaceholder || "Masukkan email Anda"}
                className="form-rad-12 igahp-m-medium"
                id="form-contact-us-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>


            <textarea
              placeholder={myLandingTranslations.questionPlaceholder || "Masukkan pertanyaan"}
              className="form-rad-12 igahp-m-medium"
              id="form-contact-us-question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            <button className="btn-green-solid-pressed" id="btn-send-contact-us" type="button" onClick={handleButtonClick} disabled>
              <img src="/ic_send.svg" alt="Send" />
              <div className="igahp-m-semi-bold white-text">
                {myLandingTranslations.sendQuestion || "Kirim Pertanyaan"}
              </div>
            </button>

          </div>
        </div>
        {/* <img src="bg_top_right_contact_us.svg" id="bg-top-right-contact-us" /> */}
        {/* <img src="/ic_green_leaf_testimoni.svg" id="ic-bottom-left-contact-us" width="200" /> */}
      </section>

    </>
  )
};

export default FooterQuestion
