import React from 'react'
import { AuthContext } from '../../AuthContext'
import { useContext } from 'react'
import DashboardPenerimaManfaat from './DashboardPenerimaManfaat'

const ContentDepanPenerimaManfaat = () => {


const { user, setUser } = useContext(AuthContext);

return (
    <>
{/* 
        {(user.pengembang_id == null) ? (
            <>
                <PengembangProfileFirstPage />
            </>
        ): (
            <>
                <PengembangAdminDashboard />
            </>
        )} */}
        
        <DashboardPenerimaManfaat />
    </>
  )
}

export default ContentDepanPenerimaManfaat
