import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { OnegreenProvider } from './OnegreenContext';
import { LanguageProvider } from './context/LanguageContext'; // Tambahkan LanguageProvider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <OnegreenProvider>
  //     <LanguageProvider>
  //       <App />
  //     </LanguageProvider>
  //   </OnegreenProvider>
  // </React.StrictMode>



    <OnegreenProvider>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </OnegreenProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
