import React from 'react'

import {
    BrowserRouter,
    Router,
    Routes,
    Route,
    Link,
    Outlet,
} from "react-router-dom";

import { useContext } from "react";

import { AuthContext } from '../../AuthContext';

const NavbarPenerimaManfaat = () => {

    const { user, setUser } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    
    return (
    <div>
      

            {/* Navbar */}
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                    </li>
                </ul>
                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">
                    {/* Messages Dropdown Menu */}
                    <li className="nav-item d-none d-sm-inline-block">
                        <a href="#" className="nav-link text-muted">{user.pengembang_nama}</a>
                        
                    </li>
                    {/* Notifications Dropdown Menu */}
                    {/* <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
                            <i className="far fa-bell" />
                            <span className="badge badge-warning navbar-badge">15</span>
                        </a>
                        
                    </li> */}
                    {/* <li className="nav-item"><a className="nav-link" href="https://indonesiahijau.id/login"><i className="nav-icon  	fas fa-power-off" /></a></li> */}
                 
                    <li className="nav-item">
                        <Link to="/logout" className="nav-link">
                            <i className="nav-icon  	fas fa-power-off"></i>

                        </Link>
                    </li>
                    
                    <li className="nav-item">
                        <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                            <i className="fas fa-expand-arrows-alt" />
                        </a>
                    </li>
                </ul>
            </nav>
            {/* /.navbar */}


    </div>
  )
}

export default NavbarPenerimaManfaat
