import React, { useEffect, useRef } from 'react';
import NavBarAtas from './NavBarAtas';
import FooterFront from './FooterFront';
import Lottie from 'lottie-web';

const ComingSoon = () => {
    require("./../../css/common.css");

    const clockAnimationRef = useRef(null); // Gunakan satu referensi saja untuk animasi

    useEffect(() => {
        const animationInstance = Lottie.loadAnimation({
            animationData: require("./animation/clock_animation_green_pressed.json"),
            autoplay: true,
            container: clockAnimationRef.current, // Pastikan hanya satu elemen yang terhubung
            loop: true,
            renderer: "svg",
        });

        // Bersihkan animasi saat komponen unmount untuk mencegah kebocoran memori
        return () => {
            animationInstance.destroy();
        };
    }, []);

    return (
        <>
            <div>
                <NavBarAtas showButton={true} />
                <section className="wrapper-coming-soon">
                    <div className="wrapper-coming-soon-text">
                        {/* Hanya satu elemen yang terhubung dengan animasi */}
                        <div ref={clockAnimationRef} className="clock-animation" />
                        <div className="one-green-m-regular neutral-90-text" id="coming-soon-1">
                            Nyari rumah dari hulu ke hilir, jangan sampai kena PHP
                        </div>
                        <div className="one-green-m-regular neutral-90-text">
                            <b>Kami akan segera hadir, pantengin terus IGAHP</b>
                        </div>
                    </div>
                </section>
                <FooterFront />
            </div>
        </>
    );
};

export default ComingSoon;
