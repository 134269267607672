import React from 'react'

const FooterFront = () => {
  return (
    <>

          <footer className="copy-right-footer">
              <div className="wrapper-footer">
                  {/* <img src="ic_logo_one_green.svg" alt="One Green" /> */}
                  <p className="one-green-s-regular black-text">Copyright 2024. All Rights Reserved.</p>
              </div>

          </footer>
      
    </>
  )
}

export default FooterFront
