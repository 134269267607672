import React from 'react'
import Footer from '../Footer'
import { useEffect } from 'react';
import CustomIframe from '../CustomIFrame';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import { Chart } from 'chart.js/auto';
import DataTable from 'react-data-table-component';
import { Modal, Table, Button } from 'react-bootstrap';


const SurveyorDashboard = () => {

    const [barData, setBarData] = useState(null);
    const [pieData, setPieData] = useState(null);
    const pieChartRef = useRef(null);
    const barChartRef = useRef(null);

    const [judulModal, setJudulModal] = useState(null);
    const [clickedData, setClickedData] = useState(null);
    const [showTable, setShowTable] = useState(false);
    const tableRefMinat = useRef(null); // Ref to the table section

    const pieChartInstance = useRef(null);
    const barChartInstance = useRef(null);

    let refreshToken = null;
    const { user, setUser } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [lokasiGps, setLokasiGps] = useState({});

    const [dataSurvei, setDataSurvei] = useState(0);
    const [menungguApproval, setMenungguApproval] = useState(0);
    const [statusRevisi, setStatusRevisi] = useState(0);
    const [totalKabKota, setTotalKabKota] = useState(0);
    const [totalKabKecamatan, setTotalKecamatan] = useState(0);

    const [statusApproval, setStatusApproval] = useState(0);
    const [minatSewaCount, setMinatSewaCount] = useState(0);

    const navigate = useNavigate();

    const [showTableKonfirmasiMinat, setShowTableKonfirmasiMinat] = useState(false);
    const [dataKonfirmasiMinat, setDataKonfirmasiMinat] = useState(null);
    const tableRefKonfirmasiMinat = useRef(null); // Ref to the table section

    const columns = [
        {
            name: 'No.',
            selector: (row, index) => (page - 1) * perPage + index + 1,
            sortable: true,
            width: '80px',
        },
        {
            name: 'Provinsi',
            selector: row => row.provinsi,
            sortable: true,
        },
        {
            name: 'kab/kota',
            selector: row => row.kabkota,
            sortable: true,
        },
        {
            name: 'kecamatan',
            selector: row => row.kecamatan,
            sortable: true,
        },
        {
            name: 'kelurahan',
            selector: row => row.kelurahan,
            sortable: true,
        },

        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Penghasilan',
            selector: row => row.penghasilan
                ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Number(row.penghasilan))
                : 'Rp 0',
            sortable: true,
            right: true, // This will align the text to the right
        },
        {
            name: 'Photo',
            selector: row => (
                <i
                    className="bi bi-camera"
                    onClick={() => handlePhotoClick(row.photodiri, row.photorumah, row.photolahan, row.koordinatphotodiri, row.koordinatphotorumah, row.koordinatphotolahan, row.nama, row.programPerumahan)} // pass the photo URL or data
                    style={{ cursor: 'pointer' }}
                ></i>
            ),
            width: '80px',
        }
    ];


    useEffect(() => {


        // const script = document.createElement('script');
        // script.src = "/js/pemdadashboard.js"
        // script.async = true;
        // document.body.appendChild(script);


        fetchDataSurvei();
        fetchDataMenungguApproval();
        fetchDataStatusRevisi();
        fetchSurveiKabKota();
        fetchSurveiKecamatan();

        fetchTotalApproved();
        // fetchMinatSewa();

        fetchJenisKelaminData();
        fetchMinatProgramData();

        setLokasiGps({ lat: -6.266007, lng: 106.747293 });


        // return () => { script.remove(); }

    }, []);



    useEffect(() => {
        if (barData) {
            console.log(barData);
            if (barChartInstance.current) {
                barChartInstance.current.destroy();  // Destroy the previous chart instance
            }

            const barData2 = {
                labels: ['Label 1', 'Label 2', 'Label 3'],
                backgroundColor: ['#ff6384', '#36a2eb', '#ffce56'],
                data: [10, 20, 30]
            };

            const barCtx = barChartRef.current.getContext('2d');
            barChartInstance.current = new Chart(barCtx, {
                type: 'bar',
                data: {
                    labels: barData.labels,
                    datasets: [{
                        backgroundColor: barData.backgroundColor,
                        data: barData.data
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    onClick: async (event, elements) => { // Make the function async

                        if (elements.length > 0) {
                            const chartElement = elements[0];
                            const label = barData.labels[chartElement.index];
                            setJudulModal(label);

                            try {
                                const response = await axios.get(apiUrl + '/api/igahp/dashboard/get-chart-data-minat-program-username/' + label, {
                                    headers: {
                                        Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                                    }
                                });

                                setClickedData(response.data.hasil); // Update state with fetched data
                                setShowTable(true); // Show the table
                                if (tableRefMinat.current) {
                                    tableRefMinat.current.scrollIntoView({ behavior: 'smooth' });
                                }

                            } catch (error) {
                                console.error("Error fetching data:", error);
                            }
                        }
                    },

                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                generateLabels: function (chart) {
                                    // Kustomisasi label di sini jika perlu
                                }
                            }
                        }
                    }
                }
            });
        }

        if (pieData) {
            if (pieChartInstance.current) {
                pieChartInstance.current.destroy();  // Destroy the previous chart instance
            }
            const pieCtx = pieChartRef.current.getContext('2d');
            pieChartInstance.current = new Chart(pieCtx, {
                type: 'pie',
                data: {
                    labels: pieData.labels,
                    datasets: [{
                        backgroundColor: pieData.backgroundColor,
                        data: pieData.data
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            });
        }
    }, [barData, pieData]);

    const handleMapContainerReady = () => {
        // setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };

    const fetchTotalApproved = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalDataSurveiStatusApproval', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setStatusApproval(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchMinatSewa = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/count-minatrumah-sewa-username', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setMinatSewaCount(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchDataSurvei = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalDataSurvei', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataSurvei(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchDataMenungguApproval = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalDataSurveiMenungguApproval', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setMenungguApproval(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchDataStatusRevisi = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalDataSurveiStatusRevisi', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setStatusRevisi(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchSurveiKabKota = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalDataSurveiKabKota', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setTotalKabKota(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchSurveiKecamatan = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalDataSurveiKecamatan', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setTotalKecamatan(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchJenisKelaminData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveiJenisKelamin', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setPieData(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchMinatProgramData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveiDataByProgram', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setBarData(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const columnsKonfirmasiMinat = [
        {
            name: 'No.',
            selector: (row, index) => (page - 1) * perPage + index + 1,
            sortable: true,
            width: '80px',
        },

        {
            name: 'kab/kota',
            selector: row => row.kabkota,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Kecamatan',
            selector: row => row.kecamatan,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Nik',
            selector: row => row.nik.slice(0, 12) + row.nik.slice(12).replace(/\d/g, '*'),
            sortable: true,
            width: '135px',
        }
        ,

        {
            name: 'Alamat',
            selector: row => row.alamat,
            sortable: true,

        },

        {
            name: 'Penghasilan',
            selector: row => row.penghasilan
                ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Number(row.penghasilan))
                : 'Rp 0',
            sortable: true,
            right: true, // This will align the text to the right
        },
        {
            name: 'Photo',
            selector: row => (
                <i
                    className="bi bi-camera"
                    onClick={() => handlePhotoClick(row.photodiri, row.photorumah, row.photolahan, row.koordinatphotodiri, row.koordinatphotorumah, row.selectedKoordinatPhotoLahan, row.nama, row.programPerumahan)} // pass the photo URL or data
                    style={{ cursor: 'pointer' }}
                ></i>
            ),
            width: '80px',
        },
        {
            name: 'Minat',
            selector: row => row.programPerumahan,
            sortable: true,

        },

    ];


    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const handlePhotoClick = (photodiri, photorumah, photolahan, koordinatphotodiri, koordinatphotorumah, koordinatphotoLahan, nama, programPerumahan) => {
        setSelectedPhotoDiri(photodiri);
        setSelectedPhotoRumah(photorumah);
        setSelectedPhotoLahan(photolahan);
        setSelectedKoordinatPhotoDiri(koordinatphotodiri);
        setSelectedKoordinatPhotoRumah(koordinatphotorumah);
        setSelectedKoordinatPhotoLahan(koordinatphotoLahan);
        // setSelectedProgramPerumahan(programPerumahan);
        if (programPerumahan) {
            setJudulModal(programPerumahan);
        }

        // alert(programPerumahan)
        setSelectedNama(nama);
        setShowModalPhoto(true);
    };
    const [showModalPhoto, setShowModalPhoto] = useState(false);
    const [selectedPhotoDiri, setSelectedPhotoDiri] = useState(null);
    const [selectedPhotoRumah, setSelectedPhotoRumah] = useState(null);
    const [selectedPhotoLahan, setSelectedPhotoLahan] = useState(null);
    const [selectedNama, setSelectedNama] = useState(null);

    const [selectedKoordinatPhotoDiri, setSelectedKoordinatPhotoDiri] = useState(null);
    const [selectedKoordinatPhotoRumah, setSelectedKoordinatPhotoRumah] = useState(null);
    const [selectedKoordinatPhotoLahan, setSelectedKoordinatPhotoLahan] = useState(null);
    const [selectedProgramPerumahan, setSelectedProgramPerumahan] = useState(null);

    const [rotateDiri, setRotateDiri] = useState(0);
    const [rotateRumah, setRotateRumah] = useState(0);
    const handleRotateDiri = () => setRotateDiri((prev) => prev + 90);
    const handleRotateRumah = () => setRotateRumah((prev) => prev + 90);

    const handleClosePhoto = () => {
        setShowModalPhoto(false);
        setSelectedPhotoDiri(null);
        setSelectedPhotoRumah(null);
    };




    return (
        <>


            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Dashboard Enumerator</h1>
                            </div>{/* /.col */}

                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}

                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{dataSurvei.toLocaleString('id-ID')}</h3>
                                        <p>Total Data Survei</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-bag" />
                                    </div> */}
                                    <a href="/#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-4 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{totalKabKota.toLocaleString('id-ID')}</h3>
                                        <p>Jumlah Kab/Kota</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div> */}
                                    <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{totalKabKecamatan.toLocaleString('id-ID')}</h3>
                                        <p>Jumlah Kecamatan</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div> */}
                                    <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>

                            {/* ./col */}
                        </div>


                        <div className="row">
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{menungguApproval.toLocaleString('id-ID')}</h3>
                                        <p>Data Menunggu Approval</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-bag" />
                                    </div> */}
                                    <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        {/* <h3>53<sup style={{ fontSize: 20 }}>%</sup></h3> */}
                                        <h3>{statusRevisi.toLocaleString('id-ID')}</h3>
                                        <p>Data Status Revisi</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div> */}
                                    <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{statusApproval}</h3>
                                        <p>Data Approval</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-person-add" />
                                    </div> */}
                                    <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{statusApproval}</h3>
                                        <p>Total Data Klaim</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-pie-graph" />
                                    </div> */}
                                    <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-success">
                                    <div className="card-header">
                                        <h3 className="card-title">Status Data Survei</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <div style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} >
                                            <canvas ref={pieChartRef}></canvas>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="card card-success">
                                    <div className="card-header">
                                        <h3 className="card-title">Data Minat Program Pembiayaan</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <div style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} >
                                            <canvas ref={barChartRef}></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Tampilkan Tabel minat program */}
                        <div className="row" ref={tableRefMinat}>
                            <div className="col-md-12">
                                {showTable && clickedData && (
                                    <DataTable
                                        title={judulModal}
                                        columns={columns}
                                        data={clickedData}
                                        onChangePage={page => setPage(page)}
                                        onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                        pagination
                                    />
                                )}
                            </div>
                        </div>

                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}


            <Modal show={showModalPhoto} onHide={handleClosePhoto} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Minat Program : "{judulModal}" atas nama "{selectedNama}" </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {(selectedPhotoDiri || selectedPhotoRumah || selectedKoordinatPhotoDiri || selectedKoordinatPhotoRumah || selectedPhotoLahan || selectedKoordinatPhotoLahan) ? (
                        <div className="row">
                            <div className="col-md-6">
                                <h6>Photo Diri</h6>
                                {selectedPhotoDiri ? (
                                    <div style={{ position: 'relative' }}>
                                        <img
                                            src={`${apiUrl}/api/public/displayimage/${selectedPhotoDiri}`}
                                            alt="Photo Diri"
                                            style={{
                                                width: '100%',
                                                objectFit: 'cover',
                                                transform: `rotate(${rotateDiri}deg)`
                                            }}
                                        />
                                        <button
                                            onClick={handleRotateDiri}
                                            className="btn btn-outline-primary mt-2 d-flex align-items-center"
                                        >
                                            <i className="bi bi-arrow-clockwise me-2"></i> Rotate Photo Diri
                                        </button>
                                    </div>
                                ) : (
                                    <p>No photo of yourself available</p>
                                )}
                                <p
                                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    onClick={() => {
                                        const coords = selectedKoordinatPhotoDiri.split(', ');
                                        const longitude = coords[0];
                                        const latitude = coords[1];
                                        const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
                                        window.open(googleMapsUrl, '_blank');
                                    }}
                                >
                                    {selectedKoordinatPhotoDiri || 'No coordinates available'}
                                </p>
                            </div>
                            <div className="col-md-6">
                                <h6>{judulModal === "Pembangunan Rumah" ? "Photo Lahan" : "Photo Rumah"}</h6>
                                {judulModal === "Pembangunan Rumah" ? (
                                    selectedPhotoLahan ? (
                                        <div style={{ position: 'relative' }}>
                                            <img
                                                src={`${apiUrl}/api/public/displayimage/${selectedPhotoLahan}`}
                                                alt="Photo Lahan"
                                                style={{
                                                    width: '100%',
                                                    objectFit: 'cover',
                                                    transform: `rotate(${rotateRumah}deg)`
                                                }}
                                            />
                                            <button
                                                onClick={handleRotateRumah}
                                                className="btn btn-outline-primary mt-2 d-flex align-items-center"
                                            >
                                                <i className="bi bi-arrow-clockwise me-2"></i> Rotate Photo Lahan
                                            </button>
                                        </div>
                                    ) : (
                                        <p>No photo of the land available</p>
                                    )
                                ) : (
                                    selectedPhotoRumah ? (
                                        <div style={{ position: 'relative' }}>
                                            <img
                                                src={`${apiUrl}/api/public/displayimage/${selectedPhotoRumah}`}
                                                alt="Photo Rumah"
                                                style={{
                                                    width: '100%',
                                                    objectFit: 'cover',
                                                    transform: `rotate(${rotateRumah}deg)`
                                                }}
                                            />
                                            <button
                                                onClick={handleRotateRumah}
                                                className="btn btn-outline-primary mt-2 d-flex align-items-center"
                                            >
                                                <i className="bi bi-arrow-clockwise me-2"></i> Rotate Photo Rumah
                                            </button>
                                        </div>
                                    ) : (
                                        <p>No photo of the house available</p>
                                    )
                                )}
                                <p
                                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    onClick={() => {
                                        const coords = (judulModal === "Pembangunan Rumah" ? selectedKoordinatPhotoLahan : selectedKoordinatPhotoRumah).split(', ');
                                        const longitude = coords[0];
                                        const latitude = coords[1];
                                        const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
                                        window.open(googleMapsUrl, '_blank');
                                    }}
                                >
                                    {judulModal === "Pembangunan Rumah" ? (selectedKoordinatPhotoLahan || 'No coordinates available') : (selectedKoordinatPhotoRumah || 'No coordinates available')}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <p>No photo available</p>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePhoto}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default SurveyorDashboard
