import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../../AuthContext';
import Swal from 'sweetalert2';

const ModalUploadCSV = ({ onClose, editingData }) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
  });

  const { user } = useContext(AuthContext);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    const sUrl = `${apiUrl}/api/igahp/quesioner/uploadcsv`;

    setIsLoading(true); // Start loader
    setMessage(""); // Clear previous message

    try {
      const response = await axios.post(sUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      setMessage(response.data.message || "File uploaded successfully!");
    } catch (error) {
      setMessage(
        "Failed to upload file: " + (error.response?.data?.message || error.message)
      );
    } finally {
      setIsLoading(false); // Stop loader
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div
      className="modal fade"
      id="ModalUploadCSV"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="ModalUploadCSVLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalUploadCSVLabel">Upload CSV File</h5>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 text-center">
                  <h4 className="mb-3">Select and Upload Your CSV File</h4>
                  <input
                    type="file"
                    className="form-control mb-3"
                    onChange={handleFileChange}
                  />
                  <button
                    onClick={handleUpload}
                    className="btn btn-primary w-100"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Uploading...
                      </>
                    ) : (
                      "Upload"
                    )}
                  </button>
                  {message && (
                    <div
                      className={`alert mt-3 ${
                        message.toLowerCase().includes("success")
                          ? "alert-success"
                          : "alert-danger"
                      }`}
                      role="alert"
                    >
                      {message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUploadCSV;
