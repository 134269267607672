import React from 'react'
import Footer from '../Footer'
import { useEffect } from 'react';
import CustomIframe from '../CustomIFrame';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import { Chart } from 'chart.js/auto';
import { Modal, Table, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import RotatableImage from '../shared/RotatableImage'

import $ from 'jquery'; // Import jQuery if necessary
import ModalPedesaanDetail from './Modal/ModalPedesaanDetail';
import ModalPerkotaanDetail from './Modal/ModalPerkotaanDetail';
import ModalPemprovTerkoneksi from './Modal/ModalPemprovTerkoneksi';
import ModalPempkotTerkoneksi from './Modal/ModalPemkotTerkoneksi';
import ModalKecamatanTerkoneksi from './Modal/ModalKecamatanTerkoneksi';
import ModalKelurahanTerkoneksi from './Modal/ModalKelurahanTerkoneksi';

// import 'datatables.net-dt/css/jquery.dataTables.css'; // Import DataTables CSS if required

const PimpinanDashboard = () => {

    const [rotateDiri, setRotateDiri] = useState(0);
    const [rotateRumah, setRotateRumah] = useState(0);

    const handleRotateDiri = () => setRotateDiri((prev) => prev + 90);
    const handleRotateRumah = () => setRotateRumah((prev) => prev + 90);

    //------------------------------------
    const [barData, setBarData] = useState(null);
    const [pieData, setPieData] = useState(null);
    const pieChartRef = useRef(null);
    const barChartRef = useRef(null);
    const [judulModal, setJudulModal] = useState(null);
    const pieChartInstance = useRef(null);
    const barChartInstance = useRef(null);

    const [viewOnly, setViewOnly] = useState(false);

    const [clickedData, setClickedData] = useState(null);
    const [showTable, setShowTable] = useState(false);

    const [showTableLolosSC, setShowTableLolosSC] = useState(false);
    const [dataLolosSc, setDataLolosSc] = useState(null);

    const [showTableTidakLolosSC, setShowTableTidakLolosSC] = useState(false);
    const [dataTidakLolosSc, setDataTidakLolosSc] = useState(null);

    const [showTableDataMasuk, setShowTableDataMasuk] = useState(false);
    const [dataMasukDetail, setDataMasukDetail] = useState(null);

    const [showTableKonfirmasiMinat, setShowTableKonfirmasiMinat] = useState(false);
    const [dataKonfirmasiMinat, setDataKonfirmasiMinat] = useState(null);

    //----------------------- MODAL ------------------------------------------------------

    const tableRefLolosSC = useRef(null); // Ref to the table section
    const tableRefTidakLolosSC = useRef(null); // Ref to the table section
    const tableRefMinat = useRef(null); // Ref to the table section
    const tableRefDataMasuk = useRef(null); // Ref to the table section
    const tableRefKonfirmasiMinat = useRef(null); // Ref to the table section

    const [isModalOpenDesaLTE, setModalOpenDesaLTE] = useState(false);

    const [isModalOpenKotaLTE, setModalOpenKotaLTE] = useState(false);

    const [isModalOpenPemprovTerkoneksi, setModalOpenPemprovTerkoneksi] = useState(false);
    const [isModalOpenPemkotTerkoneksi, setModalOpenPemkotTerkoneksi] = useState(false);
    const [isModalOpenKecamatanTerkoneksi, setModalOpenKecamatanTerkoneksi] = useState(false);
    const [isModalOpenKelurahanTerkoneksi, setModalOpenKelurahanTerkoneksi] = useState(false);


    const [showModalPerkotaan, setShowModalPerkotaan] = useState(false);
    const handleClosePerkotaan = () => setShowModalPerkotaan(false);

    const [showModalPedesaan, setShowModalPedesaan] = useState(false);
    const handleClosePedesaan = () => setShowModalPedesaan(false);


    const [showModalPenghasilan, setShowModalPenghasilan] = useState(false);
    const [clickedSegmentDataPenghasilan, setClickedSegmentDataPenghasilan] = useState(null);
    const handleClosePenghasilan = () => setShowModalPenghasilan(false);

    const [showModalMinat, setShowModalMinat] = useState(false);
    const [clickedSegmentDataMinat, setClickedSegmentDataMinat] = useState([]);
    const handleCloseMinat = () => setShowModalMinat(false);
    //---------------------------- END OF MODAL ---------------------------------


    let refreshToken = null;
    const { user, setUser } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [lokasiGps, setLokasiGps] = useState({});

    const [dataAwalCount, setDataAwalCount] = useState(0);
    const [dataAwalKabKotaCount, setDataAwalKabKotaCount] = useState(0);
    const [dataAwalKecCount, setDataAwalKecCount] = useState(0);
    const [lolosSubsidiCount, setLolosSubsidiCount] = useState(0);
    const [terkoneksiPemKab, setTerkoneksiPemKab] = useState(0);
    const [tidakLolosSubsidiCount, setTidakLolosSubsidiCount] = useState(0);
    const [dataKelurahan, setDataKelurahan] = useState(0);

    const [dataPerkotaan, setDataPerkotaan] = useState(0);
    const [dataPerkotaan2, setDataPerkotaan2] = useState(0);
    const [dataPedesaan, setDataPedesaan] = useState(0);
    const [dataPedesaan2, setDataPedesaan2] = useState(0);
    const [dataUpdateLapangan, setDataUpdateLapangan] = useState(0);
    const [dataMasuk, setDataMasuk] = useState(0);

    const [minatMilikCount, setMinatMilikCount] = useState(0);
    const [minatSewaCount, setMinatSewaCount] = useState(0);
    const [modalDataMinat, setModalDataMinat] = useState();

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [isLoading, setIsLoading] = useState(false); // Loading state

    const [isLoadingLolos, setIsLoadingLolos] = useState(false); // Loading state
    const [isLoadingTidakLolos, setIsLoadingTidakLolos] = useState(false); // Loading state
    const [isLoadingDataMasuk, setIsLoadingDataMasuk] = useState(false); // Loading state
    const [isLoadingKonfirmasiMinat, setIsLoadingKonfirmasiMinat] = useState(false); // Loading state


    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    const [showModalPhoto, setShowModalPhoto] = useState(false);
    const [selectedPhotoDiri, setSelectedPhotoDiri] = useState(null);
    const [selectedPhotoRumah, setSelectedPhotoRumah] = useState(null);
    const [selectedPhotoLahan, setSelectedPhotoLahan] = useState(null);
    const [selectedNama, setSelectedNama] = useState(null);

    const [selectedKoordinatPhotoDiri, setSelectedKoordinatPhotoDiri] = useState(null);
    const [selectedKoordinatPhotoRumah, setSelectedKoordinatPhotoRumah] = useState(null);
    const [selectedKoordinatPhotoLahan, setSelectedKoordinatPhotoLahan] = useState(null);
    const [selectedProgramPerumahan, setSelectedProgramPerumahan] = useState(null);


    const handleModalOpenDesaLTE = () => {
        console.log('Modal Open')
        setModalOpenDesaLTE(true);
    };

    const handleModalOpenPemprovTerkoneksi = () => {
        // console.log('Modal Open')        
        setModalOpenPemprovTerkoneksi(true);
    };

    const handleModalOpenPemkotTerkoneksi = () => {
        // console.log('Modal Open')        
        setModalOpenPemkotTerkoneksi(true);
    };

    const handleModalOpenKelurahanTerkoneksi = () => {
        // console.log('Modal Open')        
        setModalOpenKelurahanTerkoneksi(true);
    };

    const handleModalOpenKecamatanTerkoneksi = () => {
        // console.log('Modal Open')        
        setModalOpenKecamatanTerkoneksi(true);
    };

    const handleModalOpenKotaLTE = () => {
        console.log('Modal Open')
        setModalOpenKotaLTE(true);
    };

    const handleShowTableDataMasuk = async () => {
        // console.log('Modal Open')
        // alert("aaaaa");
        setShowTableDataMasuk(true);
        setIsLoadingDataMasuk(true); // Set loading state to true

        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/getPimpinanDataMasukDetail', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            setDataMasukDetail(response.data); // Update state with fetched data


            if (tableRefDataMasuk.current) {
                tableRefDataMasuk.current.scrollIntoView({ behavior: 'smooth' });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoadingDataMasuk(false);
        }

    };


    const handleShowTableLolosSC = async () => {
        // console.log('Modal Open')
        // alert("aaaaa");
        setShowTableLolosSC(true);
        setIsLoadingLolos(true); // Set loading state to true

        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/getPimpinanLolosScDetail', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            setDataLolosSc(response.data); // Update state with fetched data


            if (tableRefLolosSC.current) {
                tableRefLolosSC.current.scrollIntoView({ behavior: 'smooth' });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoadingLolos(false);
        }

    };

    const handleShowTableTidakLolosSC = async () => {
        // console.log('Modal Open')
        // alert("aaaaa");
        setShowTableTidakLolosSC(true);
        setIsLoadingTidakLolos(true); // Set loading state to true

        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/getPimpinanTidakLolosScDetail', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            setDataTidakLolosSc(response.data); // Update state with fetched data


            if (tableRefTidakLolosSC.current) {
                tableRefTidakLolosSC.current.scrollIntoView({ behavior: 'smooth' });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoadingTidakLolos(false);
        }

    };

    const handleShowTableKonfirmasiMinat = async () => {
        // console.log('Modal Open')
        // alert("aaaaa");
        setShowTableKonfirmasiMinat(true);
        setIsLoadingKonfirmasiMinat(true); // Set loading state to true

        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/getPimpinanKonfirmasiMinatDetail', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            setDataKonfirmasiMinat(response.data); // Update state with fetched data


            if (tableRefKonfirmasiMinat.current) {
                tableRefKonfirmasiMinat.current.scrollIntoView({ behavior: 'smooth' });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoadingKonfirmasiMinat(false);
        }

    };

    const handleModalCloseDesaLTE = () => {
        console.log('Modal Close')
        setModalOpenDesaLTE(false);
    };
    const handleModalCloseKotaLTE = () => {
        console.log('Modal Close')
        setModalOpenKotaLTE(false);
    };

    const handleModalClosePemkotTerkoneksi = () => {
        console.log('Modal Close')
        setModalOpenPemkotTerkoneksi(false);
    };

    const handleModalClosePemprovTerkoneksi = () => {
        console.log('Modal Close')
        setModalOpenPemprovTerkoneksi(false);
    };

    const handleModalCloseKecamatanTerkoneksi = () => {
        console.log('Modal Close')
        setModalOpenKecamatanTerkoneksi(false);
    };

    const handleModalCloseKelurahanTerkoneksi = () => {
        console.log('Modal Close')
        setModalOpenKelurahanTerkoneksi(false);
    };


    const handleShowModalPerkotaan = () => {
        setShowModalPerkotaan(true);
    };

    const handleCloseModalPerkotaan = () => {
        setShowModalPerkotaan(false);
    };

    const handleShowModalPedesaan = () => {
        setShowModalPedesaan(true);
    };

    const handleCloseModalPedesaan = () => {
        setShowModalPedesaan(false);
    };

    const modalDataPenghasilan = {
        'Desil-1': [
            { name: '', age: 30, gender: '' },
            { name: '', age: 25, gender: '' },
        ],
        Wanita: [
            { name: 'Jane Doe', age: 28, gender: 'Female' },
            { name: 'Emily Davis', age: 22, gender: 'Female' },
        ]
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        const fetchData = () => {
            fetchPemprovTerkoneksi();
            fetchPemKabTerkoneksi();
            fetchDataAwalKabKota();
            fetchDataAwalKec();
            fetchDataKelurahan();
            fetchDataMasuk();
            fetchLolosSubsidi();
            fetchTidakLolosSubsidi();
            fetchDataUpdateLapangan();
            fetchDataPedesan();
            fetchDataPerkotaan();
            fetchDataPerkotaanBaru();
            fetchDataPedesaanBaru();
            fetchDataPenghasilan();
            fetchMinatProgramData();

            setLokasiGps({ lat: -6.266007, lng: 106.747293 });
        };

        // Initial fetch
        fetchData();

        // Set interval to run every 30 seconds
        const interval = setInterval(fetchData, 30000);

        // Cleanup: remove script and clear interval
        return () => {
            script.remove();
            clearInterval(interval);
        };
    }, []);




    const fetchDataPerkotaanBaru = async () => {
        // setIsLoading(true);

        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/getPimpinanKotaList', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            setDataPerkotaan2(response.data); // Update state with fetched data

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            // setIsLoading(false);
        }


    };

    const fetchDataPedesaanBaru = async () => {
        // setIsLoading(true);

        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/getPimpinanDesaList', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            setDataPedesaan2(response.data); // Update state with fetched data

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            // setIsLoading(false);
        }


    };


    useEffect(() => {
        if (barData) {
            if (barChartInstance.current) {
                barChartInstance.current.destroy();  // Destroy the previous chart instance
            }

            const barCtx = barChartRef.current.getContext('2d');
            barChartInstance.current = new Chart(barCtx, {
                type: 'bar',
                data: {
                    labels: barData.labels,
                    datasets: [{
                        backgroundColor: barData.backgroundColor,
                        data: barData.data
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    onClick: async (event, elements) => { // Make the function async
                        if (elements.length > 0) {
                            const chartElement = elements[0];
                            const label = barData.labels[chartElement.index];
                            setJudulModal(label);

                            try {
                                const response = await axios.get(apiUrl + '/api/igahp/dashboard/minat/data2/' + label, {
                                    headers: {
                                        Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                                    }
                                });

                                setClickedData(response.data.hasil); // Update state with fetched data
                                setShowTable(true); // Show the table
                                if (tableRefMinat.current) {
                                    tableRefMinat.current.scrollIntoView({ behavior: 'smooth' });
                                }

                            } catch (error) {
                                console.error("Error fetching data:", error);
                            }
                        }
                    },

                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                generateLabels: function (chart) {
                                    // Kustomisasi label di sini jika perlu
                                }
                            }
                        }
                    }
                }
            });
        }

        if (pieData) {
            if (pieChartInstance.current) {
                pieChartInstance.current.destroy();  // Hapus instance chart sebelumnya
            }
            const pieCtx = pieChartRef.current.getContext('2d');
            pieChartInstance.current = new Chart(pieCtx, {
                type: 'pie',
                data: {
                    labels: pieData.labels,
                    datasets: [{
                        backgroundColor: pieData.backgroundColor,
                        data: pieData.data
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true, // Set to false if you want to hide the legend
                            position: 'bottom', // Position options: 'top', 'left', 'bottom', 'right'
                            labels: {
                                color: '#333', // Legend text color
                                font: {
                                    size: 14 // Font size for the legend text
                                }
                            }
                        }
                    },
                    // onClick: (event, elements) => {
                    //     if (elements.length > 0) {
                    //         const chartElement = elements[0];
                    //         const label = pieData.labels[chartElement.index];
                    //         setJudulModal(label);
                    //         const segmentDataPenghasilan = modalDataPenghasilan[label]; // Data spesifik untuk segmen yang diklik

                    //         setClickedSegmentDataPenghasilan(segmentDataPenghasilan);
                    //         setShowModalPenghasilan(true); // Tampilkan modal saat segmen pie diklik
                    //     }
                    // }
                }
            });
        }



    }, [barData, pieData]);

    const handleMapContainerReady = () => {
        // setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };

    const fetchDataUpdateLapangan = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/countPimpinanUpdateDataLapangan', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataUpdateLapangan(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchMinatSewa = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/count-minatrumah-sewa-username', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setMinatSewaCount(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchPemprovTerkoneksi = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/pimpinanPemprovTerkoneksi', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataAwalCount(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchPemKabTerkoneksi = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/pimpinanPemKabTerkoneksi', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log(response.data)
            setTerkoneksiPemKab(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchDataAwalKabKota = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/countPimpinanDataAwalKabkota', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            // setDataAwalKabKotaCount(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchDataAwalKec = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/countPimpinanKecamatan', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataAwalKecCount(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchDataMasuk = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/countPimpinanDataMasuk', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataMasuk(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchDataKelurahan = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/countPimpinanKelurahan', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataKelurahan(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchDataPerkotaan = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/countPimpinanKota', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataPerkotaan(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchDataPedesan = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/countPimpinanDesa', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataPedesaan(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchLolosSubsidi = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/count-pimpinan-lolossubchecking', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setLolosSubsidiCount(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchTidakLolosSubsidi = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/countPimpinanTidakLolosSubchecking', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setTidakLolosSubsidiCount(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchDataPenghasilan = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/pimpinanPenghasilanPie', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setPieData(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchMinatProgramData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/pimpinanMinatProgramBar', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setBarData(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    let sequenceNumber = 1;

    const handlePhotoClick = (photodiri, photorumah, photolahan, koordinatphotodiri, koordinatphotorumah, koordinatphotoLahan, nama, programPerumahan) => {
        setSelectedPhotoDiri(photodiri);
        setSelectedPhotoRumah(photorumah);
        setSelectedPhotoLahan(photolahan);
        setSelectedKoordinatPhotoDiri(koordinatphotodiri);
        setSelectedKoordinatPhotoRumah(koordinatphotorumah);
        setSelectedKoordinatPhotoLahan(koordinatphotoLahan);
        // setSelectedProgramPerumahan(programPerumahan);
        if (programPerumahan) {
            setJudulModal(programPerumahan);
        }

        // alert(programPerumahan)
        setSelectedNama(nama);
        setShowModalPhoto(true);
    };

    const columns = [
        {
            name: 'No.',
            selector: (row, index) => (page - 1) * perPage + index + 1,
            sortable: true,
            width: '80px',
        },
        {
            name: 'Provinsi',
            selector: row => row.provinsi,
            sortable: true,
        },
        {
            name: 'kab/kota',
            selector: row => row.kabkota,
            sortable: true,
        },
        {
            name: 'kecamatan',
            selector: row => row.kecamatan,
            sortable: true,
        },
        {
            name: 'kelurahan',
            selector: row => row.kelurahan,
            sortable: true,
        },

        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Penghasilan',
            selector: row => row.penghasilan
                ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Number(row.penghasilan))
                : 'Rp 0',
            sortable: true,
            right: true, // This will align the text to the right
        },
        {
            name: 'Photo',
            selector: row => (
                <i
                    className="bi bi-camera"
                    onClick={() => handlePhotoClick(row.photodiri, row.photorumah, row.photolahan, row.koordinatphotodiri, row.koordinatphotorumah, row.koordinatphotolahan, row.nama, row.programPerumahan)} // pass the photo URL or data
                    style={{ cursor: 'pointer' }}
                ></i>
            ),
            width: '80px',
        }
    ];

    const columnsLolosSC = [
        {
            name: 'No.',
            selector: (row, index) => (page - 1) * perPage + index + 1,
            sortable: true,
            width: '80px',
        },

        {
            name: 'kab/kota',
            selector: row => row.kabkota,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Kecamatan',
            selector: row => row.kecamatan,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Nik',
            selector: row => row.nik.slice(0, 12) + row.nik.slice(12).replace(/\d/g, '*'),
            sortable: true,
            width: '135px',
        }
        ,

        {
            name: 'Alamat',
            selector: row => row.alamat,
            sortable: true,

        },

        {
            name: 'Keterangan',
            selector: row => row.keterangan,
            sortable: true,
            width: '800px',
        },


    ];

    const columnsKonfirmasiMinat = [
        {
            name: 'No.',
            selector: (row, index) => (page - 1) * perPage + index + 1,
            sortable: true,
            width: '80px',
        },

        {
            name: 'kab/kota',
            selector: row => row.kabkota,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Kecamatan',
            selector: row => row.kecamatan,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Nik',
            selector: row => row.nik.slice(0, 12) + row.nik.slice(12).replace(/\d/g, '*'),
            sortable: true,
            width: '135px',
        }
        ,

        {
            name: 'Alamat',
            selector: row => row.alamat,
            sortable: true,

        },

        {
            name: 'Penghasilan',
            selector: row => row.penghasilan
                ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Number(row.penghasilan))
                : 'Rp 0',
            sortable: true,
            right: true, // This will align the text to the right
        },
        {
            name: 'Photo',
            selector: row => (
                <i
                    className="bi bi-camera"
                    onClick={() => handlePhotoClick(row.photodiri, row.photorumah, row.photolahan, row.koordinatphotodiri, row.koordinatphotorumah, row.selectedKoordinatPhotoLahan, row.nama, row.programPerumahan)} // pass the photo URL or data
                    style={{ cursor: 'pointer' }}
                ></i>
            ),
            width: '80px',
        },
        {
            name: 'Minat',
            selector: row => row.programPerumahan,
            sortable: true,

        },

    ];

    const handleClosePhoto = () => {
        setShowModalPhoto(false);
        setSelectedPhotoDiri(null);
        setSelectedPhotoRumah(null);
    };



    return (
        <>


            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Dashboard Pimpinan</h1>
                            </div>{/* /.col */}

                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}

                        <div className="row">

                            <div className="col-lg-3 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">

                                    <div className="inner">
                                        <h3>2</h3>
                                        <p>Pemprov Terkoneksi</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>

                                    <div
                                        // onClick={handleModalOpenDesaLTE}
                                        className="small-box-footer"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {/* Detail <i className="fas fa-arrow-circle-right" /> */}
                                        <button
                                            type="button"
                                            id="modPemprovTerkoneksi"
                                            data-toggle="modal"
                                            data-target="#ModalPemprovTerkoneksi"
                                            className="btn btn-success"
                                            onClick={handleModalOpenPemprovTerkoneksi}
                                            style={{ marginLeft: '10px' }}

                                        >
                                            Detail
                                        </button>
                                    </div>

                                </div>
                            </div>
                            {isModalOpenPemprovTerkoneksi && <ModalPemprovTerkoneksi onClose={handleModalClosePemprovTerkoneksi} />}


                            <div className="col-lg-3 col-md-6">

                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{terkoneksiPemKab.toLocaleString('id-ID')}</h3>
                                        <p>PemKab/Kota Terkoneksi</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>
                                    {/* <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a> */}
                                    <div
                                        // onClick={handleModalOpenDesaLTE}
                                        className="small-box-footer"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {/* Detail <i className="fas fa-arrow-circle-right" /> */}
                                        <button
                                            type="button"
                                            id="modPemkotTerkoneksi"
                                            data-toggle="modal"
                                            data-target="#ModalPemkotTerkoneksi"
                                            className="btn btn-success"
                                            onClick={handleModalOpenPemkotTerkoneksi}
                                            style={{ marginLeft: '10px' }}

                                        >
                                            Detail
                                        </button>
                                    </div>

                                </div>
                            </div>
                            {isModalOpenPemkotTerkoneksi && <ModalPempkotTerkoneksi onClose={handleModalClosePemkotTerkoneksi} />}


                            <div className="col-lg-3 col-md-6">

                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{dataAwalKecCount.toLocaleString('id-ID')}</h3>
                                        <p>Jumlah Kecamatan</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>

                                    <div
                                        // onClick={handleModalOpenDesaLTE}
                                        className="small-box-footer"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {/* Detail <i className="fas fa-arrow-circle-right" /> */}
                                        <button
                                            type="button"
                                            id="modKecamatanTerkoneksi"
                                            data-toggle="modal"
                                            data-target="#ModalKecamatanTerkoneksi"
                                            className="btn btn-success"
                                            onClick={handleModalOpenKecamatanTerkoneksi}
                                            style={{ marginLeft: '10px' }}

                                        >
                                            Detail
                                        </button>
                                    </div>

                                </div>


                            </div>
                            {isModalOpenKecamatanTerkoneksi && <ModalKecamatanTerkoneksi onClose={handleModalCloseKecamatanTerkoneksi} />}


                            <div className="col-lg-3 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{dataKelurahan.toLocaleString('id-ID')}</h3>
                                        <p>Jumlah Kelurahan/Desa</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>
                                    {/* <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a> */}
                                    <div
                                        // onClick={handleModalOpenDesaLTE}
                                        className="small-box-footer"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {/* Detail <i className="fas fa-arrow-circle-right" /> */}
                                        <button
                                            type="button"
                                            id="modKelurahanTerkoneksi"
                                            data-toggle="modal"
                                            data-target="#ModalKelurahanTerkoneksi"
                                            className="btn btn-success"
                                            onClick={handleModalOpenKelurahanTerkoneksi}
                                            style={{ marginLeft: '10px' }}

                                        >
                                            Detail
                                        </button>
                                    </div>

                                </div>

                            </div>
                            {isModalOpenKelurahanTerkoneksi && <ModalKelurahanTerkoneksi onClose={handleModalCloseKelurahanTerkoneksi} />}

                        </div>

                        <div className="row">
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{dataMasuk.toLocaleString('id-ID')}</h3>
                                        <p>Data Masuk</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-pie-graph" />
                                    </div>
                                    {/* <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a> */}

                                    <div

                                        className="small-box-footer"
                                        style={{ cursor: 'pointer' }}
                                    >

                                        <button
                                            type="button"
                                            id="dataMasuk"
                                            className="btn btn-info"
                                            onClick={handleShowTableDataMasuk}
                                            style={{
                                                marginLeft: '10px',
                                                cursor: isLoadingDataMasuk ? 'wait' : 'pointer',
                                            }}
                                            disabled={isLoadingDataMasuk} // Optional: Disable button during loading
                                        >
                                            {isLoadingDataMasuk ? 'Loading...' : 'Detail'}
                                        </button>

                                    </div>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{lolosSubsidiCount.toLocaleString('id-ID')}</h3>
                                        <p>Lolos Subsidi Checking</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-pie-graph" />
                                    </div>

                                    <div

                                        className="small-box-footer"
                                        style={{ cursor: 'pointer' }}
                                    >

                                        <button
                                            type="button"
                                            id="lolosSubCek"
                                            className="btn btn-info"
                                            onClick={handleShowTableLolosSC}
                                            style={{
                                                marginLeft: '10px',
                                                cursor: isLoadingLolos ? 'wait' : 'pointer',
                                            }}
                                            disabled={isLoadingLolos} // Optional: Disable button during loading
                                        >
                                            {isLoadingLolos ? 'Loading...' : 'Detail'}
                                        </button>

                                    </div>

                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{tidakLolosSubsidiCount.toLocaleString('id-ID')}</h3>
                                        <p>Tidak Lolos Subsidi Checking</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add" />
                                    </div>

                                    <div

                                        className="small-box-footer"
                                        style={{ cursor: 'pointer' }}
                                    >

                                        <button
                                            type="button"
                                            id="lolosTidakSubCek"
                                            className="btn btn-info"
                                            onClick={handleShowTableTidakLolosSC}
                                            style={{
                                                marginLeft: '10px',
                                                cursor: isLoadingTidakLolos ? 'wait' : 'pointer',
                                            }}
                                            disabled={isLoadingTidakLolos} // Optional: Disable button during loading
                                        >
                                            {isLoadingTidakLolos ? 'Loading...' : 'Detail'}
                                        </button>

                                    </div>

                                </div>

                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{dataUpdateLapangan.toLocaleString('id-ID')}</h3>
                                        <p>Data Hasil Konfirmasi Minat</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add" />
                                    </div>

                                    <div

                                        className="small-box-footer"
                                        style={{ cursor: 'pointer' }}
                                    >

                                        <button
                                            type="button"
                                            id="konfirmasiMInat"
                                            className="btn btn-info"
                                            onClick={handleShowTableKonfirmasiMinat}
                                            style={{
                                                marginLeft: '10px',
                                                cursor: isLoadingKonfirmasiMinat ? 'wait' : 'pointer',
                                            }}
                                            disabled={isLoadingKonfirmasiMinat} // Optional: Disable button during loading
                                        >
                                            {isLoadingKonfirmasiMinat ? 'Loading...' : 'Detail'}
                                        </button>

                                    </div>


                                </div>
                            </div>
                            {/* ./col */}
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">

                                    <div className="inner">
                                        <h3>{dataPerkotaan.toLocaleString('id-ID')}</h3>
                                        <p>Wilayah Perkotaan</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>
                                    {/* <div onClick={handleShowModalPerkotaan} className="small-box-footer" style={{ cursor: 'pointer' }}>
                                        Detail <i className="fas fa-arrow-circle-right" />
                                    </div> */}

                                    <div
                                        // onClick={handleModalOpenDesaLTE}
                                        className="small-box-footer"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {/* Detail <i className="fas fa-arrow-circle-right" /> */}
                                        <button
                                            type="button"
                                            id="tambahModal"
                                            data-toggle="modal"
                                            data-target="#ModalPerkotaanDetail"
                                            className="btn btn-success"
                                            onClick={handleModalOpenKotaLTE}
                                            style={{ marginLeft: '10px' }}

                                        >
                                            Detail
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {isModalOpenKotaLTE && <ModalPerkotaanDetail onClose={handleModalCloseKotaLTE} />}

                            <div className="col-lg-6 col-md-6">
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{dataPedesaan.toLocaleString('id-ID')}</h3>
                                        <p>Wilayah Pedesaan</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>

                                    <div
                                        // onClick={handleModalOpenDesaLTE}
                                        className="small-box-footer"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {/* Detail <i className="fas fa-arrow-circle-right" /> */}
                                        <button
                                            type="button"
                                            id="tambahModal"
                                            data-toggle="modal"
                                            data-target="#ModalPedesaanDetail"
                                            className="btn btn-success"
                                            onClick={handleModalOpenDesaLTE}
                                            style={{ marginLeft: '10px' }}

                                        >
                                            Detail
                                        </button>
                                    </div>


                                </div>
                            </div>

                            {isModalOpenDesaLTE && <ModalPedesaanDetail onClose={handleModalCloseDesaLTE} />}


                        </div>


                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-info">
                                    <div className="card-header">
                                        <h3 className="card-title">Data Penghasilan</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <div style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} >
                                            <canvas ref={pieChartRef}></canvas>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="card card-info">
                                    <div className="card-header">
                                        <h3 className="card-title">Data Minat Program</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <div style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} >
                                            <canvas ref={barChartRef}></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Tampilkan Tabel minat program */}
                        <div className="row" ref={tableRefMinat}>
                            <div className="col-md-12">
                                {showTable && clickedData && (
                                    <DataTable
                                        title={judulModal}
                                        columns={columns}
                                        data={clickedData}
                                        onChangePage={page => setPage(page)}
                                        onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                        pagination
                                    />
                                )}
                            </div>
                        </div>

                        {/* Tampilkan Tabel Lolos SC  */}
                        <div className="row" ref={tableRefLolosSC}>
                            <div className="col-md-12">
                                {showTableLolosSC && dataLolosSc && (
                                    <DataTable
                                        title='Lolos Subsidi Checking'
                                        columns={columnsLolosSC}
                                        data={dataLolosSc}
                                        onChangePage={page => setPage(page)}
                                        onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                        pagination
                                    />
                                )}
                            </div>
                        </div>

                        {/* Tampilkan Tabel Tidak Lolos SC  */}
                        <div className="row" ref={tableRefTidakLolosSC}>
                            <div className="col-md-12">
                                {showTableTidakLolosSC && dataTidakLolosSc && (
                                    <DataTable
                                        title='Tidak Lolos Subsidi Checking'
                                        columns={columnsLolosSC}
                                        data={dataTidakLolosSc}
                                        onChangePage={page => setPage(page)}
                                        onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                        pagination
                                    />
                                )}
                            </div>
                        </div>

                        {/* Tampilkan Tabel DATA MASUK  */}
                        <div className="row" ref={tableRefDataMasuk}>
                            <div className="col-md-12">
                                {showTableDataMasuk && dataMasukDetail && (
                                    <DataTable
                                        title='Data Masuk'
                                        columns={columnsKonfirmasiMinat}
                                        data={dataMasukDetail}
                                        onChangePage={page => setPage(page)}
                                        onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                        pagination
                                    />
                                )}
                            </div>
                        </div>

                        {/* Tampilkan Tabel Konfirmasi Minat  */}
                        <div className="row" ref={tableRefKonfirmasiMinat}>
                            <div className="col-md-12">
                                {showTableKonfirmasiMinat && dataKonfirmasiMinat && (
                                    <DataTable
                                        title='Konfirmasi Minat'
                                        columns={columnsKonfirmasiMinat}
                                        data={dataKonfirmasiMinat}
                                        onChangePage={page => setPage(page)}
                                        onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                        pagination
                                    />
                                )}
                            </div>
                        </div>


                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Modal show={showModalPerkotaan} onHide={handleClosePerkotaan} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Detail Data Perkotaan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table id="example1" className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>NO</th>
                                <th>KOTA</th>
                                <th>KELURAHAN</th>
                                <th>PEMINAT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(dataPerkotaan2) && dataPerkotaan2.length > 0 ? (
                                dataPerkotaan2.map((item) => (
                                    <tr key={item.nomor}>
                                        <td>{sequenceNumber++}</td>
                                        <td>{item.kabkota}</td>
                                        <td>{item.kelurahan}</td>
                                        <td>{item.peminat}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePerkotaan}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalPedesaan} onHide={handleClosePedesaan} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Detail Data Pedesaan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="content-wrapper">
                        <section className="content">
                            <div className="container-fluid">
                                <table id="example1" className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>NO</th>
                                            <th>KABUPATEN</th>
                                            <th>DESA</th>
                                            <th>PEMINAT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(dataPedesaan2) && dataPedesaan2.length > 0 ? (
                                            dataPedesaan2.map((item) => (
                                                <tr key={item.nomor}>
                                                    <td>{sequenceNumber++}</td>
                                                    <td>{item.kabkota}</td>
                                                    <td>{item.kelurahan}</td>
                                                    <td>{item.peminat}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePedesaan}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalPenghasilan} onHide={handleClosePenghasilan} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Detail Data Penghasilan {judulModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {clickedSegmentDataPenghasilan ? (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Nama</th>
                                    <th>Umur</th>
                                    <th>Jenis Kelamin</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clickedSegmentDataPenghasilan.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.age}</td>
                                        <td>{item.gender}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <p>No data available</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePenghasilan}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalMinat} onHide={handleCloseMinat} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Detail Data Minat {judulModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {clickedSegmentDataMinat && clickedSegmentDataMinat.length > 0 ? (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Provinsi</th>
                                    <th>Kab/Kota</th>
                                    <th>Kecamatan</th>
                                    <th>Kelurahan/Desa</th>
                                    <th>Nik</th>
                                    <th>Nama</th>                    
                                </tr>
                            </thead>
                            <tbody>
                                {clickedSegmentDataMinat.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.provinsi}</td>
                                        <td>{item.kabkota}</td>
                                        <td>{item.kecamatan}</td>
                                        <td>{item.kelurahan}</td>
                                        <td>{item.nik}</td>
                                        <td>{item.nama}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <p>No data available for "{judulModal}"</p>
                    )} */}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseMinat}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalPhoto} onHide={handleClosePhoto} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Minat Program : "{judulModal}" atas nama "{selectedNama}" </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {(selectedPhotoDiri || selectedPhotoRumah || selectedKoordinatPhotoDiri || selectedKoordinatPhotoRumah || selectedPhotoLahan || selectedKoordinatPhotoLahan) ? (
                        <div className="row">
                            <div className="col-md-6">
                                <h6>Photo Diri</h6>
                                {selectedPhotoDiri ? (
                                    <div style={{ position: 'relative' }}>
                                        <img
                                            src={`${apiUrl}/api/public/displayimage/${selectedPhotoDiri}`}
                                            alt="Photo Diri"
                                            style={{
                                                width: '100%',
                                                objectFit: 'cover',
                                                transform: `rotate(${rotateDiri}deg)`
                                            }}
                                        />
                                        <button
                                            onClick={handleRotateDiri}
                                            className="btn btn-outline-primary mt-2 d-flex align-items-center"
                                        >
                                            <i className="bi bi-arrow-clockwise me-2"></i> Rotate Photo Diri
                                        </button>
                                    </div>
                                ) : (
                                    <p>No photo of yourself available</p>
                                )}
                                <p
                                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    onClick={() => {
                                        const coords = selectedKoordinatPhotoDiri.split(', ');
                                        const longitude = coords[0];
                                        const latitude = coords[1];
                                        const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
                                        window.open(googleMapsUrl, '_blank');
                                    }}
                                >
                                    {selectedKoordinatPhotoDiri || 'No coordinates available'}
                                </p>
                            </div>
                            <div className="col-md-6">
                                <h6>{judulModal === "Pembangunan Rumah" ? "Photo Lahan" : "Photo Rumah"}</h6>
                                {judulModal === "Pembangunan Rumah" ? (
                                    selectedPhotoLahan ? (
                                        <div style={{ position: 'relative' }}>
                                            <img
                                                src={`${apiUrl}/api/public/displayimage/${selectedPhotoLahan}`}
                                                alt="Photo Lahan"
                                                style={{
                                                    width: '100%',
                                                    objectFit: 'cover',
                                                    transform: `rotate(${rotateRumah}deg)`
                                                }}
                                            />
                                            <button
                                                onClick={handleRotateRumah}
                                                className="btn btn-outline-primary mt-2 d-flex align-items-center"
                                            >
                                                <i className="bi bi-arrow-clockwise me-2"></i> Rotate Photo Lahan
                                            </button>
                                        </div>
                                    ) : (
                                        <p>No photo of the land available</p>
                                    )
                                ) : (
                                    selectedPhotoRumah ? (
                                        <div style={{ position: 'relative' }}>
                                            <img
                                                src={`${apiUrl}/api/public/displayimage/${selectedPhotoRumah}`}
                                                alt="Photo Rumah"
                                                style={{
                                                    width: '100%',
                                                    objectFit: 'cover',
                                                    transform: `rotate(${rotateRumah}deg)`
                                                }}
                                            />
                                            <button
                                                onClick={handleRotateRumah}
                                                className="btn btn-outline-primary mt-2 d-flex align-items-center"
                                            >
                                                <i className="bi bi-arrow-clockwise me-2"></i> Rotate Photo Rumah
                                            </button>
                                        </div>
                                    ) : (
                                        <p>No photo of the house available</p>
                                    )
                                )}
                                <p
                                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    onClick={() => {
                                        const coords = (judulModal === "Pembangunan Rumah" ? selectedKoordinatPhotoLahan : selectedKoordinatPhotoRumah).split(', ');
                                        const longitude = coords[0];
                                        const latitude = coords[1];
                                        const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
                                        window.open(googleMapsUrl, '_blank');
                                    }}
                                >
                                    {judulModal === "Pembangunan Rumah" ? (selectedKoordinatPhotoLahan || 'No coordinates available') : (selectedKoordinatPhotoRumah || 'No coordinates available')}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <p>No photo available</p>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePhoto}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default PimpinanDashboard
