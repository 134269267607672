import React from 'react'
import NavBarAtas from './NavBarAtas'
import FooterFront from './FooterFront';
import FooterQuestion from './FooterQuestion';
// import { useState } from 'react';


const Panduan = () => {

  require("./../../css/common.css");

  return (

    <>

      <div>

        <NavBarAtas showButton={true} />

        <section className="body-home-content-panduan">
          
        
        <div className="section-headlines-header-text">
          
            <div className="wrapper-label-headlines">
              <div className="igahp-highlight-semi-bold">Panduan</div>
              <div className="igahp-l-regular black-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
            </div>
            <div className="wrapper-house-information">
              <div className="igahp-l-regular black-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
              
            </div>

            
          </div>

          <section className="section-body-maxpage">
            <div className="body-card-panduan">

              <div className="group-card-panduan">
                <div className="card-panduan">
                <div className="label-panduan">
                    <div className="igahp-subheading-semi-bold">
                    Guidelines
                    </div>
                    <button className="btn-icon-only">
                      <img src="/ic_number1.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                <div className="card-panduan">
                <div className="label-panduan">
                    <div className="igahp-subheading-semi-bold">
                    Basic Design
                    </div>
                    <button className="btn-icon-only">
                      <img src="/ic_number2.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                <div className="card-panduan">
                <div className="label-panduan">
                    <div className="igahp-subheading-semi-bold">
                    Toolkit
                    </div>
                    <button className="btn-icon-only">
                      <img src="/ic_number3.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                <div className="card-panduan">
                <div className="label-panduan">
                    <div className="igahp-subheading-semi-bold">
                    Administrasi PGB
                    </div>
                    <button className="btn-icon-only">
                      <img src="/ic_number4.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

              </div>

              {/* <div className="body-card-panduan">

              <div className="group-card">
                <div className="card-product">
                <div className="label-product">
                    <div className="igahp-l-semi-bold">
                    Guidelines
                    </div>
                    <button className="btn-icon-only">
                      <img src="/icon_arrow_right_green.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                

              </div>

              <div className="group-card">
                <div className="card-product">
                <div className="label-product">
                    <div className="igahp-l-semi-bold">
                    Basic Design
                    </div>
                    <button className="btn-icon-only">
                      <img src="/icon_arrow_right_green.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                

              </div>

              <div className="group-card">
                <div className="card-product">
                <div className="label-product">
                    <div className="igahp-l-semi-bold">
                    Toolkit
                    </div>
                    <button className="btn-icon-only">
                      <img src="/icon_arrow_right_green.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                

              </div>

              <div className="group-card">
                <div className="card-product">
                <div className="label-product">
                    <div className="igahp-l-semi-bold">
                    Administrasi PGB
                    </div>
                    <button className="btn-icon-only">
                      <img src="/icon_arrow_right_green.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                

              </div>

            </div> */}

            </div>


          </section>



        
            


         

          <section className="section-body-maxpage">
            <div className="body-card">
              <div className="container-attention">
                <div className="content-attention">
                  <div className="container-text-attention">
                    <div className="label-text-attention">LAPORKAN!!</div>
                    <div className="support-text-attention">
                      <p>Jika anda melihat dan mendengar Pelanggaran/Kecurangan yang dilakukan oleh Pejabat/Pegawai di lingkungan</p>
                      <p>Kementerian Perumahan dan Kawasan Permukiman</p></div>

                  </div>
                </div>
              </div>

            </div>

          </section>

        </section>

       

        <FooterQuestion />
        <FooterFront />
      </div>

    </>



  )
};


export default Panduan
