import React from 'react'

const Footer = () => {
  return (
    <>
      <footer className="main-footer">
        <div className="float-right d-none d-sm-block">
          <b>Version</b> 2.0.0
        </div>
        <strong>2024, made with ❤️ by <a href="https://igahp.id/">IGAHP</a>.</strong> All rights reserved.
      </footer>

     


    </>
  )
}

export default Footer
