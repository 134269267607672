import React, { useState } from "react";
import {
  BrowserRouter,
  Router,
  Routes,
  Route,
  Link,
  Outlet,
} from "react-router-dom";

// import Badge from "../additional/Bagde";
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import ChangePasswordModal from "../shared/ChangePasswordModal"

import Navbar from "./Navbar";
// import $ from "jquery";
import { useEffect } from "react";
// import ContentAsosiasi from "../master/ContentAsosiasi";

const SideBarAdmin = (props) => {
  const { user, setUser } = useContext(AuthContext);

  const [showModalPassword, setshowModalPassword] = useState(false);

  // Fungsi untuk menampilkan modal
  const handleshowModalPassword = () => setshowModalPassword(true);

  // Fungsi untuk menutup modal
  const handleCloseModalPassword = () => setshowModalPassword(false);



  // useEffect(() => {
  //   console.log('sidebar admin component loaded...'); 
  // } );


  useEffect(() => {
    // const script = document.createElement("script");
    // script.src = "/js/highlight.js";
    // script.async = true;
    // document.body.appendChild(script);

    return () => {
      // script.remove();
    };
  }, []);

  
  return (
    <>
      <aside className="main-sidebar sidebar-light-success elevation-4">
        <Link to="/PengembangAdminDashboard" className="brand-link">
          <img src="/dist/img/lentera_logo.svg" alt="Logo" className="brand-image" />
          <h4><span className="brand-text text-muted ml-2"></span><b>LENTERA</b></h4>
        </Link>
  
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img src="/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
            </div>
            <div className="info">
              <a href="#" className="d-block">{user.username}</a>
            </div>
          </div>
  
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
  
              {/* Dashboard */}
              <li className="nav-item">
                <Link to="admin/dashboard" className="nav-link">
                  <i className="nav-icon bi bi-speedometer2"></i>
                  <p>Dashboard</p>
                </Link>
              </li>
  
              {/* Approval Section */}
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon bi bi-check-circle"></i>
                  <p>
                    Approval <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="admin/ManajemenUsers" className="nav-link">
                      <i className="bi bi-people nav-icon text-blue"></i>
                      <p>Pengguna</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="admin/ManajemenLokasi" className="nav-link">
                      <i className="bi bi-geo-alt-fill nav-icon text-danger"></i>
                      <p>Lokasi Perumahan</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="admin/AdminApprovalPendataan" className="nav-link">
                      <i className="bi bi-clipboard-check nav-icon text-info"></i>
                      <p>Pendataan</p>
                    </Link>
                  </li>
                </ul>
              </li>
  
              {/* Data Master Section */}
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon bi bi-database"></i>
                  <p>
                    Data Master <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="admin/ManajemenAsosiasi" className="nav-link">
                      <i className="bi bi-building nav-icon text-blue"></i>
                      <p>Asosiasi</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="admin/ManajemenBanks" className="nav-link">
                      <i className="bi bi-bank nav-icon text-danger"></i>
                      <p>Banks</p>
                    </Link>
                  </li>
                </ul>
              </li>
  
              {/* RumahQu Section */}
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon bi bi-house-door"></i>
                  <p>
                    RumahQu <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="admin/UploadRumahquCsv" className="nav-link">
                      <i className="bi bi-upload nav-icon text-blue"></i>
                      <p>Upload CSV</p>
                    </Link>
                  </li>
                </ul>
              </li>
  
              {/* Penugasan Section */}
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon bi bi-list-task"></i>
                  <p>
                    Penugasan <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="admin/AdminPenugasanPendataan" className="nav-link">
                      <i className="bi bi-clipboard-data nav-icon text-blue"></i>
                      <p>Pendataan</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="admin/ManajemenBanks" className="nav-link">
                      <i className="bi bi-bank nav-icon text-danger"></i>
                      <p>Lain</p>
                    </Link>
                  </li>
                </ul>
              </li>
  
              {/* Utility Section */}
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon bi bi-tools"></i>
                  <p>
                    Utility <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="#" className="nav-link" onClick={handleshowModalPassword}>
                      <i className="bi bi-key nav-icon text-blue"></i>
                      <p>Rubah Password</p>
                    </a>
                  </li>
                </ul>
              </li>
  
              <ChangePasswordModal
                show={showModalPassword}
                handleClose={handleCloseModalPassword}
              />
  
              {/* Peminatan */}
              <li className="nav-item">
                <Link to="pengembang/dataMinat" className="nav-link">
                  <i className="nav-icon bi bi-heart-fill text-warning"></i>
                  <p>Peminatan</p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
  

};

export default SideBarAdmin;
