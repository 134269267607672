import React from 'react'
import Footer from '../Footer'
import { useEffect } from 'react';
import CustomIframe from '../CustomIFrame';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import { Chart } from 'chart.js/auto';
import { Modal, Table, Button } from 'react-bootstrap';

const PemdaDashboard = () => {

    const [barData, setBarData] = useState(null);
    const [pieData, setPieData] = useState(null);
    const pieChartRef = useRef(null);
    const barChartRef = useRef(null);

    const pieChartInstance = useRef(null);
    const barChartInstance = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [clickedSegmentData, setClickedSegmentData] = useState(null); // Holds the data for the clicked segment

    const handleClose = () => setShowModal(false);


    let refreshToken = null;
    const { user, setUser } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [lokasiGps, setLokasiGps] = useState({});

    const [dataAwalCount, setDataAwalCount] = useState(0);
    const [dataAwalKabKotaCount, setDataAwalKabKotaCount] = useState(0);
    const [dataAwalKecCount, setDataAwalKecCount] = useState(0);
    const [lolosSubsidiCount, setLolosSubsidiCount] = useState(0);
    const [tidakLolosSubsidiCount, setTidakLolosSubsidiCount] = useState(0);

    const [minatMilikCount, setMinatMilikCount] = useState(0);
    const [minatSewaCount, setMinatSewaCount] = useState(0);

    const navigate = useNavigate();

    const modalData = {
        Pria: [
            { name: 'John Doe', age: 30, gender: 'Male' },
            { name: 'Michael Smith', age: 25, gender: 'Male' },
        ],
        Wanita: [
            { name: 'Jane Doe', age: 28, gender: 'Female' },
            { name: 'Emily Davis', age: 22, gender: 'Female' },
        ]
    };
    

    useEffect(() => {


        // const script = document.createElement('script');
        // script.src = "/js/pemdadashboard.js"
        // script.async = true;
        // document.body.appendChild(script);


        fetchDataAwal();
        fetchDataAwalKabKota();
        fetchDataAwalKec();
        fetchLolosSubsidi();
        fetchTidakLolosSubsidi();

        fetchMinatMilik();
        fetchMinatSewa();

        fetchJenisKelaminData();
        fetchMinatProgramData();

        setLokasiGps({ lat: -6.266007, lng: 106.747293 });


        // return () => { script.remove(); }

    }, []);



    useEffect(() => {
        if (barData) {
            if (barChartInstance.current) {
                barChartInstance.current.destroy();  // Destroy the previous chart instance
            }

            const barCtx = barChartRef.current.getContext('2d');
            barChartInstance.current = new Chart(barCtx, {
                type: 'bar',
                data: {
                    labels: barData.labels,
                    datasets: [{
                        backgroundColor: barData.backgroundColor,
                        data: barData.data
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                generateLabels: function (chart) {
                                    // Kustomisasi label di sini jika perlu
                                }
                            }
                        }
                    }
                }
            });
        }

        if (pieData) {
            if (pieChartInstance.current) {
                pieChartInstance.current.destroy();  // Destroy the previous chart instance
            }
            const pieCtx = pieChartRef.current.getContext('2d');
            pieChartInstance.current = new Chart(pieCtx, {
                type: 'pie',
                data: {
                    labels: pieData.labels,
                    datasets: [{
                        backgroundColor: pieData.backgroundColor,
                        data: pieData.data
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    // onClick: (event, elements) => {
                    //     if (elements.length > 0) {
                    //         const chartElement = elements[0];
                    //         const label = pieData.labels[chartElement.index];
                    //         const segmentData = modalData[label]; // Fetch data specific to clicked segment (based on gender in this case)

                    //         setClickedSegmentData(segmentData);
                    //         setShowModal(true); // Show modal when a pie segment is clicked
                    //     }
                    // }

                }
            });
        }
    }, [barData, pieData]);

    const handleMapContainerReady = () => {
        // setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };

    const fetchMinatMilik = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/count-minatrumah-milik-username', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setMinatMilikCount(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchMinatSewa = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/count-minatrumah-sewa-username', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setMinatSewaCount(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchDataAwal = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/count-dataawal-username', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataAwalCount(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchDataAwalKabKota = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/count-dataawal-kabkota-username', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataAwalKabKotaCount(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchDataAwalKec = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/count-dataawal-kec-username', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataAwalKecCount(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchLolosSubsidi = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/count-lolossubchecking-username', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setLolosSubsidiCount(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchTidakLolosSubsidi = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/count-tidaklolossubchecking-username', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setTidakLolosSubsidiCount(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchJenisKelaminData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/pie', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setPieData(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchMinatProgramData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/bar', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setBarData(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    return (
        <>


            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Dashboard Monitoring</h1>
                            </div>{/* /.col */}

                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}

                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{dataAwalCount.toLocaleString('id-ID')}</h3>
                                        <p>Belum Subsidi Checking</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>
                                    <a href="/depan/pemda/QuesionerDataAwal" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{lolosSubsidiCount.toLocaleString('id-ID')}</h3>
                                        <p>Lolos Subsidi Checking</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>
                                    <a href="/depan/pemda/LolosSubsidiChecking" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{tidakLolosSubsidiCount.toLocaleString('id-ID')}</h3>
                                        <p>Tidak Lolos Subsidi Checking</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>
                                    <a href="/depan/pemda/TidakLolosSubsidiChecking" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{(tidakLolosSubsidiCount + lolosSubsidiCount + dataAwalCount).toLocaleString('id-ID')}</h3>
                                        <p>Total Data</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>
                                    <a href="/depan/pemda/TidakLolosSubsidiChecking" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{dataAwalKabKotaCount.toLocaleString('id-ID')}</h3>
                                        <p>Jumlah Kabupaten/Kota</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-pie-graph" />
                                    </div>
                                    <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        {/* <h3>53<sup style={{ fontSize: 20 }}>%</sup></h3> */}
                                        <h3>{dataAwalKecCount.toLocaleString('id-ID')}</h3>
                                        <p>Jumlah Kecamatan</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-pie-graph" />
                                    </div>
                                    <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">                                        
                                        <h3>{minatMilikCount.toLocaleString('id-ID')}</h3>
                                        <p>Jenis Minat Milik</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add" />
                                    </div>
                                    <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">                                        
                                        <h3>{minatSewaCount.toLocaleString('id-ID')}</h3>
                                        <p>Jenis Minat Sewa</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add" />
                                    </div>
                                    <a href="#" className="small-box-footer">Detail <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-success">
                                    <div className="card-header">
                                        <h3 className="card-title">Data Jenis Kelamin</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <div style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} >
                                            <canvas ref={pieChartRef}></canvas>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="card card-success">
                                    <div className="card-header">
                                        <h3 className="card-title">Data Minat Program Pembiayaan</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <div style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} >
                                            <canvas ref={barChartRef}></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">

                                <h4>Peta Sebaran Penerima Manfaat</h4>

                                <CustomIframe width="100%" height="500px" frameBorder="0" scrolling="no">

                                    <>
                                        <MapContainer
                                            gps={lokasiGps}
                                            onMapContainerReady={handleMapContainerReady}
                                        />
                                    </>

                                </CustomIframe>


                            </div>
                        </div>


                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            {/* Modal for Data Table */}
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Detail Data Jenis Kelamin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {clickedSegmentData ? (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Nama</th>
                                    <th>Umur</th>
                                    <th>Jenis Kelamin</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clickedSegmentData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.age}</td>
                                        <td>{item.gender}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <p>No data available</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default PemdaDashboard
