// LoginForm.js
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "./AuthContext";
import { LoginToServer } from './components/LoginToServer';
import { Link, useNavigate } from 'react-router-dom';
import NavBarAtas from './components/Front/NavBarAtas';
import axios from 'axios';

import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';

const Login = (props) => {


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
    });


    // const onLogin = props.onLogin;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { loginContext } = useContext(AuthContext);
    const [loginStatus, setLoginStatus] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    // const [forgotPassword, setForgotPassword] = useState(false);

    const [csrfToken, setCsrfToken] = useState('');

    const [usernameError, setUsernameError] = useState('');

    const [passError, setPassError] = useState('');

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    // const [isButtonProcess, setIsButtonProcess] = useState(false);

    useEffect(() => {
        if (props.statusLogout) {
            setLoginStatus('logout')
        }

    }, [props.statusLogout]);

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                // const response = await fetch('http://:8080/api/auth/csrfToken');
                const response = await fetch(apiUrl + '/api/auth/csrfToken');
                if (response.ok) {
                    const data = await response.json();
                    setCsrfToken(data.token);
                    document.cookie = `XSRF-TOKEN=${data.token}`;
                } else {
                    console.log('Failed to fetch CSRF token');
                }
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCsrfToken();
    }, []);

    const navigate = useNavigate()

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        setUsernameError(''); // Reset error when input changes
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPassError('');
    };


    // const handleUsernameBlur = () => {
    //     axios.get(apiUrl + `/api/public/checkEmail/${username}`)
    //         .then(response => {                
    //             if (response.data) {
    //             } else {
    //                 setUsernameError('Username / email tidak ditemukan');
    //                 setIsLoading(false);

    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //         });
    // };

    const handleUsernameBlur = () => {
        if (!validator.isEmail(username)) {
            setUsernameError('Invalid email format');
            setIsLoading(false);
            return;
        }

        axios.get(`${apiUrl}/api/public/checkEmail/${encodeURIComponent(username)}`)
            .then(response => {
                if (!response.data) {
                    setUsernameError('Username / email tidak ditemukan');
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleSubmit = async (e) => {
        // setIsButtonProcess(true);

        // console.log('hit login...')
        setLoginStatus('');

        e.preventDefault();

        if (username && password) {
            setIsLoading(true);
            try {

                await axios.get(apiUrl + `/api/public/checkEmail/${username}`)
                    .then(response => {
                        if (response.data) {
                        } else {
                            // toast.error('Email belum terdaftar');
                            // return;
                            setUsernameError('Username / email tidak ditemukan');
                            setIsLoading(false);
                            return;
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });

                // const timeout = 30000; // 5 seconds
                // const apiPromise = LoginToServer(username, password, csrfToken);
                // const user2 = await Promise.race([
                //     apiPromise,
                //     new Promise((resolve, reject) => setTimeout(reject, timeout))
                // ]);

                const user2 = await LoginToServer(username, password, csrfToken);

                try {

                    if (user2) {

                        if (user2 == 'Bad credentials') {

                            setPassError('Username atau password tidak sesuai');
                            setIsLoading(false);
                            // navigate('/login');
                            setLoginStatus('fail');
                            // setIsButtonProcess(true)

                        } else {

                            const response = await fetch(apiUrl + '/api/public/pengembang/userid/' + user2.id);

                            const jsonData = await response.json();
                            user2['pengembang_id'] = jsonData.pengembang_id;
                            user2['pengembang_nama'] = jsonData.pengembang_nama;
                            user2['pengembang_photo'] = jsonData.pengembang_photo;
                            user2['siteplan'] = null;
                            // user2['perumahan_id'] = '';
                            // user2['perumahan_nama'] = '';

                            loginContext(user2); // isi context

                            if (user2.status === '0') {
                                alert('Status user anda masih non aktif, hubungi admin untuk aktivasi')
                                setIsLoading(false);
                                setLoginStatus('fail');
                                // navigate('/login');
                                return;
                            }
                            if (user2.roles) {
                                // navigate('/igahp');
                                navigate('/depan');
                            } else {
                                setLoginStatus('fail');
                                setIsLoading(false);
                                // setIsButtonProcess(false);
                            }
                        }

                    }
                } catch (error) {
                    console.error('Error Bro:', error);
                    // toast.error('Salah password');

                    setLoginStatus('fail');
                    // setIsButtonProcess(true)
                    // return;
                }


            } catch (error) {
                console.log('API request timed out or encountered an error:', error);
                setLoginStatus('timeout');
                // setIsButtonProcess(false);
            } finally {
                setIsLoading(false);
            }
        } else {
            // setIsButtonProcess(false);
            setLoginStatus('fail');

        }
    };

    require("./css/common.css");


    return (

        <>

            <NavBarAtas showButton={false} />

            <div className="login-container-background">
                {/* Bagian Gambar */}
                <div className="login-banner">
                    <img src="/images/prabowo_program_3juta_rumah_green.png"
                        alt="Login Background" />
                </div>
                <div className="wrapper-login-content">

                    <div className="logo-top">
                        <div className="login-logo-content">
                            <img src="/dist/img/lentera_logo.svg" width={60} height={60} alt="logo lentera hijau" />
                            <div className="logo-title-brand">
                                <div className="igahp-l-semi-bold neutral-90-text">LENTERA HIJAU</div>
                                <div className="igahp-m-regular neutral-80-text">Layanan Terintegrasi Rumah Hijau Terjangkau</div>
                            </div>
                        </div>
                    </div>

                    <div className="content-login-form">

                        <div className="login-title">
                            <div className="igahp-l-semi-bold neutral-90-text">Selamat Datang</div>
                            <div className="igahp-m-reguler neutral-60-text">Masuk untuk akses akun Anda</div>
                        </div>

                        <div className="form-login">
                            <form onSubmit={handleSubmit} className="wrapper-form-login">
                                <div className="igahp-form-item">
                                    <div className="igahp-s-medium neutral-80-text">Email</div>
                                    <input type="text"
                                        placeholder="Masukkan email"
                                        // className="form-border-rad-12 igahp-m-regular neutral-90-text"
                                        className={`form-border-rad-12 igahp-m-regular neutral-90-text ${usernameError && 'error'}`}
                                        id="username"
                                        value={username}
                                        onChange={handleUsernameChange}
                                        onBlur={handleUsernameBlur}
                                        required
                                    />

                                    {usernameError && <div className="error-message text-danger">{usernameError}</div>}


                                </div>

                                <div className="igahp-form-item">
                                    <div className="igahp-s-medium neutral-80-text">Kata Sandi</div>
                                    <input type="password" placeholder="******"
                                        className={`form-border-rad-12 igahp-m-regular neutral-90-text ${passError && 'error'}`}
                                        id="password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        required
                                    />
                                    {passError && <div className="error-message text-danger">{passError}</div>}

                                </div>
                                <div className="wrapper-forgot-password">
                                    <a href="/forgot-password" className="forgot-password igahp-s-regular">Lupa Kata Sandi</a>
                                </div>
                                <button
                                    type="submit"
                                    // disabled={isButtonProcess}
                                    disabled={isLoading}
                                    className="btn-login igahp-s-semibold">Masuk</button>

                                <div className="divider-or">
                                    <div className="divider-regis" />
                                    <div className="or-text igahp-xs-regular">Atau</div>
                                    <div className="divider-regis" />
                                </div>
                                <div className="wrapper-registration">
                                    <a href="/register" className="btn-registration igahp-s-semibold green-main-text">Daftar</a>
                                </div>

                                {isLoading ?
                                    <div className="divider-or">
                                        <div>Loading...</div>
                                    </div>
                                    : <p></p>}

                            </form>
                        </div>
                    </div>


                </div>


            </div>


            <ToastContainer />

        </>


    );
};

export default Login;
