import React, { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { AuthContext } from "../../../AuthContext";
import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import EXIF from 'exif-js';
import exportCSV from 'json-to-csv-export';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import LocationSelect from '../../shared/LocationSelect'
import validator from 'validator';
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom';


const AdminPenugasanPendataan = () => {
    let refreshToken = null;
    const navigate = useNavigate()
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [totalRows, setTotalRows] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedVillage, setSelectedVillage] = useState('');
    const [wilayahPilih, setWilayahPilih] = useState('');
    const { user } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const [selectedRows, setSelectedRows] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    const handleShowModal = () => {
        const selectedData = data.filter(row => selectedRows.includes(row.id));
        setSelectedData(selectedData);
        setShowModal(true);
    };

    const handleRowSelect = (id) => {
        setSelectedRows(prev =>
            prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
        );
    };

    const [surveyors, setSurveyors] = useState([]);
    const [selectedSurveyor, setSelectedSurveyor] = useState('');



    useEffect(() => {
        if (showModal) {


            axios.get(`${apiUrl}/api/igahp/quesioner/getSurveyor`, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,  // Replace `yourToken` with the actual token
                }
            })
                .then((response) => {
                    setSurveyors(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching surveyor data:', error);
                });
        }
    }, [showModal]);


    useEffect(() => {
        if (wilayahPilih) {
            fetchData();
        }
    }, [page, perPage, searchQuery, wilayahPilih]); // Consolidated dependencies



    useEffect(() => {
        if (selectedProvince) {
            setWilayahPilih(selectedProvince)
        }
    }, [selectedProvince]);

    useEffect(() => {
        if (selectedCity) {
            setWilayahPilih(selectedCity)
        }
    }, [selectedCity]);

    useEffect(() => {
        if (selectedDistrict) {
            setWilayahPilih(selectedDistrict)
        }
    }, [selectedDistrict]);

    useEffect(() => {
        if (selectedVillage) {
            setWilayahPilih(selectedVillage)
        }
    }, [selectedVillage]);



    const columns = [
        {
            name: 'Pilih',
            cell: row => (
                <input
                    type="checkbox"
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelect(row.id)}
                />
            ),
            width: '80px',
        },
        {
            name: 'No.',
            selector: (row, index) => (page - 1) * perPage + index + 1,
            sortable: true,
            width: '80px', // Set a reasonable width for small columns
        },
        {
            name: 'Kab/Kota',
            selector: row => row.namaKabkota,
            sortable: true,
            wrap: true, // Wrap text in case it overflows
        },

        {
            name: 'Kecamatan',
            selector: row => row.namaKec,
            sortable: true,
            wrap: true, // Wrap text in case it overflows
        },
        {
                name: 'Nama Lengkap',
            selector: row => row.namaLengkap,
            sortable: true,
            wrap: true, // Wrap text in case it overflows
        },
        {
            name: 'NIK',
            selector: row => {
                const nik = row.nik;
                return nik ? nik.slice(0, 12) + nik.slice(12).replace(/\d/g, '*') : '';
            },
            sortable: true,
            wrap: true,
        },
        {
            name: 'Jenis Kelamin',
            selector: row => row.jenisKelamin == '1' ? 'Pria' : row.jenisKelamin == '2' ? 'Wanita' : 'Unknown',
            sortable: true,
            wrap: true,
        },
        {
            name: 'Alamat',
            selector: row => row.alamatKtp,
            sortable: true,
            wrap: true, // Wrap text in case it overflows
        },


    ];


    const fetchData = async () => {
        setLoading(true);


        try {
            const sanitizedSearchQuery = validator.escape(searchQuery); // Escape query untuk keamanan

            const response = await axios.get(`${apiUrl}/api/igahp/quesioner/allQuesioner`, {
                params: {
                    page: page - 1,
                    size: perPage,
                    wilayah: wilayahPilih, // Kirim wilayah yang sudah digabung
                    search: encodeURIComponent(sanitizedSearchQuery), // Encode search query
                },
                headers: {
                    Authorization: `Bearer ${user.accessToken}`,
                },
            });
            setData(response.data.content);
            setTotalRows(response.data.totalElements);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchAllData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/igahp/quesioner/data-quesioner-page2-all`, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,  // Replace `yourToken` with the actual token
                }
            });
            setLoading(false);
            return response.data;
        } catch (error) {
            console.error('Error fetching all data:', error);
            setLoading(false);
            return [];
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, perPage, searchQuery]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setPage(1);
    };






    const handleExportCSV = async () => {
        const allData = await fetchAllData();
        const headers = ['No.', 'Nama Lengkap', 'NIK', 'Jenis Kelamin', 'Tanggungan Jiwa', 'Handphone'];
        const itemsFormatted = allData.map((item, index) => ({
            'No.': index + 1,
            'Nama Lengkap': item.namaLengkap,
            'NIK': item.nik,
            'Jenis Kelamin': item.jenisKelamin,
            'Tanggungan Jiwa': item.tanggunganJiwa,
            'Handphone': item.handphone,
        }));

        exportCSV({ data: itemsFormatted, filename: 'pendataan_awal_bgh_all', headers });
    };


    const handleSurveyorChange = (event) => {
        setSelectedSurveyor(event.target.value);
    };

    const handleSaveAssignment = async () => {

        // let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        // let currentDate = new Date();
        // // JWT exp is in seconds
        // if (decodedToken.exp * 1000 < currentDate.getTime()) {
        //     console.log("Token expired.");
        //     try {
        //         const paramRefreshToken = {
        //             refreshToken: user.refreshToken,
        //             csrfToken: user.csrfToken,
        //         };
        //         refreshToken = await refreshTokenRequest(paramRefreshToken);
        //         // refreshToken = await refreshTokenRequest();
        //     } catch (error) {
        //         console.error('Expired. Please sign in.');
        //         navigate('/login');
        //     }
        //     if (refreshToken.statusCode === 403) {
        //         console.error('Expired. Please sign in.');
        //         navigate('/login');
        //     }
        //     console.log(refreshToken);
        //     user['accessToken'] = refreshToken.accessToken;
        //     setIsTokenExpired(true);
        // } else {
        //     console.log("Valid token");
        //     // result = true;
        // }


        
        const payload = {
            surveyorId: selectedSurveyor,
            selectedData: selectedData, // This should contain the rows you've selected
        };
    
        try {
            const response = await fetch(`${apiUrl}/api/igahp/quesioner/assignSurveyor`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            if (response.ok) {
                fetchData();
                alert("Assignment saved successfully!");
                setShowModal(false);
            } else {
                console.error("Failed to save assignment");
                alert("Failed to save assignment");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("Error occurred while saving assignment");
        }
    };


    return (
        <>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Monitoring Penugasan</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Penugasan Enumerator</li>
                                </ol>
                            </div>
                        </div>

                        <div className="form-group">
                            <h8>Pilih Wilayah</h8>
                            <div className="row">
                                <LocationSelect
                                    onProvinceChange={setSelectedProvince}
                                    onCityChange={setSelectedCity}
                                    onDistrictChange={setSelectedDistrict}
                                    onVillageChange={setSelectedVillage}
                                />
                            </div>
                        </div>

                        <div className="row mb-2 d-flex justify-content-between">
                            <div className="col-sm-4 d-flex">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="form-control form-control-sm"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />

                            </div>

                        </div>


                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    checked={selectedRows.length === data.length}
                                    onChange={() => {
                                        if (selectedRows.length === data.length) {
                                            setSelectedRows([]);
                                        } else {
                                            setSelectedRows(data.map(row => row.id));
                                        }
                                    }}
                                />
                                <span className="ml-2">Pilih Semua</span>
                            </div>

                            <button
                                className="btn btn-info"
                                onClick={handleShowModal}
                                disabled={selectedRows.length === 0} // Disable if no rows are selected
                            >
                                Penugasan ke Enumerator
                            </button>

                        </div>

                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={page => setPage(page)}
                            onChangeRowsPerPage={perPage => setPerPage(perPage)}
                            responsive
                            dense
                        />
                    </div>
                </section>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Penugasan Ke Petugas Surveyor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <ul>
                        {selectedData.map(item => (
                            <li key={item.id}>
                                {item.namaLengkap} - {item.nik} - {item.jenisKelamin} - {item.tanggalLahir}
                            </li>
                        ))}
                    </ul> */}


                    {/* Combo box to select a surveyor */}
                    <Form.Group controlId="surveyorSelect">
                        <Form.Label>Pilih Enumerator</Form.Label>
                        <Form.Control as="select" value={selectedSurveyor} onChange={handleSurveyorChange}>
                            <option value="">Pilih Enumerator</option>
                            {surveyors.map(surveyor => (
                                <option key={surveyor.id} value={surveyor.id}>
                                    {surveyor.nama} - {surveyor.nik}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group
                    >
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveAssignment} disabled={!selectedSurveyor}>
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    );
};

export default AdminPenugasanPendataan;

