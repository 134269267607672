import React from 'react'
import Footer from '../Footer'
import { useEffect } from 'react';
import CustomIframe from '../CustomIFrame';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import { Chart } from 'chart.js/auto';

const SatkerDashboard = () => {

    const [barData, setBarData] = useState(null);
    const [pieData, setPieData] = useState(null);
    const pieChartRef = useRef(null);
    const barChartRef = useRef(null);

    const pieChartInstance = useRef(null);
    const barChartInstance = useRef(null);

    let refreshToken = null;
    const { user, setUser } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [lokasiGps, setLokasiGps] = useState({});

    const [dataAwalCount, setDataAwalCount] = useState(0);
    const [dataTotalProvinsi, setDataTotalProvinsi] = useState(0);
    const [dataTotalKabKota, setDataTotalKabKota] = useState(0);
    const [menungguApproval, setMenungguApproval] = useState(0);
    const [totalRevisi, setTotalRevisi] = useState(0);
    const [totalApproved, setTotalApproved] = useState(0);
    const [totalSurveyor, setTotalSurveyor] = useState(0);

    const navigate = useNavigate();



    useEffect(() => {


        // const script = document.createElement('script');
        // script.src = "/js/pemdadashboard.js"
        // script.async = true;
        // document.body.appendChild(script);


        fetchTotalDataSiapSurvei();
        fetchSurveiJumlahProvinsi();
        fetchSurveiKabKota();
        fetchMenungguApproval();
        fetchTotalRevisi();        
        fetchTotalApproved();
        fetchTotalSurveyor();

        fetchJenisKelaminData();
        fetchMinatProgramData();

        setLokasiGps({ lat: -6.266007, lng: 106.747293 });


        // return () => { script.remove(); }

    }, []);



    useEffect(() => {
        if (barData) {
            // console.log('Bar Data')
            console.log(barData)

            if (barChartInstance.current) {
                barChartInstance.current.destroy();  // Destroy the previous chart instance
            }

            const barCtx = barChartRef.current.getContext('2d');
            barChartInstance.current = new Chart(barCtx, {
                type: 'bar',
                data: {
                    labels: barData.labels,
                    datasets: [{
                        backgroundColor: barData.backgroundColor,
                        data: barData.data
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                generateLabels: function (chart) {
                                    // Kustomisasi label di sini jika perlu
                                }
                            }
                        }
                    }
                }
            });
        }

        if (pieData) {
            if (pieChartInstance.current) {
                pieChartInstance.current.destroy();  // Destroy the previous chart instance
            }
            const pieCtx = pieChartRef.current.getContext('2d');
            pieChartInstance.current = new Chart(pieCtx, {
                type: 'pie',
                data: {
                    labels: pieData.labels,
                    datasets: [{
                        backgroundColor: pieData.backgroundColor,
                        data: pieData.data
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            });
        }
    }, [barData, pieData]);

    const handleMapContainerReady = () => {
        // setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };

    const fetchTotalApproved = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalApprovedAdmin', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setTotalApproved(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchTotalSurveyor = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalSurveyorAdmin', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setTotalSurveyor(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchTotalDataSiapSurvei = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalDataSurveiAdmin', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataAwalCount(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchSurveiJumlahProvinsi = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalJumlahProvinsiAdmin', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataTotalProvinsi(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchSurveiKabKota = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalJumlahProvinsiAdmin', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            setDataTotalKabKota(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchMenungguApproval = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalMenungguApprovalAdmin', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setMenungguApproval(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchTotalRevisi = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveyor/totalRevisiAdmin', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setTotalRevisi(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const fetchJenisKelaminData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveiJenisKelaminAdmin', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setPieData(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchMinatProgramData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/surveiDataByProgramAdmin', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            setBarData(response.data);

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    return (
        <>


            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Dashboard Satuan Kerja</h1>
                            </div>{/* /.col */}

                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}

                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{dataAwalCount.toLocaleString('id-ID')}</h3>
                                        <p>Total Data Siap Survei</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-bag" />
                                    </div> */}
                                    <a href="/#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-4 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{dataTotalProvinsi.toLocaleString('id-ID')}</h3>
                                        <p>Jumlah Provinsi Survei</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div> */}
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{dataTotalKabKota.toLocaleString('id-ID')}</h3>
                                        <p>Jumlah Kab/Kota Survei</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div> */}
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>

                            {/* ./col */}
                        </div>


                        <div className="row">
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{menungguApproval.toLocaleString('id-ID')}</h3>
                                        <p>Data Menunggu Approval</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-bag" />
                                    </div> */}
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        {/* <h3>53<sup style={{ fontSize: 20 }}>%</sup></h3> */}
                                        <h3>{totalRevisi.toLocaleString('id-ID')}</h3>
                                        <p>Data Status Revisi</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div> */}
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{totalApproved}</h3>
                                        <p>Data Approval</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-person-add" />
                                    </div> */}
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{totalSurveyor}</h3>
                                        <p>Total Petugas Survei</p>
                                    </div>
                                    {/* <div className="icon">
                                        <i className="ion ion-pie-graph" />
                                    </div> */}
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-success">
                                    <div className="card-header">
                                        <h3 className="card-title">Status Data Survei</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <div style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} >
                                            <canvas ref={pieChartRef}></canvas>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="card card-success">
                                    <div className="card-header">
                                        <h3 className="card-title">Data Minat Program Pembiayaan</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <div style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} >
                                            <canvas ref={barChartRef}></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">

                                <h4>Peta Sebaran Rumah Hijau</h4>

                                <CustomIframe width="100%" height="500px" frameBorder="0" scrolling="no">

                                    <>
                                        <MapContainer
                                            gps={lokasiGps}
                                            onMapContainerReady={handleMapContainerReady}
                                        />
                                    </>

                                </CustomIframe>


                            </div>
                        </div>


                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}



        </>
    )
}

export default SatkerDashboard
