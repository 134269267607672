import React from 'react'
import { useState } from 'react';
import NavBarAtas from './NavBarAtas'
import FooterFront from './FooterFront';
import FooterQuestion from './FooterQuestion';
import { useLanguage } from '../../context/LanguageContext';

const Aplikasi = () => {
  const { translations } = useLanguage(); // Mengambil terjemahan dari konteks bahasa
  const myAplikasiTranslations = translations?.myaplikasi || {};
  // const myLandingTranslations = translations?.mylanding || {};



  const [imageSource, setImageSource] = useState("images/aplikasi/image_phone_aplikasi_5.svg");


  const [isActivePenilaian, setIsActivePenilaian] = useState(false);
  const handlePenilaianOtomatisClick = () => {
    if (isActivePenilaian) {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_5.svg";
      setImageSource(newImageSource);
    } else {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_1.svg";
      setImageSource(newImageSource);
    }
    setIsActivePenilaian(!isActivePenilaian);
    setIsActiveUnggah(false);
    setIsActiveTerbit(false)
    setIsActivePenugasan(false);
  };


  const [isActiveUnggah, setIsActiveUnggah] = useState(false);
  const handleUnggahOtomatisClick = () => {
    if (isActiveUnggah) {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_5.svg";
      setImageSource(newImageSource);
    } else {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_2.svg";
      setImageSource(newImageSource);
    }
    setIsActiveUnggah(!isActiveUnggah);
    setIsActivePenilaian(false)
    setIsActiveTerbit(false)
    setIsActivePenugasan(false);
  };


  const [isActiveTerbit, setIsActiveTerbit] = useState(false);
  const handleTerbitOtomatisClick = () => {
    if (isActiveTerbit) {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_5.svg";
      setImageSource(newImageSource);
    } else {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_3.svg";
      setImageSource(newImageSource);
    }
    setIsActiveTerbit(!isActiveTerbit)
    setIsActiveUnggah(false);
    setIsActivePenilaian(false)
    setIsActivePenugasan(false);
  };


  const [isActivePenugasan, setIsActivePenugasan] = useState(false);
  const handlePenugasanOtomatisClick = () => {
    if (isActivePenugasan) {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_5.svg";
      setImageSource(newImageSource);
    } else {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_4.svg";
      setImageSource(newImageSource);
    }
    setIsActivePenugasan(!isActivePenugasan)
    setIsActiveTerbit(false)
    setIsActiveUnggah(false);
    setIsActivePenilaian(false)
  };

  // const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');

  const handleButtonClick = async () => {
    try {
      // Assuming you have an API endpoint and the appropriate method (POST, PUT, etc.)
      // const response = await fetch('http://localhost:8080/api/public/kirimEmailPertanyaan', {
      const response = await fetch('https://onegreen-be-cms.indonesiahijau.id/api/public/kirimEmailPertanyaan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from: email,
          isi: question,
          subject: 'contact us'
        }),
      });

      // Handle the response accordingly (e.g., show success message, error handling)
      if (response.ok) {
        console.log('Request successful');
        alert('Pesan telah terkirim, harap tunggu balasan dari admin');
        // You can add further actions here upon successful API request
      } else {
        console.error('Error in API request');
        alert('Error in API request');
        // Handle error cases here
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error');
      // Handle other errors such as network issues
    } finally {

    }
  };


  require("./../../css/common.css");

  return (

    <>
      <NavBarAtas page="6" showButton={true} />


      <div>
        {/* <section className="wrapper-hero-image-aplikasi">
          <div className="wrapper-hero-text-aplikasi">
            <div className="one-green-h3-semi-bold white-text">Sertifikasi Hijau Dengan Aplikasi OneGreen</div>
            <div className="one-green-m-regular white-text">Lakukan sertifikasi hijau untuk rumah subsidi dan komersil
              dengan aplikasi Lenter Hijau secara mudah, canggih, dan efektif.</div>
          </div>
          <div className="wrapper-background-hero-image-aplikasi">
            <img src="images/aplikasi/background_green_vector_aplikasi.svg" id="background-hero-image-aplikasi" />
            <img src="images/aplikasi/image_hero_aplikasi.png" id="hero-image-aplikasi" />
          </div>
          <img src="images/aplikasi/ic_green_leaf_aplikasi.svg" id="green-leaf-aplikasi" width={120} height={120} />
        </section> */}

        <section className="body-home-content-aplikasi">


          <div className="section-headlines-header-text">

            <div className="wrapper-label-headlines">
              <div className="igahp-highlight-semi-bold">
                {myAplikasiTranslations.headlineTitle ||
                  "Sertifikasi Rumah Hijau Pakai Mobile Lentera Hijau"}
              </div>
              <div className="igahp-l-regular black-text">
                {myAplikasiTranslations.headlineDescription ||
                  "Dengan Mobile Lentera Hijau, memudahkan Anda dalam melakukan proses penilaian Bangunan Rumah Hijau secara mudah, cepat dan efisien."}
              </div>
            </div>

            {/* <div className="wrapper-house-information">
              <div className="igahp-l-regular black-text">Ciptakan hunian berwawasan lingkungan dengan Lentera HIjau Mobile</div>
              <div className="igahp-l-regular black-text"><p>1. Telah terintegrasi langsung dengan SIMBG</p></div>

            </div> */}


          </div>

          <section className="section-body-maxpage">

            <div className="body-card-panduan">

              <div className="keunggulan-aplikasi">

                <div className="tab-image-aplikasi">
                  {/* <img src="images/aplikasi/image_phone_aplikasi_1.svg " id="image-keunggulan-aplikasi" /> */}
                  <img src={imageSource} id="image-keunggulan-aplikasi" alt="" />
                </div>


                <div className="wrapper-deskripsi-keunggulan-aplikasi">
                  <div className="igahp-m-medium green-main-text">
                    {myAplikasiTranslations.keunggulanTitle ||
                      "KEMUDAHAN YANG BISA DILAKUKAN LENTERA HIJAU MOBILE"}
                  </div>

                  <div className="igahp-h3-medium neutral-90-text">
                    {myAplikasiTranslations.seDescription ||
                      "Sesuai dengan Surat Edaran Dirjen Cipta Karya No 03/SE/DC/2023 tentang Petunjuk Teknis Penilaian Kinerja Bangunan Gedung Hijau untuk Klas Bangunan 1a"}
                  </div>

                  <div className="wrapper-list-keunggulan-aplikasi">
                    <div
                      className={`list-keunggulan-aplikasi-item ${isActivePenugasan ? "active" : ""
                        }`}
                      id="penugasan-tenaga-ahli"
                      onClick={handlePenugasanOtomatisClick}
                    >
                      <div className="list-keunggulan-aplikasi-title">
                        {myAplikasiTranslations.penugasanTitle ||
                          "Penugasan untuk Tenaga Ahli BGH"}
                      </div>
                      <div className="list-keunggulan-aplikasi-description">
                        {myAplikasiTranslations.penugasanDescription ||
                          "Tenaga Ahli Sertifikasi Rumah Hijau dapat menerima penugasan dari Pengembang Perumahan yang sedang mengajukan Sertifikasi Rumah Hijau"}
                      </div>
                      <img src={imageSource} id="image-keunggulan-aplikasi-mobile" alt="" />
                      <div className="divider-neutral-30" />
                      <img
                        src="images/aplikasi/ic_chevron_down.svg"
                        id="chevron-aplikasi"
                        width={20}
                        height={20}
                        alt=""
                      />
                    </div>

                    <div
                      className={`list-keunggulan-aplikasi-item ${isActivePenilaian ? "active" : ""
                        }`}
                      id="penilaian-otomatis"
                      onClick={handlePenilaianOtomatisClick}
                    >
                      <div className="list-keunggulan-aplikasi-title">
                        {myAplikasiTranslations.penilaianTitle ||
                          "Penilaian otomatis sertifikasi hijau"}
                      </div>
                      <div className="list-keunggulan-aplikasi-description">
                        {myAplikasiTranslations.penilaianDescription ||
                          "Melakukan perhitungan secara otomatis sehingga memudahkan Tenaga Ahli Bangunan Gedung Hijau."}
                      </div>
                      <img src={imageSource} id="image-keunggulan-aplikasi-mobile" alt="" />
                      <div className="divider-neutral-30" />
                      <img
                        src="images/aplikasi/ic_chevron_down.svg"
                        id="chevron-aplikasi"
                        width={20}
                        height={20}
                        alt=""
                      />
                    </div>

                    <div
                      className={`list-keunggulan-aplikasi-item ${isActiveUnggah ? "active" : ""
                        }`}
                      id="unggah-dokumen"
                      onClick={handleUnggahOtomatisClick}
                    >
                      <div className="list-keunggulan-aplikasi-title">
                        {myAplikasiTranslations.unggahTitle ||
                          "Unggah dokumen lebih mudah"}
                      </div>
                      <div className="list-keunggulan-aplikasi-description">
                        {myAplikasiTranslations.unggahDescription ||
                          "Anda dapat melakukan unggah dokumen sesuai dengan parameter penilaian yang disyaratkan."}
                      </div>
                      <img src={imageSource} id="image-keunggulan-aplikasi-mobile" alt="" />
                      <div className="divider-neutral-30" />
                      <img
                        src="images/aplikasi/ic_chevron_down.svg"
                        id="chevron-aplikasi"
                        width={20}
                        height={20}
                        alt=""
                      />
                    </div>

                    <div
                      className={`list-keunggulan-aplikasi-item ${isActiveTerbit ? "active" : ""
                        }`}
                      id="penerbitan-dokumen"
                      onClick={handleTerbitOtomatisClick}
                    >
                      <div className="list-keunggulan-aplikasi-title">
                        {myAplikasiTranslations.terbitTitle ||
                          "Menerbitkan dokumen penilaian lebih efisien"}
                      </div>
                      <div className="list-keunggulan-aplikasi-description">
                        {myAplikasiTranslations.terbitDescription ||
                          "Anda dapat menerbitkan dokumen penilaian lebih efisien dan membantu untuk pengajuan Sertifikasi Rumah Hijau"}
                      </div>
                      <img src={imageSource} id="image-keunggulan-aplikasi-mobile" alt="" />
                      <div className="divider-neutral-30" />
                      <img
                        src="images/aplikasi/ic_chevron_down.svg"
                        id="chevron-aplikasi"
                        width={20}
                        height={20}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div className="body-card">
              {/* <div className="container-attention">
                <div className="content-attention">
                  <div className="container-text-attention">
                    <div className="label-text-attention">LAPORKAN!!</div>
                    <div className="support-text-attention">
                      <p>Jika anda melihat dan mendengar Pelanggaran/Kecurangan yang dilakukan oleh Pejabat/Pegawai di lingkungan</p>
                      <p>Kementerian Perumahan dan Kawasan Permukiman</p></div>

                  </div>
                </div>
              </div> */}
            </div>

            <div className="wrapper-download-aplikasi">
              <div className="wrapper-qr-code-aplikasi">
                <div className="igahp-h3-semi-bold black-text">
                  {myAplikasiTranslations.downloadTitle ||
                    "Download Mobile App Lentera Hijau"}
                </div>
                <div className="igahp-m-regular neutral-90-text">
                  {myAplikasiTranslations.scanInstruction ||
                    "Scan QR Code dibawah ini"}
                </div>
                <img
                  src="lentera_hijau_mobile_qr.png"
                  width={160}
                  height={160}
                  id="lentera-hijau-mobile-qr"
                  alt="QR Code Lentera Hijau"
                />
                {/* <div className="download-panduan-penggunaan">
      <img src="images/aplikasi/ic_download.svg" width={18} height={18} alt="" />
      <div className="igahp-s-semibold green-main-text">
        {myAplikasiTranslations.downloadGuide || "Download panduan penggunaan"}
      </div>
    </div> */}
              </div>
              {/* <div className="background-green-bottom-right-rounded" /> */}
              <div className="wrapper-image-contoh-aplikasi">
                <img
                  src="images/aplikasi/image_aplikasi_detail_rumah_new.png"
                  width={265}
                  alt={myAplikasiTranslations.imageAltDetail || "Contoh aplikasi detail rumah"}
                />
                <img
                  src="images/aplikasi/image_aplikasi_penilaian_new.png"
                  width={265}
                  alt={myAplikasiTranslations.imageAltPenilaian || "Contoh aplikasi penilaian"}
                />
              </div>
            </div>


          </section>


        </section>



        {/* <section className="wrapper-download-aplikasi">
          <div className="wrapper-qr-code-aplikasi">
            <div className="one-green-h3-semi-bold white-text">Download Aplikasi</div>
            <div className="one-green-m-regular white-text">Scan QR dibawah ini untuk mendownload aplikasi Lentera Hijau</div>
            <img src="one_green_mobile_qr.png" width={120} height={120} id="one-green-mobile-qr" />
            <div className="download-panduan-penggunaan">
              <div className="one-green-s-semibold green-main-text">Download panduan penggunaan</div>
              <img src="images/aplikasi/ic_download.svg" width={18} height={18} />
            </div>
          </div>
          <div className="background-green-bottom-right-rounded" />
          <div className="wrapper-image-contoh-aplikasi">
            <img src="images/aplikasi/image_aplikasi_detail_rumah.png" width={265} />
            <img src="images/aplikasi/image_aplikasi_penilaian.png" width={265} />
          </div>
        </section> */}

      </div>
      <FooterQuestion />
      <FooterFront />

    </>
  )
}

export default Aplikasi
