import React from 'react'
import Footer from '../Footer'

const AdminDashboard = () => {




    return (
        <>


            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            {/* <div className="col-sm-6">
                                <h1 className="m-0">Dashboard Admin</h1>
                            </div> */}
                            
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        <div className="row">
                            
                        </div>
                        {/* /.row */}
                        {/* Main row */}
                        
                        {/* /.row (main row) */}
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}



        </>
    )
}

export default AdminDashboard
